import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'Graph/queries';
import { useProductTutorial } from 'Hooks/Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { clearLocalCaches } from 'Utilities/utils';
import { Panel, PanelItem } from './Panel';
import { ToastContext } from 'Map/Components/ToastContext';
import { useContext } from 'react';
import { useAuth } from 'Hooks/Auth';
import { useCurrentUser } from 'Hooks/User';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Tooltip } from 'Library/Tooltip';
import { IdentificationIcon } from '@heroicons/react/20/solid';
import { useOrganization } from 'Utilities/OrganizationProvider';

const Profile = (): JSX.Element => {
    const { enableTour } = useFlags();
    const { isAuth0Auth } = useOrganization();

    const { logout, userId, tenantId } = useAuth();
    const { name, email } = useCurrentUser();

    const userIsSSO = userId?.includes('samlp');

    const { dispatch: toastDispatch } = useContext(ToastContext);
    const { dispatch } = useContext(IdentityMapContext);
    const { startTutorial } = useProductTutorial();

    const [generatePasswordReset, { data, loading, error }] = useMutation(RESET_PASSWORD, {
        variables: { tenantId, userId },
    });

    const logoutUser = () => {
        clearLocalCaches();
        logout();
    };

    const createNewPassword = () => {
        generatePasswordReset();
    };

    return (
        <Panel title="Your Profile" subtitle="Choose your personal preferences here">
            <PanelItem title={`Signed in as ${name || email || 'invalid user'}`}>
                <div className="flex place-items-center space-x-1">
                    <p>{email}</p>
                    {userIsSSO && (
                        <Tooltip label="Signed in with SSO" placement="right">
                            <IdentificationIcon className="w-5 h-5" />
                        </Tooltip>
                    )}
                </div>
                <button onClick={logoutUser} className="btn mt-2" data-test="logout-button">
                    Logout
                </button>
            </PanelItem>

            {!userIsSSO && isAuth0Auth && (
                <PanelItem title="Update Password">
                    <p>You will be emailed a password reset link</p>
                    {error && (
                        <p className="mt-1 text-red-500 font-semibold">
                            Could not generate a password reset email:{' '}
                            <span className="lowercase">{error?.message}</span>
                        </p>
                    )}
                    {loading && <p className="mt-1 text-gray-500">Sending password reset email...</p>}
                    {data && <p className="mt-1 text-green-500 ">Password reset link sent</p>}
                    <button
                        onClick={() => {
                            createNewPassword();
                            toastDispatch({
                                type: 'add-toast',
                                message: `Password reset email sent`,
                                status: 'success',
                                autoTimeout: true,
                                timeoutTimer: 15,
                            });
                        }}
                        className="btn mt-2"
                    >
                        Create New Password
                    </button>
                </PanelItem>
            )}
            {enableTour && (
                <PanelItem title="Product Tutorial">
                    <p>The Product Tutorial will help you learn to navigate the Trust Fabric</p>
                    <button
                        className="btn mt-2"
                        onClick={() => {
                            if (startTutorial()) {
                                dispatch({ type: 'toggle-config' });
                            }
                        }}
                    >
                        Restart Product Tutorial
                    </button>
                </PanelItem>
            )}
        </Panel>
    );
};

export default Profile;
