import { useCallback, useEffect, useState } from 'react';
import { InvalidAuthProviderConfig } from './AuthStatusMessageComponents';
import { AuthProvider, AuthProviderProps, hasAuthParams, useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { User } from 'oidc-client-ts';
import ExternalPage from 'Onboarding/ExternalPage';

const oidcConfig: AuthProviderProps = {
    client_id: 'sir-ui-dev',
    disablePKCE: true,
    omitScopeWhenRequesting: true,
    redirect_uri: window.location.origin,
    authority: '',
    loadUserInfo: true,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTimeInSeconds: 60 * 5,
};

const SailPointAuthProviderWithHistory = ({ children }: { children: JSX.Element }) => {
    const currentOrganization = localStorage.getItem('currentOrganization');
    const authMetadata = JSON.parse(localStorage.getItem('authMetadata') || '{}');
    const tenantId = localStorage.getItem('dzTenantId');

    const [validProvider, setValidProvider] = useState(false);

    const history = useHistory();

    oidcConfig.onSigninCallback = useCallback(
        (user: User) => {
            history.push(user?.state || window.location.pathname);
        },
        [history],
    );

    useEffect(() => {
        console.log('Authentication will be provided by SailPoint');

        if (!currentOrganization || !tenantId || Object.keys(authMetadata).length === 0) {
            console.log('No SailPoint Organization specified, redirecting to select Organization page');
            history.push('/auth/login');
            setValidProvider(false);
        }

        oidcConfig.metadata = authMetadata;
        oidcConfig.authority = authMetadata.authority;

        setValidProvider(true);
    }, [authMetadata, currentOrganization, history, tenantId]);

    return validProvider ? (
        <AuthProvider {...oidcConfig}>
            <SailPoint>{children}</SailPoint>
        </AuthProvider>
    ) : (
        <InvalidAuthProviderConfig />
    );
};

export default SailPointAuthProviderWithHistory;

const REQUIRED_ROLES = ['sir:admin', 'sir:trust-admin', 'sir:trust-readonly', 'sir:readonly'];

const SailPoint = ({ children }: { children: JSX.Element }) => {
    const { isLoading, isAuthenticated, error, signinRedirect, signinSilent, user, events } = useAuth();
    const { activeNavigator } = useAuth();
    const [silentRenewError, setSilentRenewError] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!hasAuthParams() && !isAuthenticated && !isLoading && !error && !activeNavigator) {
            signinRedirect({ state: window.location.pathname + window.location.search });
        }

        if (user) {
            if (user.profile && user.profile) {
                // Check the profile capabilities to see if the user has the required roles
                const capabilities = (user.profile.capabilities as string[]) || [];
                const hasRequiredRoles = REQUIRED_ROLES.some((role) => capabilities.includes(role));

                if (!hasRequiredRoles) {
                    console.error('User does not have the required roles:', REQUIRED_ROLES);
                    history.push('/account-activated');
                }
            }

            localStorage.setItem('accessToken', user.access_token);
        }
    }, [activeNavigator, error, history, isAuthenticated, isLoading, signinRedirect, user]);

    // If the access token expires before we have a chance to renew it, the access token
    // can be left in a stale state. This most often happens when the user leaves the app
    // open for a long time and the laptop goes to sleep. This event listener will catch
    // the event and attempt to silently renew the token.
    useEffect(() => {
        return events.addAccessTokenExpired(() => {
            console.log('Access Token Expired');
            signinSilent();
        });
    }, [events, signinSilent]);

    // If the silent renew fails, we need to notify the user and give them the option to retry
    useEffect(() => {
        return events.addSilentRenewError(() => {
            console.log('Silent Renew Error');
            setSilentRenewError(true);
        });
    }, [events]);

    return (
        <>
            {error && <AuthError silentRenewError={silentRenewError} />}
            {isLoading && !error && <Loading />}
            {!isLoading && !error && children}
        </>
    );
};

const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Authenticating ...
            </p>
        </div>
    </div>
);

const AuthError = ({ silentRenewError }: { silentRenewError: boolean }): JSX.Element => {
    const { error, signinRedirect, signinSilent, isLoading } = useAuth();

    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        if (error) {
            console.warn('SailPoint Auth Error:', error);
        }
    }, [error]);

    useEffect(() => {
        if (silentRenewError) {
            // Try to sign in again every 30s
            const interval = setInterval(() => {
                signinSilent();
            }, 30000);

            return () => clearInterval(interval);
        }
    }, [signinSilent, silentRenewError]);

    const clearCacheAndLogin = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('dzTenantId');

        signinRedirect({});
    };

    const retryLogin = () => {
        setRetryCount(retryCount + 1);
        signinSilent({});
    };

    if (silentRenewError) {
        return (
            <ExternalPage>
                <div className="pb-16">
                    <h2 className="text-3xl font-extrabold text-gray-200">Session Paused</h2>
                    <p className="text-sm text-gray-400 mt-2">Your session was unable to be refreshed</p>
                    <p className="text-xs text-gray-200 mt-4 place-items-center flex h-4">
                        We will attempt to sign-in again every 30 seconds.
                        {isLoading && <span className="ml-2 loader" />}
                    </p>
                    <div className="mt-6">
                        <button onClick={retryLogin} className="btn btn-primary w-3/7 text-sm rounded-md mx-auto">
                            Try Again Now
                        </button>
                    </div>
                    {retryCount > 0 && (
                        <p className="text-xs text-red-500 mt-6">
                            There was an issue with signing in. Please try again.
                        </p>
                    )}
                </div>
            </ExternalPage>
        );
    }

    return (
        <ExternalPage>
            <div className="pb-16">
                {/* <img className="h-12 w-auto" src={logo} alt="SailPoint Identity Risk Icon" /> */}

                <h2 className="text-3xl font-extrabold text-gray-200">Authentication Issue</h2>
                <p className="text-sm text-gray-400 mt-2">Unfortunately you may not access SailPoint Identity Risk</p>

                <p className="text-xs text-red-500 mt-4">Reason: {error?.message}</p>

                <div className="mt-6 space-y-8">
                    <button onClick={clearCacheAndLogin} className="btn btn-primary w-3/7 text-sm rounded-md mx-auto">
                        Try to Sign In Again
                    </button>
                </div>
            </div>
        </ExternalPage>
    );
};
