import { Menu } from '@headlessui/react';
import {
    AdjustmentsVerticalIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    ExclamationCircleIcon,
    NoSymbolIcon,
    QuestionMarkCircleIcon,
    XCircleIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { cloneElement, useContext, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    capitalize,
    classNames,
    getDisplayName,
    getEntityType,
    providerNameLookup,
    tagNameLookup,
} from 'Utilities/utils';

import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet-async';
import { BackendEventHistoryData, UnitInterval } from 'Types/types';
import { useChartTimeRange } from 'Dashboard/Charts/ChartHooks';
import { getIconSourceURL, getNodeIconElement, getTagIconElement } from 'Map/Graph/Icons';
import { BaseChart, DashboardChartProps } from 'Dashboard/Charts/BaseChart';
import { GET_ENTITY_ACCESSORS, GET_ENTITY_EVENT_HISTORY } from 'Graph/queries';
import { NodeTrustDisplay } from 'Map/Profiles/Library/NodeTrustDisplay';
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, Tooltip } from 'recharts';
import Avatar from 'react-avatar';
import { useQuery } from '@apollo/client';
import { GET_ENTITY_PERMISSION_STATS, GET_ENTITY_PROFILE, GET_ENTITY_SPIDER } from 'Graph/typedQueries';
import { FactorType, StatsEntityType } from 'GeneratedGQL/graphql';
import { useTenant } from 'Hooks/Hooks';
import { MaxNodeProfileOverviewPrototype } from './MaxNodeProfilePrototype';
import { NodeTheWall } from 'Map/Profiles/Library/NodeTheWall';
import { NodeLocationDisplay } from 'Map/Profiles/Library/NodeLocationDisplay';
import { NodeAttributeDisplay } from 'Map/Profiles/Library/NodeAttributeDisplay';
import { AccessorList } from 'Map/Profiles/Library/AccessorList';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type DashboardTabs =
    | 'overview'
    | 'actors'
    | 'targets'
    | 'events'
    | 'trust'
    | 'location'
    | 'location-future'
    | 'overview-future';

export type DashboardModalProps = {
    open: boolean;
};

export type DashboardTabProps = {
    open: boolean;
    startDate: number;
    endDate: number;
    selectedStartDate: number;
    selectedEndDate: number;
    interval: UnitInterval;
    onBarClick: (epoch: number | undefined, index: number | undefined) => void;
    selectedBar: number;
};

export const MaxNodeProfile = ({ open }: DashboardModalProps): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);

    const { enableFutureTabs } = useFlags();

    const { startDate, endDate, interval, setNarrowedEpoch, UnitSelect, TimeWindowSelect } = useChartTimeRange();

    const [selectedBar, setSelectedBar] = useState(-1);

    useEffect(() => {
        setSelectedBar(-1);
    }, [startDate, endDate, interval]);

    const onBarClick = (epoch: number | undefined, index: number | undefined) => {
        setNarrowedEpoch(epoch);
        setSelectedBar(index ?? -1);
    };

    const closeMaxNodeProfile = () => {
        if (open) {
            dispatch({ type: 'toggle-max-profile' });
        }
    };
    useHotkeys('esc', closeMaxNodeProfile, [open]);

    const [tab, setTab] = useState<DashboardTabs>('overview');

    const Tab = ({ tabName, tabTitle }: { tabName: DashboardTabs; tabTitle: string }) => (
        <li>
            <button
                data-test={tabName}
                type="button"
                className={classNames(
                    'rounded-t-md py-3 px-4 text-sm h-full',
                    tab === tabName
                        ? 'bg-gray-700/50 text-white'
                        : 'text-gray-500 hover:text-gray-300 hover:bg-gray-700/25',
                )}
                onClick={() => setTab(tabName)}
            >
                {tabTitle}
            </button>
        </li>
    );

    return (
        <div
            className={open ? 'fixed bottom-0 z-10 inset-0 overflow-y-auto top-[56px] h-[calc(100vh-56px)]' : 'hidden'}
        >
            {open && (
                <Helmet>
                    <title>Max Actor Profile</title>
                </Helmet>
            )}
            <div className="flex items-end justify-center text-center sm:block sm:p-0">
                <div
                    className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                    onClick={closeMaxNodeProfile}
                />
                <div
                    id="MaxNodeProfile"
                    className="absolute left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] xxl:w-[75vw] mt-14 align-top md:mt-5"
                >
                    <div id="Header" className="flex justify-between items-start p-4 pb-2">
                        <h1 className="text-md font-bold tracking-wider">Actor Profile</h1>
                        <div className="flex space-x-2">
                            <DownloadButton />
                        </div>
                    </div>

                    <div className="flex">
                        <ul id="Tabs" className="flex space-x-1 px-2 w-full">
                            <Tab tabName="overview" tabTitle="Overview" />
                            {enableFutureTabs && <Tab tabName="overview-future" tabTitle="Overview (Future)" />}
                        </ul>
                        <div className="flex space-x-2 mr-4 mb-2 place-items-center">
                            <UnitSelect />
                            <TimeWindowSelect />
                        </div>
                    </div>
                    <div className="bg-gray-700/50 p-4 text-xs overflow-y-auto max-h-[calc(100vh-200px)] rounded-b-lg">
                        {tab === 'overview' && (
                            <MaxNodeProfileOverview
                                startDate={startDate}
                                endDate={endDate}
                                interval={interval}
                                onBarClick={onBarClick}
                                selectedBar={selectedBar}
                            />
                        )}
                        {tab === 'overview-future' && (
                            <MaxNodeProfileOverviewPrototype
                                startDate={startDate}
                                endDate={endDate}
                                interval={interval}
                                onBarClick={onBarClick}
                                selectedBar={selectedBar}
                            />
                        )}
                    </div>
                    <button
                        data-test="modal-close"
                        type="button"
                        className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md"
                        onClick={closeMaxNodeProfile}
                    >
                        <XMarkIcon className="h-4 w-4 text-gray-200" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    );
};

const MaxNodeProfileOverview = ({
    startDate,
    endDate,
    interval,
    onBarClick,
    selectedBar,
}: DashboardChartProps): JSX.Element => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { selectedProfileNode: node } = mapState;

    const tenantId = useTenant();

    // Actor Profile
    const {
        data: actorProfileData,
        loading: loadingGetEntityProfile,
        error: errorGetEntityProfile,
    } = useQuery(GET_ENTITY_PROFILE, {
        variables: {
            tenantId: tenantId || '',
            entity: StatsEntityType.StatsEntityTypeActor,
            entityId: String(node?.id) || '',
            startDate: startDate,
            endDate: endDate,
        },
        skip: !tenantId || !node,
    });
    const profile = actorProfileData?.getEntityProfile;

    // Actor Permission Stats
    const {
        data: actorPermissionStatsData,
        loading: loadingGetEntityPermissionsStats,
        error: errorGetEntityPermissionStats,
    } = useQuery(GET_ENTITY_PERMISSION_STATS, {
        variables: {
            tenantId: tenantId || '',
            entity: StatsEntityType.StatsEntityTypeActor,
            entityId: String(node?.id) || '',
        },
        skip: !tenantId || !node,
    });
    const permissionStats = actorPermissionStatsData?.getEntityPermissionStats;

    const {
        data: entityAccessorsData,
        loading: loadingGetEntityAccessors,
        error: errorGetEntityAccessors,
    } = useQuery(GET_ENTITY_ACCESSORS, {
        variables: {
            tenantId,
            entityId: node?.id,
            entityType: getEntityType(node),
            startDate: startDate,
            endDate: endDate,
        },
        skip: !node,
    });

    const { targets, devices, applications } = useMemo(() => {
        if (entityAccessorsData) {
            const results = entityAccessorsData.getEntityAccessors;
            return {
                actors: results.actors,
                targets: results.targets,
                devices: results.devices,
                identities: results.identities,
                applications: results.applications,
                stats: {
                    actorCount: results.actorsCount,
                    targetCount: results.targetsCount,
                    deviceCount: results.devicesCount,
                    applicationCount: results.applicationsCount,
                    identityCount: results.identitiesCount,
                    highRiskActorCount: results.highRiskActorsCount,
                    highRiskTargetCount: results.highRiskTargetsCount,
                    highRiskDeviceCount: results.highRiskDevicesCount,
                    highRiskIdentityCount: results.highRiskIdentitiesCount,
                    highRiskApplicationCount: results.highRiskApplicationsCount,
                },
            };
        }

        return {
            actors: undefined,
            targets: undefined,
            devices: undefined,
            identities: undefined,
            stats: undefined,
        };
    }, [entityAccessorsData]);

    if (!node) {
        return <div>No node selected</div>;
    }

    return (
        <div className="grid lg:grid-cols-6 grid-cols-3 grid-flow-dense gap-4">
            <div className="col-span-1 bg-gray-800 rounded-lg p-4">
                <div className="flex justify-between">
                    <div className="flex-grow space-y-2 flex flex-col">
                        <div className="flex">
                            <div className="flex items-top justify-between pt-0.5">
                                <div className="flex flex-row space-x-3">
                                    <div className="flex-shrink-0 items-center relative">
                                        <div className="h-12 w-12 rounded-full bg-gray-900 border border-gray-700 p-2 flex items-center justify-center mr-2">
                                            {node.label == 'actor' ? (
                                                <Avatar
                                                    size="100%"
                                                    name={getDisplayName(node)}
                                                    round={true}
                                                    maxInitials={2}
                                                />
                                            ) : (
                                                <img src={getIconSourceURL(getNodeIconElement(node))} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="text-lg">{getDisplayName(node)}</div>
                                <div>{node.props.alternateId}</div>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="text-gray-300 flex flex-col">
                                {node.tags.map((tag) => {
                                    return (
                                        <div className="flex items-center" key={tag}>
                                            <img
                                                className="h-5 w-5 mr-2"
                                                src={getIconSourceURL(getTagIconElement(tag))}
                                            />
                                            {tagNameLookup(tag)}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="text-gray-400">
                                {loadingGetEntityProfile && <Loading />}
                                {errorGetEntityProfile && <Error />}

                                {profile && (
                                    <span>
                                        Observed through{' '}
                                        {profile.providers
                                            .map((provider: string) => providerNameLookup(provider))
                                            .join(', ')}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-2 bg-gray-800 rounded-lg p-4 pt-[18px]">
                <NodeAttributeDisplay node={node} startDate={startDate} endDate={endDate} />
            </div>

            <div className="col-span-3 bg-gray-800 rounded-lg flex place-items-center space-x-4 p-4">
                <TrustSpider node={node} />

                <BaseChart
                    margin={{ top: 0, right: 0, bottom: 0, left: -20 }}
                    legendHeight={40}
                    query={GET_ENTITY_EVENT_HISTORY}
                    queryName="getEntityEventHistory"
                    variables={{
                        entityId: node?.id,
                        entityType: getEntityType(node),
                    }}
                    processRow={(row: BackendEventHistoryData) => {
                        let trustScore = null;

                        if (row.trustScore && row.trustScore >= 0) {
                            trustScore = Math.round(row.trustScore);
                        }

                        return {
                            Success: row.successCount + row.allowCount,
                            Challenge: row.challengeCount,
                            Failure: row.denyCount + row.failureCount,
                            Trust: trustScore,
                        };
                    }}
                    bars={[
                        {
                            name: 'Success',
                            dataKey: 'Success',
                            activeColor: 'rgba(101, 163, 13, 1)',
                            inactiveColor: 'rgba(101, 163, 13, 0.5)',
                        },
                        {
                            name: 'Challenge',
                            dataKey: 'Challenge',
                            activeColor: 'rgba(245, 158, 11, 1)',
                            inactiveColor: 'rgba(245, 158, 11, 0.5)',
                        },
                        {
                            name: 'Failure',
                            dataKey: 'Failure',
                            activeColor: 'rgba(225, 29, 72, 1)',
                            inactiveColor: 'rgba(225, 29, 72, 0.5)',
                        },
                    ]}
                    startDate={startDate}
                    endDate={endDate}
                    interval={interval}
                    onBarClick={onBarClick}
                    selectedBar={selectedBar}
                    plotTrust={true}
                    trustData={node.trustData}
                />
            </div>

            <div className="col-span-3">
                <div className="flex w-full space-x-4">
                    <div className="flex flex-col space-y-4">
                        <a href="mailto:manager@example.com" target="_blank" className="btn flex-grow rounded-lg">
                            Email Manager
                        </a>
                        <a href="mailto:sirt@example.com" target="_blank" className="btn flex-grow rounded-lg">
                            Notify SIRT
                        </a>
                    </div>

                    <div className="flex-grow bg-gray-700 rounded-lg p-2 space-y-1">
                        <div className="font-medium text-gray-500 capitalize w-full text-center">
                            Authentication Methods
                        </div>
                        {loadingGetEntityProfile && <Loading />}
                        {errorGetEntityProfile && <Error />}

                        {profile?.mfaMethods && profile.mfaMethods.length > 0 ? (
                            profile?.mfaMethods.map((mfaMethod) => {
                                const { name, strength } = mfaFactorNameAndStrength(
                                    mfaMethod || FactorType.FactorTypeUnknown,
                                );
                                const { icon, colorClass } = getIconAndColor(strength);

                                return (
                                    <div className="flex place-items-center">
                                        {cloneElement(icon, { className: `h-4 w-4 ${colorClass} mr-2` })}
                                        {name}
                                    </div>
                                );
                            })
                        ) : (
                            <div className="font-medium text-gray-600 capitalize w-full text-center">
                                No MFA methods reported.
                            </div>
                        )}
                    </div>

                    <div className="bg-gray-700 rounded-lg p-2 w-24 flex flex-col">
                        <div className="font-medium text-gray-500 capitalize w-full text-center">Groups</div>
                        <div className="flex justify-center items-center text-3xl font-medium h-full">
                            {loadingGetEntityPermissionsStats && <span className="loader h-8 w-8" />}
                            {errorGetEntityPermissionStats && <NoSymbolIcon className="h-8 w-8 text-red-400" />}
                            {!loadingGetEntityPermissionsStats &&
                                !errorGetEntityPermissionStats &&
                                (permissionStats?.groupCount || 0)}
                        </div>
                    </div>

                    <div className="bg-gray-700 rounded-lg p-2 w-24 flex flex-col">
                        <div className="font-medium text-gray-500 capitalize w-full text-center">Targets</div>
                        <div className="flex justify-center items-center text-3xl font-medium h-full">
                            {loadingGetEntityPermissionsStats && <span className="loader h-8 w-8" />}
                            {errorGetEntityPermissionStats && <NoSymbolIcon className="h-8 w-8 text-red-400" />}
                            {!loadingGetEntityPermissionsStats &&
                                !errorGetEntityPermissionStats &&
                                (permissionStats?.targetCount || 0)}
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4">
                        <button
                            className="btn flex-grow rounded-lg"
                            onClick={() => {
                                dispatch({ type: 'set-permissions-node', node: node });
                                if (!mapState.navigatorOpen) {
                                    dispatch({ type: 'toggle-navigator' });
                                }
                            }}
                        >
                            Permissions
                        </button>
                        <button
                            className="btn flex-grow rounded-lg"
                            onClick={() => {
                                if (!mapState.policyOpen) {
                                    dispatch({ type: 'toggle-policy' });
                                }
                            }}
                        >
                            Trust Profiles
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-span-3 row-span-2">
                <NodeTrustDisplay node={node} startDate={startDate} endDate={endDate} dynamicHeight={true} />
            </div>

            <div className="bg-gray-700 rounded-lg h-full pt-4 pb-2">
                <div className="font-medium text-gray-500 capitalize w-full text-center">Most Accessed Targets</div>
                {loadingGetEntityAccessors && <Loading />}
                {errorGetEntityAccessors && <Error />}
                {targets && (
                    <AccessorList
                        items={targets}
                        entityType="STATS_ENTITY_TYPE_TARGET"
                        dynamicHeight={true}
                        maxItems={5}
                    />
                )}
            </div>
            <div className="bg-gray-700 rounded-lg pt-4 pb-2">
                <div className="font-medium text-gray-500 capitalize w-full text-center">Most Used Applications</div>
                {loadingGetEntityAccessors && <Loading />}
                {errorGetEntityAccessors && <Error />}
                {applications && (
                    <AccessorList
                        items={applications}
                        entityType="STATS_ENTITY_TYPE_APPLICATION"
                        dynamicHeight={true}
                        maxItems={5}
                    />
                )}
            </div>
            <div className="bg-gray-700 rounded-lg pt-4 pb-2">
                <div className="font-medium text-gray-500 capitalize w-full text-center">Most Used Devices</div>
                {loadingGetEntityAccessors && <Loading />}
                {errorGetEntityAccessors && <Error />}
                {devices && (
                    <AccessorList
                        items={devices}
                        entityType="STATS_ENTITY_TYPE_DEVICE"
                        dynamicHeight={true}
                        maxItems={5}
                    />
                )}
            </div>

            <div className="lg:col-span-4 col-span-3 bg-gray-800 rounded-lg flex place-items-center">
                <NodeLocationDisplay
                    node={node}
                    startDate={startDate}
                    endDate={endDate}
                    projectionConfig={{
                        scale: 250,
                        rotate: [-10, 0, 0],
                        center: [0, 18],
                    }}
                />
            </div>
            <div className="lg:col-span-2 col-span-3 bg-gray-700 rounded-lg">
                <NodeTheWall node={node} startDate={startDate} endDate={endDate} />
            </div>
        </div>
    );
};

type FactorNameAndStrength = {
    name: string;
    strength: 'weak' | 'medium' | 'strong' | 'variable' | 'unknown';
};

const mfaFactorNameAndStrength = (factor: FactorType): FactorNameAndStrength => {
    const factorNameAndStrength: FactorNameAndStrength = {
        name: 'Unknown',
        strength: 'medium',
    };

    switch (factor) {
        case FactorType.FactorTypeEmail:
            factorNameAndStrength.name = 'Email';
            factorNameAndStrength.strength = 'medium';
            break;

        case FactorType.FactorTypePhone:
            factorNameAndStrength.name = 'Phone';
            factorNameAndStrength.strength = 'medium';
            break;

        case FactorType.FactorTypeSms:
            factorNameAndStrength.name = 'SMS';
            factorNameAndStrength.strength = 'weak';
            break;

        case FactorType.FactorTypePassword:
            factorNameAndStrength.name = 'Password';
            factorNameAndStrength.strength = 'strong';
            break;

        case FactorType.FactorTypeTotp:
            factorNameAndStrength.name = 'Time-based One-Time Password (TOTP)';
            factorNameAndStrength.strength = 'strong';
            break;

        case FactorType.FactorTypeApp:
            factorNameAndStrength.name = 'App';
            factorNameAndStrength.strength = 'strong';
            break;

        case FactorType.FactorTypeFirstFactorClaim:
            factorNameAndStrength.name = 'First Factor Claim';
            factorNameAndStrength.strength = 'variable';
            break;

        case FactorType.FactorTypeMfaClaim:
            factorNameAndStrength.name = 'Multi-Factor Authentication (MFA) Claim';
            factorNameAndStrength.strength = 'strong';
            break;

        case FactorType.FactorTypeWindowsBroker:
            factorNameAndStrength.name = 'Windows Broker';
            factorNameAndStrength.strength = 'strong';
            break;

        case FactorType.FactorTypePreviouslySatisfied:
            factorNameAndStrength.name = 'Previously Satisfied';
            factorNameAndStrength.strength = 'variable';
            break;

        case FactorType.FactorTypePasswordless:
            factorNameAndStrength.name = 'Passwordless';
            factorNameAndStrength.strength = 'strong';
            break;

        case FactorType.FactorTypeUnknown:
            factorNameAndStrength.name = 'Unknown';
            factorNameAndStrength.strength = 'unknown';
            break;

        default:
            factorNameAndStrength.name = 'Unrecognized Factor Type';
            factorNameAndStrength.strength = 'unknown';
    }

    return factorNameAndStrength;
};

const getIconAndColor = (strength: FactorNameAndStrength['strength']) => {
    switch (strength) {
        case 'strong':
            return { icon: <CheckCircleIcon />, colorClass: 'text-green-600' };
        case 'medium':
            return { icon: <ExclamationCircleIcon />, colorClass: 'text-yellow-600' };
        case 'weak':
            return { icon: <XCircleIcon />, colorClass: 'text-red-600' };
        case 'variable':
            return { icon: <AdjustmentsVerticalIcon />, colorClass: 'text-blue-600' };
        default:
            return { icon: <QuestionMarkCircleIcon />, colorClass: 'text-gray-600' };
    }
};

type TrustSpiderData = {
    subject: string;
    trust: number;
    fullMark: number;
};

export const TrustSpider = ({ node }: { node: Node }): JSX.Element => {
    const tenantId = useTenant();

    // Actor Spider Chart
    const { loading, data: spiderChartData } = useQuery(GET_ENTITY_SPIDER, {
        variables: {
            tenantId: tenantId || '',
            entity: getEntityType(node) as StatsEntityType,
            entityId: String(node?.id) || '',
        },
        skip: !tenantId || !node,
    });

    const data = useMemo(() => {
        const data: TrustSpiderData[] = [];
        if (spiderChartData?.getEntitySpider?.dimensions) {
            Object.entries(spiderChartData.getEntitySpider?.dimensions).map(([key, value]) => {
                if (key === '__typename') return;

                data.push({
                    subject: capitalize(key),
                    trust: value > -1 ? value : 0,
                    fullMark: 100,
                });
            });
        }
        return data;
    }, [spiderChartData]);

    if (loading) {
        return (
            <div className="flex h-[200px] w-[310px] items-center justify-center">
                <span className="h-8 w-8 loader" />
            </div>
        );
    }

    if (!data || data.length === 0) {
        return (
            <div className="flex flex-col space-y-1 h-[200px] w-[310px] place-content-center">
                <div className="font-medium text-gray-500 capitalize w-full text-center">Trust Radar</div>
                <div className="font-medium text-gray-600 capitalize w-full text-center">No trust data available.</div>
            </div>
        );
    }

    return (
        <RadarChart data={data} height={200} width={310} margin={{ top: 0, bottom: -65 }}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <Radar
                dataKey="trust"
                name="Trust"
                stroke="#165BAA"
                fill="#63ABFD"
                fillOpacity={0.6}
                isAnimationActive={false}
            />
            <Tooltip
                cursor={{ fill: 'rgba(255,255,255,0.15)', stroke: 'white', strokeWidth: 2 }}
                contentStyle={{
                    background: 'rgb(31 41 55)',
                    borderRadius: '6px',
                    border: '1px solid rgb(107 114 128)',
                }}
            />
        </RadarChart>
    );
};

const DownloadButton = (): JSX.Element => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={`
                  ${open ? 'opacity-50' : ''}
                  btn btn-primary text-xs rounded-md inline-flex`}
                    >
                        Download Report
                        <ChevronDownIcon className="h-4 w-4 ml-2" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-gray-900 shadow-lg text-xs outline-none">
                        <div className="py-2">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-blue-700 text-white' : 'text-gray-400',
                                            'px-4 py-2 text-xs flex items-center',
                                        )}
                                        href="#"
                                    >
                                        <DocumentIcon className="h-4 w-4 mr-2" />
                                        Download current report
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-blue-700 text-white' : 'text-gray-400',
                                            'px-4 py-2 text-xs flex items-center',
                                        )}
                                        href="#"
                                    >
                                        <DocumentDuplicateIcon className="h-4 w-4 mr-2" />
                                        Download all reports
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </>
            )}
        </Menu>
    );
};

const Loading = () => {
    return (
        <ul className="text-center text-xs text-gray-300 pt-2 flex flex-col items-center justify-center h-40">
            <div className="h-8 w-8 loader mb-3" />
            Loading...
        </ul>
    );
};
const Error = () => {
    return (
        <ul className="text-center text-xs text-red-400 pt-2 flex flex-col items-center justify-center h-40">
            <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
            Error loading results
        </ul>
    );
};
