/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    '#graphql\n    query getEntitiesAsNodes($tenantId: ID!, $entityType: StatsEntityType!, $entityIds: [String!]) {\n        getEntitiesAsNodes(tenantId: $tenantId, entityType: $entityType, entityIds: $entityIds) {\n            nodeId\n            nodeType\n            label\n            props\n            tags\n            attributes {\n                updatedAt\n                key\n                value\n            }\n            groupingData\n            currentLevelTrail\n            hasNextLevel\n            nextLevelBase64\n        }\n    }\n':
        types.GetEntitiesAsNodesDocument,
    '#graphql\n    query getInventoryStatistics($entity: StatsEntityType, $tenantId: ID!) {\n        getInventoryStatistics(entity: $entity, tenantId: $tenantId) {\n            items {\n                count\n                subType\n                entityType\n            }\n        }\n    }\n':
        types.GetInventoryStatisticsDocument,
    '#graphql\n    query getGlobalTrustStatistics($tenantId: ID!, $entity: StatsEntityType!) {\n        getGlobalTrustStatistics(tenantId: $tenantId, entity: $entity) {\n            globalTrustScore\n            buckets {\n                count\n                name\n                startTrustScore\n                endTrustScore\n            }\n        }\n    }\n':
        types.GetGlobalTrustStatisticsDocument,
    '#graphql\n    query getEntityByTrust($entity: StatsEntityType!, $tenantId: ID!, $limit: Int!, $order: StatsOrder!, $startDate: Int64!, $endDate: Int64!) {\n        getEntityByTrust(entity: $entity, tenantId: $tenantId, limit: $limit, order: $order, startDate: $startDate, endDate: $endDate ) {\n            items {\n                entityId\n                trustScore\n                accessCount\n            }\n        }\n    }\n':
        types.GetEntityByTrustDocument,
    '#graphql\n    query getEntityProfile($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!, $startDate: Int64!, $endDate: Int64!) {\n        getEntityProfile(entity: $entity, tenantId: $tenantId, entityId: $entityId, startDate: $startDate, endDate: $endDate) {\n            firstAccessed\n            lastAccessed\n            providers\n            mfaMethods\n            tags\n        }\n}':
        types.GetEntityProfileDocument,
    '#graphql\n    query getEntityPermissionStats($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {\n        getEntityPermissionStats(entity: $entity, tenantId: $tenantId, entityId: $entityId) {\n            groupCount\n            policyCount\n            targetCount\n        }\n    }\n':
        types.GetEntityPermissionStatsDocument,
    '#graphql\n    query getEntitySpider($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {\n        getEntitySpider(entity: $entity, tenantId: $tenantId, entityId: $entityId) {\n            dimensions {\n                actor\n                sessions\n                policies\n                devices\n                processes\n                targets\n                activities\n            }\n        }\n    }\n':
        types.GetEntitySpiderDocument,
    '#graphql\n    query listPolicyProfiles($tenantId: ID!, $filter: PolicyProfileQueryInput) {\n        listPolicyProfiles(tenantId: $tenantId, filter: $filter) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n            deletedAt\n        }\n    }\n':
        types.ListPolicyProfilesDocument,
    '#graphql\n    query getPolicyProfile($tenantId: ID!, $profileId: ID!) {\n        getPolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n':
        types.GetPolicyProfileDocument,
    '#graphql\n    mutation createPolicyProfile($tenantId: ID!, $policyProfileInput: PolicyProfileInput!) {\n        createPolicyProfile(tenantId: $tenantId, policyProfileInput: $policyProfileInput) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n':
        types.CreatePolicyProfileDocument,
    '#graphql\n    mutation updatePolicyProfile($tenantId: ID!, $profileId: ID!, $policyProfileInput: PolicyProfileInput!) {\n        updatePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId, policyProfileInput: $policyProfileInput) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n':
        types.UpdatePolicyProfileDocument,
    '#graphql\n    mutation deletePolicyProfile($tenantId: ID!, $profileId: ID!) {\n        deletePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) \n    }\n':
        types.DeletePolicyProfileDocument,
    '#graphql\nmutation addActorsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {\n    addActorsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n                updatedAt\n    }\n}\n':
        types.AddActorsToPolicyProfileDocument,
    '#graphql\nmutation removeActorsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {\n    removeActorsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n':
        types.RemoveActorsFromPolicyProfileDocument,
    '#graphql\nmutation addTargetsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targets: [PolicyProfileTargetInput!]!) {\n    addTargetsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, target: $targets) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n':
        types.AddTargetsToPolicyProfileDocument,
    '#graphql\nmutation removeTargetsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targetIds: [ID!]!) {\n    removeTargetsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, targetIds: $targetIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n':
        types.RemoveTargetsFromPolicyProfileDocument,
    '#graphql\nmutation addPolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policies: [PolicyProfilePolicyInput]!) {\n    addPolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policies: $policies) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n':
        types.AddPolicyProfilePolicyDocument,
    '#graphql\nmutation removePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policyIds: [ID!]!) {\n    removePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policyIds: $policyIds) {\n    profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n':
        types.RemovePolicyProfilePolicyDocument,
    '#graphql\nmutation updatePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policy: PolicyProfilePolicyInput!) {\n    updatePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policy: $policy) {\n    profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n':
        types.UpdatePolicyProfilePolicyDocument,
    '#graphql\nquery listPolicyDocuments($tenantId: ID!, $filter: PolicyDocumentQueryInput) {\n    listPolicyDocuments(tenantId: $tenantId, filter: $filter) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n':
        types.ListPolicyDocumentsDocument,
    '#graphql\n    query getPolicyDocument($tenantId: ID!, $policyId: ID!, $version: Int64) {\n        getPolicyDocument(tenantId: $tenantId, policyId: $policyId, version: $version) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n':
        types.GetPolicyDocumentDocument,
    '#graphql\n    mutation stagePolicyDocument(\n        $tenantId: ID!\n        $policyDocument: String!\n        $name: String\n        $description: String\n        $validateOnly: Boolean\n    ) {\n        stagePolicyDocument(\n            tenantId: $tenantId\n            policyDocument: $policyDocument\n            name: $name\n            description: $description\n            validateOnly: $validateOnly\n        ) {\n            policyDocument {\n                policyDocumentId\n            }\n            status\n            errors {\n                code\n                message\n            }\n        }\n    }\n':
        types.StagePolicyDocumentDocument,
    '#graphql\n    mutation publishPolicyDocument(\n        $tenantId: ID!\n        $policyDocumentId: String!\n        $action: PolicyAction\n        $version: Int64\n    ) {\n        publishPolicyDocument(\n            tenantId: $tenantId\n            policyDocumentId: $policyDocumentId\n            action: $action\n            version: $version\n        ) {\n            policyDocumentId\n            status\n            errors {\n                code\n                message\n            }\n        }\n    }\n':
        types.PublishPolicyDocumentDocument,
    '#graphql\n    mutation deletePolicyDocument($tenantId: ID!, $policyDocumentId: ID!, $version: Int64) {\n        deletePolicyDocument(tenantId: $tenantId, policyDocumentId: $policyDocumentId, version: $version) \n    }\n':
        types.DeletePolicyDocumentDocument,
    '#graphql\n    mutation updatePolicyDocument($tenantId: ID!, $policyUpdates: UpdatePolicyDocumentFieldsInput!) {\n        updatePolicyDocument(tenantId: $tenantId, policyUpdates: $policyUpdates) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n':
        types.UpdatePolicyDocumentDocument,
    '#graphql\n    mutation createWebhook($tenantId: ID!, $webhookInput: WebhookInput!) {\n        createWebhook(tenantId: $tenantId, webhookInput: $webhookInput) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n':
        types.CreateWebhookDocument,
    '#graphql\n    query listWebhooks($tenantId: ID!) {\n        listWebhooks(tenantId: $tenantId) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n':
        types.ListWebhooksDocument,
    '#graphql\n    query getWebhook($tenantId: ID!, $webhookId: ID!) {\n        getWebhook(tenantId: $tenantId, webhookId: $webhookId) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n':
        types.GetWebhookDocument,
    '#graphql\n    mutation deleteWebhook($tenantId: ID!, $webhookId: ID!) {\n        deleteWebhook(tenantId: $tenantId, webhookId: $webhookId)\n    }\n':
        types.DeleteWebhookDocument,
    '#graphql\n    query getTrustProfileHistory($tenantId: ID!, $profileId: ID, $unit: StatsUnits!, $startDate: Int64!, $endDate: Int64!) {\n        getTrustProfileHistory(tenantId: $tenantId, profileId: $profileId, unit: $unit, startDate: $startDate, endDate: $endDate) {\n            items {\n                date\n                trustScore\n            }\n        }\n    }\n':
        types.GetTrustProfileHistoryDocument,
    '#graphql\n    query getStorageKeys($tenantId: ID!) {\n        getStorageKeys(tenantId: $tenantId) {\n            keys {\n                key\n                shared\n                userId\n            }\n        }\n    }\n':
        types.GetStorageKeysDocument,
    '#graphql\n    query getStorage($tenantId: ID!, $userId: String!, $key: String!, $shared: Boolean!) {\n        getStorage(tenantId: $tenantId, userId: $userId, key: $key, shared: $shared) {\n                id\n                key\n                data\n                publishedAt\n                updatedAt\n                shared\n            }\n        }\n':
        types.GetStorageDocument,
    '#graphql\n    mutation setStorage($tenantId: ID!, $key: String!, $data: String!, $shared: Boolean!) {\n        setStorage(tenantId: $tenantId, key: $key, data: $data, shared: $shared) {\n            id\n            key\n            data\n            publishedAt\n            updatedAt\n            shared\n        }\n    }\n':
        types.SetStorageDocument,
    '#graphql\n    mutation deleteStorage($tenantId: ID!, $key: String!, $shared: Boolean!) {\n        deleteStorage(tenantId: $tenantId, key: $key, shared: $shared)\n    }\n':
        types.DeleteStorageDocument,
    '#graphql\n    query listAlerts($tenantId: ID!, $input: ListAlertInput!) {\n        listAlerts(tenantId: $tenantId, input: $input) {\n            alerts {\n                id\n                raisedAt\n                eventDate\n                status\n                muted\n                observedCount\n                definitionId\n                alertDefinitionName\n                triggeredNodeId\n            }\n            pageInfo {\n                currentPage\n                pageCount\n            }\n        }\n    }\n':
        types.ListAlertsDocument,
    '#graphql\n    query getTotalAlertCount($tenantId: ID!) {\n        getTotalAlertCount (tenantId: $tenantId) {\n            count\n        }\n    }\n':
        types.GetTotalAlertCountDocument,
    '#graphql\n    query getTenantBySlug($slug: String!) {\n        getTenantBySlug(slug: $slug) {\n            name\n            slug\n            tenantId\n            metadata {\n                saasPlatformTenantId\n                saasPlatformTenantUrl\n                saasPlatformTenantApiUrl\n                saasPlatformTenantAccessTokenUrl\n            }\n        }\n    }\n':
        types.GetTenantBySlugDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getEntitiesAsNodes($tenantId: ID!, $entityType: StatsEntityType!, $entityIds: [String!]) {\n        getEntitiesAsNodes(tenantId: $tenantId, entityType: $entityType, entityIds: $entityIds) {\n            nodeId\n            nodeType\n            label\n            props\n            tags\n            attributes {\n                updatedAt\n                key\n                value\n            }\n            groupingData\n            currentLevelTrail\n            hasNextLevel\n            nextLevelBase64\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getEntitiesAsNodes($tenantId: ID!, $entityType: StatsEntityType!, $entityIds: [String!]) {\n        getEntitiesAsNodes(tenantId: $tenantId, entityType: $entityType, entityIds: $entityIds) {\n            nodeId\n            nodeType\n            label\n            props\n            tags\n            attributes {\n                updatedAt\n                key\n                value\n            }\n            groupingData\n            currentLevelTrail\n            hasNextLevel\n            nextLevelBase64\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getInventoryStatistics($entity: StatsEntityType, $tenantId: ID!) {\n        getInventoryStatistics(entity: $entity, tenantId: $tenantId) {\n            items {\n                count\n                subType\n                entityType\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getInventoryStatistics($entity: StatsEntityType, $tenantId: ID!) {\n        getInventoryStatistics(entity: $entity, tenantId: $tenantId) {\n            items {\n                count\n                subType\n                entityType\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getGlobalTrustStatistics($tenantId: ID!, $entity: StatsEntityType!) {\n        getGlobalTrustStatistics(tenantId: $tenantId, entity: $entity) {\n            globalTrustScore\n            buckets {\n                count\n                name\n                startTrustScore\n                endTrustScore\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getGlobalTrustStatistics($tenantId: ID!, $entity: StatsEntityType!) {\n        getGlobalTrustStatistics(tenantId: $tenantId, entity: $entity) {\n            globalTrustScore\n            buckets {\n                count\n                name\n                startTrustScore\n                endTrustScore\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getEntityByTrust($entity: StatsEntityType!, $tenantId: ID!, $limit: Int!, $order: StatsOrder!, $startDate: Int64!, $endDate: Int64!) {\n        getEntityByTrust(entity: $entity, tenantId: $tenantId, limit: $limit, order: $order, startDate: $startDate, endDate: $endDate ) {\n            items {\n                entityId\n                trustScore\n                accessCount\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getEntityByTrust($entity: StatsEntityType!, $tenantId: ID!, $limit: Int!, $order: StatsOrder!, $startDate: Int64!, $endDate: Int64!) {\n        getEntityByTrust(entity: $entity, tenantId: $tenantId, limit: $limit, order: $order, startDate: $startDate, endDate: $endDate ) {\n            items {\n                entityId\n                trustScore\n                accessCount\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getEntityProfile($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!, $startDate: Int64!, $endDate: Int64!) {\n        getEntityProfile(entity: $entity, tenantId: $tenantId, entityId: $entityId, startDate: $startDate, endDate: $endDate) {\n            firstAccessed\n            lastAccessed\n            providers\n            mfaMethods\n            tags\n        }\n}',
): (typeof documents)['#graphql\n    query getEntityProfile($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!, $startDate: Int64!, $endDate: Int64!) {\n        getEntityProfile(entity: $entity, tenantId: $tenantId, entityId: $entityId, startDate: $startDate, endDate: $endDate) {\n            firstAccessed\n            lastAccessed\n            providers\n            mfaMethods\n            tags\n        }\n}'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getEntityPermissionStats($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {\n        getEntityPermissionStats(entity: $entity, tenantId: $tenantId, entityId: $entityId) {\n            groupCount\n            policyCount\n            targetCount\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getEntityPermissionStats($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {\n        getEntityPermissionStats(entity: $entity, tenantId: $tenantId, entityId: $entityId) {\n            groupCount\n            policyCount\n            targetCount\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getEntitySpider($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {\n        getEntitySpider(entity: $entity, tenantId: $tenantId, entityId: $entityId) {\n            dimensions {\n                actor\n                sessions\n                policies\n                devices\n                processes\n                targets\n                activities\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getEntitySpider($entity: StatsEntityType!, $tenantId: ID!, $entityId: ID!) {\n        getEntitySpider(entity: $entity, tenantId: $tenantId, entityId: $entityId) {\n            dimensions {\n                actor\n                sessions\n                policies\n                devices\n                processes\n                targets\n                activities\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query listPolicyProfiles($tenantId: ID!, $filter: PolicyProfileQueryInput) {\n        listPolicyProfiles(tenantId: $tenantId, filter: $filter) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n            deletedAt\n        }\n    }\n',
): (typeof documents)['#graphql\n    query listPolicyProfiles($tenantId: ID!, $filter: PolicyProfileQueryInput) {\n        listPolicyProfiles(tenantId: $tenantId, filter: $filter) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n            deletedAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getPolicyProfile($tenantId: ID!, $profileId: ID!) {\n        getPolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getPolicyProfile($tenantId: ID!, $profileId: ID!) {\n        getPolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation createPolicyProfile($tenantId: ID!, $policyProfileInput: PolicyProfileInput!) {\n        createPolicyProfile(tenantId: $tenantId, policyProfileInput: $policyProfileInput) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation createPolicyProfile($tenantId: ID!, $policyProfileInput: PolicyProfileInput!) {\n        createPolicyProfile(tenantId: $tenantId, policyProfileInput: $policyProfileInput) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation updatePolicyProfile($tenantId: ID!, $profileId: ID!, $policyProfileInput: PolicyProfileInput!) {\n        updatePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId, policyProfileInput: $policyProfileInput) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation updatePolicyProfile($tenantId: ID!, $profileId: ID!, $policyProfileInput: PolicyProfileInput!) {\n        updatePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId, policyProfileInput: $policyProfileInput) {\n            profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation deletePolicyProfile($tenantId: ID!, $profileId: ID!) {\n        deletePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) \n    }\n',
): (typeof documents)['#graphql\n    mutation deletePolicyProfile($tenantId: ID!, $profileId: ID!) {\n        deletePolicyProfile(tenantId: $tenantId, policyProfileId: $profileId) \n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation addActorsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {\n    addActorsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n                updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation addActorsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {\n    addActorsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n                updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation removeActorsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {\n    removeActorsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation removeActorsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $actorIds: [ID!]!) {\n    removeActorsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, actorIds: $actorIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation addTargetsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targets: [PolicyProfileTargetInput!]!) {\n    addTargetsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, target: $targets) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation addTargetsToPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targets: [PolicyProfileTargetInput!]!) {\n    addTargetsToPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, target: $targets) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation removeTargetsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targetIds: [ID!]!) {\n    removeTargetsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, targetIds: $targetIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation removeTargetsFromPolicyProfile($tenantId: ID!, $policyProfileId: ID!, $targetIds: [ID!]!) {\n    removeTargetsFromPolicyProfile(tenantId: $tenantId, policyProfileId: $policyProfileId, targetIds: $targetIds) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation addPolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policies: [PolicyProfilePolicyInput]!) {\n    addPolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policies: $policies) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation addPolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policies: [PolicyProfilePolicyInput]!) {\n    addPolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policies: $policies) {\n     profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation removePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policyIds: [ID!]!) {\n    removePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policyIds: $policyIds) {\n    profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation removePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policyIds: [ID!]!) {\n    removePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policyIds: $policyIds) {\n    profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nmutation updatePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policy: PolicyProfilePolicyInput!) {\n    updatePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policy: $policy) {\n    profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n',
): (typeof documents)['#graphql\nmutation updatePolicyProfilePolicy($tenantId: ID!, $policyProfileId: ID!, $policy: PolicyProfilePolicyInput!) {\n    updatePolicyProfilePolicy(tenantId: $tenantId, policyProfileId: $policyProfileId, policy: $policy) {\n    profileId\n            displayName\n            description\n            policies {\n                profileId\n                policyId\n                weight\n            }\n            targets {\n                profileId\n                targetId\n                threshold\n                action\n                options\n            }\n            actorIds\n            version\n            originVersion\n            externalReadOnly\n            createdAt\n            updatedAt\n    }\n}\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\nquery listPolicyDocuments($tenantId: ID!, $filter: PolicyDocumentQueryInput) {\n    listPolicyDocuments(tenantId: $tenantId, filter: $filter) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n',
): (typeof documents)['#graphql\nquery listPolicyDocuments($tenantId: ID!, $filter: PolicyDocumentQueryInput) {\n    listPolicyDocuments(tenantId: $tenantId, filter: $filter) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getPolicyDocument($tenantId: ID!, $policyId: ID!, $version: Int64) {\n        getPolicyDocument(tenantId: $tenantId, policyId: $policyId, version: $version) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getPolicyDocument($tenantId: ID!, $policyId: ID!, $version: Int64) {\n        getPolicyDocument(tenantId: $tenantId, policyId: $policyId, version: $version) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation stagePolicyDocument(\n        $tenantId: ID!\n        $policyDocument: String!\n        $name: String\n        $description: String\n        $validateOnly: Boolean\n    ) {\n        stagePolicyDocument(\n            tenantId: $tenantId\n            policyDocument: $policyDocument\n            name: $name\n            description: $description\n            validateOnly: $validateOnly\n        ) {\n            policyDocument {\n                policyDocumentId\n            }\n            status\n            errors {\n                code\n                message\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation stagePolicyDocument(\n        $tenantId: ID!\n        $policyDocument: String!\n        $name: String\n        $description: String\n        $validateOnly: Boolean\n    ) {\n        stagePolicyDocument(\n            tenantId: $tenantId\n            policyDocument: $policyDocument\n            name: $name\n            description: $description\n            validateOnly: $validateOnly\n        ) {\n            policyDocument {\n                policyDocumentId\n            }\n            status\n            errors {\n                code\n                message\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation publishPolicyDocument(\n        $tenantId: ID!\n        $policyDocumentId: String!\n        $action: PolicyAction\n        $version: Int64\n    ) {\n        publishPolicyDocument(\n            tenantId: $tenantId\n            policyDocumentId: $policyDocumentId\n            action: $action\n            version: $version\n        ) {\n            policyDocumentId\n            status\n            errors {\n                code\n                message\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation publishPolicyDocument(\n        $tenantId: ID!\n        $policyDocumentId: String!\n        $action: PolicyAction\n        $version: Int64\n    ) {\n        publishPolicyDocument(\n            tenantId: $tenantId\n            policyDocumentId: $policyDocumentId\n            action: $action\n            version: $version\n        ) {\n            policyDocumentId\n            status\n            errors {\n                code\n                message\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation deletePolicyDocument($tenantId: ID!, $policyDocumentId: ID!, $version: Int64) {\n        deletePolicyDocument(tenantId: $tenantId, policyDocumentId: $policyDocumentId, version: $version) \n    }\n',
): (typeof documents)['#graphql\n    mutation deletePolicyDocument($tenantId: ID!, $policyDocumentId: ID!, $version: Int64) {\n        deletePolicyDocument(tenantId: $tenantId, policyDocumentId: $policyDocumentId, version: $version) \n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation updatePolicyDocument($tenantId: ID!, $policyUpdates: UpdatePolicyDocumentFieldsInput!) {\n        updatePolicyDocument(tenantId: $tenantId, policyUpdates: $policyUpdates) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation updatePolicyDocument($tenantId: ID!, $policyUpdates: UpdatePolicyDocumentFieldsInput!) {\n        updatePolicyDocument(tenantId: $tenantId, policyUpdates: $policyUpdates) {\n            policyDocumentId\n            displayName\n            description\n            policyDocument\n            status\n            requestedState\n            version\n            originVersion\n            signature\n            createdAt\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation createWebhook($tenantId: ID!, $webhookInput: WebhookInput!) {\n        createWebhook(tenantId: $tenantId, webhookInput: $webhookInput) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation createWebhook($tenantId: ID!, $webhookInput: WebhookInput!) {\n        createWebhook(tenantId: $tenantId, webhookInput: $webhookInput) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query listWebhooks($tenantId: ID!) {\n        listWebhooks(tenantId: $tenantId) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query listWebhooks($tenantId: ID!) {\n        listWebhooks(tenantId: $tenantId) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getWebhook($tenantId: ID!, $webhookId: ID!) {\n        getWebhook(tenantId: $tenantId, webhookId: $webhookId) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getWebhook($tenantId: ID!, $webhookId: ID!) {\n        getWebhook(tenantId: $tenantId, webhookId: $webhookId) {\n            webhookId\n            url\n            method\n            headers {\n                key\n                value\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation deleteWebhook($tenantId: ID!, $webhookId: ID!) {\n        deleteWebhook(tenantId: $tenantId, webhookId: $webhookId)\n    }\n',
): (typeof documents)['#graphql\n    mutation deleteWebhook($tenantId: ID!, $webhookId: ID!) {\n        deleteWebhook(tenantId: $tenantId, webhookId: $webhookId)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getTrustProfileHistory($tenantId: ID!, $profileId: ID, $unit: StatsUnits!, $startDate: Int64!, $endDate: Int64!) {\n        getTrustProfileHistory(tenantId: $tenantId, profileId: $profileId, unit: $unit, startDate: $startDate, endDate: $endDate) {\n            items {\n                date\n                trustScore\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getTrustProfileHistory($tenantId: ID!, $profileId: ID, $unit: StatsUnits!, $startDate: Int64!, $endDate: Int64!) {\n        getTrustProfileHistory(tenantId: $tenantId, profileId: $profileId, unit: $unit, startDate: $startDate, endDate: $endDate) {\n            items {\n                date\n                trustScore\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getStorageKeys($tenantId: ID!) {\n        getStorageKeys(tenantId: $tenantId) {\n            keys {\n                key\n                shared\n                userId\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getStorageKeys($tenantId: ID!) {\n        getStorageKeys(tenantId: $tenantId) {\n            keys {\n                key\n                shared\n                userId\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getStorage($tenantId: ID!, $userId: String!, $key: String!, $shared: Boolean!) {\n        getStorage(tenantId: $tenantId, userId: $userId, key: $key, shared: $shared) {\n                id\n                key\n                data\n                publishedAt\n                updatedAt\n                shared\n            }\n        }\n',
): (typeof documents)['#graphql\n    query getStorage($tenantId: ID!, $userId: String!, $key: String!, $shared: Boolean!) {\n        getStorage(tenantId: $tenantId, userId: $userId, key: $key, shared: $shared) {\n                id\n                key\n                data\n                publishedAt\n                updatedAt\n                shared\n            }\n        }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation setStorage($tenantId: ID!, $key: String!, $data: String!, $shared: Boolean!) {\n        setStorage(tenantId: $tenantId, key: $key, data: $data, shared: $shared) {\n            id\n            key\n            data\n            publishedAt\n            updatedAt\n            shared\n        }\n    }\n',
): (typeof documents)['#graphql\n    mutation setStorage($tenantId: ID!, $key: String!, $data: String!, $shared: Boolean!) {\n        setStorage(tenantId: $tenantId, key: $key, data: $data, shared: $shared) {\n            id\n            key\n            data\n            publishedAt\n            updatedAt\n            shared\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    mutation deleteStorage($tenantId: ID!, $key: String!, $shared: Boolean!) {\n        deleteStorage(tenantId: $tenantId, key: $key, shared: $shared)\n    }\n',
): (typeof documents)['#graphql\n    mutation deleteStorage($tenantId: ID!, $key: String!, $shared: Boolean!) {\n        deleteStorage(tenantId: $tenantId, key: $key, shared: $shared)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query listAlerts($tenantId: ID!, $input: ListAlertInput!) {\n        listAlerts(tenantId: $tenantId, input: $input) {\n            alerts {\n                id\n                raisedAt\n                eventDate\n                status\n                muted\n                observedCount\n                definitionId\n                alertDefinitionName\n                triggeredNodeId\n            }\n            pageInfo {\n                currentPage\n                pageCount\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query listAlerts($tenantId: ID!, $input: ListAlertInput!) {\n        listAlerts(tenantId: $tenantId, input: $input) {\n            alerts {\n                id\n                raisedAt\n                eventDate\n                status\n                muted\n                observedCount\n                definitionId\n                alertDefinitionName\n                triggeredNodeId\n            }\n            pageInfo {\n                currentPage\n                pageCount\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getTotalAlertCount($tenantId: ID!) {\n        getTotalAlertCount (tenantId: $tenantId) {\n            count\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getTotalAlertCount($tenantId: ID!) {\n        getTotalAlertCount (tenantId: $tenantId) {\n            count\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '#graphql\n    query getTenantBySlug($slug: String!) {\n        getTenantBySlug(slug: $slug) {\n            name\n            slug\n            tenantId\n            metadata {\n                saasPlatformTenantId\n                saasPlatformTenantUrl\n                saasPlatformTenantApiUrl\n                saasPlatformTenantAccessTokenUrl\n            }\n        }\n    }\n',
): (typeof documents)['#graphql\n    query getTenantBySlug($slug: String!) {\n        getTenantBySlug(slug: $slug) {\n            name\n            slug\n            tenantId\n            metadata {\n                saasPlatformTenantId\n                saasPlatformTenantUrl\n                saasPlatformTenantApiUrl\n                saasPlatformTenantAccessTokenUrl\n            }\n        }\n    }\n'];

export function gql(source: string) {
    return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
    TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
