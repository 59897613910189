import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { initialize, LDUser } from 'launchdarkly-js-client-sdk';
import { useHistory, useLocation } from 'react-router-dom';
import { Environments } from 'Types/types';

const ld_client_id = window.__env__.LAUNCH_DARKLY_CLIENT_ID || '';

interface OrganizationContextProps {
    auth: string;
    gql: string;
    setOrgName: (orgName: string) => void;
    isSailPointAuth: boolean;
    isAuth0Auth: boolean;
    isKeycloakAuth: boolean;
}

const OrganizationContext = createContext<OrganizationContextProps>({
    auth: '',
    gql: '',
    setOrgName: () => {},
    isSailPointAuth: false,
    isAuth0Auth: false,
    isKeycloakAuth: false,
});

interface OrganizationProviderProps {
    children: ReactNode;
}

const defaultDevAuthAndGql = { auth: 'keycloak', gql: 'http://graph.00.local/query' };

const OrganizationProvider: React.FC<OrganizationProviderProps> = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const [auth, setAuth] = useState('');
    const [gql, setGql] = useState('');
    const [orgName, setOrgName] = useState(localStorage.getItem('currentOrganization') || '');
    const [errorResolvingOrg, setErrorResolvingOrg] = useState(false);

    useEffect(() => {
        if (!orgName && history.location.pathname !== '/auth/login') {
            console.log('No organization details found, redirecting to login');
            history.push('/auth/login');
        }
    }, [history, orgName]);

    useEffect(() => {
        async function fetchOrgFlagData() {
            console.log('Fetching LaunchDarkly data for organization:', orgName);
            const context: LDUser = {
                key: orgName,
                custom: {
                    orgName: orgName,
                },
            };
            const client = initialize(ld_client_id, context, { streaming: false });

            try {
                await client.waitForInitialization();

                const { auth, gql } = client.variation('authAndClusterSelection');

                setAuth(auth);
                setGql(gql);

                console.log('Retrieved Auth and GQL for organization:', orgName, auth, gql);
            } catch (error) {
                if (getEnvironment() === 'dev') {
                    console.warn(
                        'Could not retrieve Auth and GQL parameters from LaunchDarkly, falling back to local settings',
                        defaultDevAuthAndGql,
                    );
                    setAuth(defaultDevAuthAndGql.auth);
                    setGql(defaultDevAuthAndGql.gql);
                    return;
                } else {
                    console.error(
                        'Could not retrieve Auth and GQL parameters from LaunchDarkly, cannot proceed',
                        error,
                    );
                    setErrorResolvingOrg(true);
                }
            }
        }

        if (orgName) {
            fetchOrgFlagData();
        }
    }, [orgName]);

    const shouldRender = auth || location.pathname === '/auth/login';

    if (errorResolvingOrg) {
        return <ResolvingOrganization />;
    }

    return shouldRender ? (
        <OrganizationContext.Provider
            value={{
                auth,
                gql,
                setOrgName,
                isSailPointAuth: auth === 'sailpoint',
                isAuth0Auth: auth === 'auth0',
                isKeycloakAuth: auth === 'keycloak',
            }}
        >
            {children}
        </OrganizationContext.Provider>
    ) : (
        <ResolvingOrganization />
    );
};

// Custom hook to use the OrganizationContext
const useOrganization = () => {
    return useContext(OrganizationContext);
};

export { OrganizationProvider, useOrganization };

const ResolvingOrganization = () => {
    return (
        <div className="flex h-screen">
            <div className="m-auto text-center">
                <p className="text-gray-500 text-xs">Resolving your SailPoint Identity Risk instance...</p>
            </div>
        </div>
    );
};

const getEnvironment = (): Environments => {
    if (
        process.env.NODE_ENV === 'development' ||
        window.__env__.ENVIRONMENT === 'dev' ||
        window.__env__.ENVIRONMENT === 'local'
    ) {
        return 'dev';
    } else if (window.__env__.ENVIRONMENT === 'staging') {
        return 'staging';
    } else if (window.__env__.ENVIRONMENT === 'prod') {
        return 'prod';
    }
    return 'dev';
};
