import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import KeycloakProviderWithHistory from './KeycloakProviderWithHistory';
import { NoAuthProvider } from './AuthStatusMessageComponents';
import SailPointAuthProviderWithHistory from './SailPointAuthProviderWithHistory';
import { useOrganization } from './OrganizationProvider';

const AuthenticationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const { auth } = useOrganization();

    switch (auth) {
        case 'keycloak':
            return <KeycloakProviderWithHistory children={children} />;
        case 'auth0':
            return <Auth0ProviderWithHistory children={children} />;
        case 'sailpoint':
            return <SailPointAuthProviderWithHistory children={children} />;
        default:
            return <NoAuthProvider provider={auth} />;
    }
};

export default AuthenticationProvider;
