import React, { useEffect } from 'react';
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    HttpLink,
    NormalizedCacheObject,
    TypePolicies,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { useAuth } from 'Hooks/Auth';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useOrganization } from './OrganizationProvider';

const AuthorizedApolloProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const client = React.useRef<ApolloClient<NormalizedCacheObject> | undefined>();
    const { getToken } = useAuth();
    const { graphqlUri } = useFlags();
    const ldClient = useLDClient();

    const { gql } = useOrganization();

    // Derive the GraphQL URI from either the Launch Darkly flag or the config file
    useEffect(() => {
        if (gql) {
            const httpLink = new HttpLink({
                uri: gql,
            });

            const authLink = setContext(async (_, { headers, ...rest }) => {
                const token = await getToken();

                if (!token) return { headers, ...rest };

                return {
                    ...rest,
                    headers: {
                        ...headers,
                        authorization: `Bearer ${token}`,
                    },
                };
            });

            console.log('Creating Apollo Client with resolved GraphQL URI:', gql);

            client.current = new ApolloClient({
                connectToDevTools: true,
                link: authLink.concat(httpLink),
                cache: apolloCache,
            });

            console.log('Updating Launch Darkly context with resolved GraphQL URI', gql);
            const currentContext = ldClient?.getContext();
            ldClient?.identify({
                ...currentContext,
                custom: { ...currentContext?.custom, graphqlUri: gql },
            });
        }
    }, [getToken, gql, graphqlUri, ldClient]);

    // Until we receive details from launch darkly on which graphql endpoint to use,
    // we will display a waiting message
    if (!graphqlUri || !client.current) {
        return <NoGraphQLURI />;
    }

    return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;

export const NoGraphQLURI = () => {
    return (
        <div className="flex h-screen">
            <div className="m-auto text-center">
                <p className="text-gray-500 text-xs">Resolving your SailPoint Identity Risk instance...</p>
            </div>
        </div>
    );
};

const typePolicies: TypePolicies = {
    User: {
        keyFields: ['userId'],
    },
    Provider: {
        keyFields: ['providerId'],
    },
    GetEntityListItem: {
        keyFields: false,
    },
    GetEntityByNeighborCountItem: {
        keyFields: false,
    },
    GetEntityByStatusItem: {
        keyFields: false,
    },
    GetEntityDormantItem: {
        keyFields: false,
    },
    GetEntityAccessorsEntityItem: {
        keyFields: false,
    },
};
const apolloCache = new InMemoryCache({ typePolicies });
