import { MinusIcon } from '@heroicons/react/24/outline';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    classNames,
    getDisplayName,
    getOperatingSystemDisplayNameFromNode,
    tagNameLookup,
    targetNodeTypeLookup,
    wordWrap,
} from 'Utilities/utils';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { getIconSourceURL, getNodeIconElement, getOSIconElement, getTagIconElement } from '../Graph/Icons';
import { ForceGraphMethods } from 'react-force-graph-2d';
import { format } from 'date-fns';
import { isATargetGroup } from 'Utilities/NodeUtilities';
import { useGraphControls } from 'Hooks/GraphHooks';
import Avatar from 'react-avatar';
import { useContextMenu, TriggerEvent } from 'react-contexify';
import { ContextMenu } from './ContextMenu';
import { Portal } from 'react-portal';
import { Tooltip } from 'Library/Tooltip';
import { Switch } from '@headlessui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface NodePanelProps {
    node: Node;
    nodeType?: string | undefined;
    displayName?: string | undefined;
    setPanelSize?: () => void;
    scrollRef?: React.RefObject<HTMLDivElement>;
}

export const NodePanel = ({ node, scrollRef }: NodePanelProps): JSX.Element => {
    const { queryNodesBlocklist } = useFlags();
    const { dispatch, mapState } = useContext(IdentityMapContext);
    const { graphRef } = mapState;
    const { zoomToNode, removeNodeFromExplorer, addNodeToQuery, removeNodeFromQuery, isNodeInQuery } =
        useGraphControls();

    const [expanded, setExpanded] = useState(false);
    const [hasBeenInQuery, setHasBeenInQuery] = useState(false);

    // if the node is in the query, set hasBeenInQuery to true
    if (!hasBeenInQuery && isNodeInQuery(node)) {
        setHasBeenInQuery(true);
    }

    // Detect if we should disable the search button if there is already a query node toggled on
    const isQueryNode = node.label === 'query';
    const isQueryNodeInQuery = useMemo((): boolean => {
        return Array.from(mapState.queriedNodes).some((n) => n.label === 'query');
    }, [mapState.queriedNodes]);

    const isBlocklisted = useMemo(() => {
        return queryNodesBlocklist.includes(getDisplayName(node));
    }, [node, queryNodesBlocklist]);

    useEffect(() => {
        if (queryNodesBlocklist.includes(getDisplayName(node))) {
            removeNodeFromQuery(node);
        }
    }, [isNodeInQuery, node, queryNodesBlocklist, removeNodeFromQuery]);

    const disableSearchTooManyNodes = isQueryNode && isQueryNodeInQuery && !isNodeInQuery(node);
    const disableSearchBlocklisted = isBlocklisted;
    const disableSearchToggle = disableSearchTooManyNodes || disableSearchBlocklisted;

    const isNodeOnMap = useMemo((): boolean => {
        if (mapState.graphData.nodes.find((n) => n.id === node.id)) {
            return true;
        } else {
            return false;
        }
    }, [mapState.graphData.nodes, node.id]);

    const numberOfTags =
        node.label == 'device' && node.props.deviceOperatingSystem ? node.tags.length + 1 : node.tags.length;

    // 45 degrees is the angle of the first tag (in radians)
    let angle = 0.785;

    // how many radians between each tag
    const step = (2 * Math.PI) / numberOfTags;

    const { show } = useContextMenu({
        id: node.id,
    });

    const displayContextMenu = (e: TriggerEvent & { clientX: number; clientY: number }) => {
        // prevent default NodePanel click action
        e.stopPropagation();
        e.preventDefault();

        // show context menu
        show(e, {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
        });
    };

    return (
        <>
            <div
                onContextMenu={displayContextMenu}
                onClick={() => {
                    isNodeOnMap && zoomToNode(node, graphRef);
                }}
                ref={scrollRef ? scrollRef : undefined}
                className={classNames(
                    'SmallNodePanel pointer-events-auto relative',
                    mapState.selectedProfileNode === node
                        ? 'bg-blue-900/50 hover:bg-blue-900/50 bg-blend-multiply border border-blue-600  '
                        : 'bg-gray-800 hover:bg-gray-900 border border-transparent',
                )}
            >
                <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div
                        className="p-3 flex items-center justify-start flex-1"
                        onClick={() => {
                            if (
                                node.label == 'actor' ||
                                node.label == 'device' ||
                                node.label == 'query' ||
                                (node.label == 'target' && !isATargetGroup(node))
                            ) {
                                dispatch({ type: 'set-profile-node', node: node });
                                dispatch({ type: 'set-profile-window', open: true });
                            } else {
                                setExpanded(!expanded);
                            }
                        }}
                    >
                        <Tooltip label="Unselect">
                            <button
                                type="button"
                                className="flex-shrink-0 text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 shadow-md"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeNodeFromExplorer(node);
                                }}
                            >
                                <MinusIcon className="h-3.5 w-3.5 text-gray-200" aria-hidden="true" />
                            </button>
                        </Tooltip>
                        <div className="flex-shrink-0 items-center relative mx-3">
                            <div
                                className={classNames(
                                    'h-10 w-10 rounded-full bg-gray-900 border p-2 border-gray-600 flex items-center justify-center',
                                    mapState.lockedNodes.has(node) ? 'ring-offset-blue-400 border-blue-400' : '',
                                )}
                            >
                                {node.label == 'actor' ? (
                                    <Avatar
                                        size="100%"
                                        name={getDisplayName(node)}
                                        round={true}
                                        maxInitials={2}
                                        className="block h-5 w-5 flex-shrink-0 flex-grow-0"
                                    />
                                ) : (
                                    <img src={getIconSourceURL(getNodeIconElement(node))} alt="" />
                                )}
                            </div>
                            {node.tags.map((tag) => {
                                const tagElement = (
                                    <div
                                        key={tag}
                                        className={`rounded-full border border-gray-700 bg-gray-800 p-1 flex place-content-center absolute`}
                                        style={{
                                            // center the tag in the middle of the 00 icon
                                            top: '8px',
                                            left: '8px',
                                            // rotate the tag to the correct angle then translate to the edge of the 00 icon
                                            transform: `rotate(${angle}rad) translateX(20px)`,
                                        }}
                                    >
                                        <img
                                            key={tag}
                                            src={getIconSourceURL(getTagIconElement(tag))}
                                            style={{
                                                // the actual tag icon will be incorrectly rotated after the parent transform, so we need to
                                                // inverse rotate the tag icon to counteract the parent transform
                                                transform: `rotate(-${angle}rad)`,
                                            }}
                                            alt={tagNameLookup(tag)}
                                            title={tagNameLookup(tag)}
                                            className="h-3 w-3"
                                        />
                                    </div>
                                );
                                angle += step;
                                return tagElement;
                            })}

                            {node.label == 'device' && (
                                <div
                                    className="rounded-full border border-gray-700 bg-gray-800 p-1 flex place-content-center absolute"
                                    style={{
                                        // center the tag in the middle of the 00 icon
                                        top: '8px',
                                        left: '8px',
                                        // rotate the tag to the correct angle then translate to the edge of the 00 icon
                                        transform: `rotate(${angle}rad) translateX(20px)`,
                                    }}
                                >
                                    <img
                                        className="h-3 w-3 block"
                                        src={getIconSourceURL(getOSIconElement(node))}
                                        style={{
                                            // the actual tag icon will be incorrectly rotated after the parent transform, so we need to
                                            // inverse rotate the tag icon to counteract the parent transform
                                            transform: `rotate(-${angle}rad)`,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex-1 flex-shrink-1">
                            <h3
                                dangerouslySetInnerHTML={wordWrap(getDisplayName(node))}
                                className="text-xs leading-5 w-full font-medium text-gray-300 word-wrap"
                            ></h3>
                            <p className="text-xs text-gray-500 capitalize">
                                {node.label == 'identity' || node.label == 'device' || node.tags.length > 0
                                    ? ''
                                    : node.label}
                                {node.label == 'target' && node.nodeType && node.nodeType != 'NODE_TYPE_UNKNOWN' && (
                                    <span> • {targetNodeTypeLookup(node.nodeType)}</span>
                                )}
                            </p>
                            <p className="text-xs text-gray-500 capitalize">
                                {node.label == 'device' && getOperatingSystemDisplayNameFromNode(node)}
                            </p>
                            <div className="text-xs text-gray-500">
                                {node.tags.map((tag) => {
                                    return (
                                        <p className="block" key={tag}>
                                            {tagNameLookup(tag)}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="actions flex-shrink-0 flex items-center space-x-3 pr-4">
                        <Tooltip
                            label={
                                disableSearchToggle
                                    ? disableSearchTooManyNodes
                                        ? 'Only one query may be toggled on at a time. Disable the current query to add a new one.'
                                        : 'This node is currently not available for searching'
                                    : isQueryNode
                                      ? 'Search for nodes and events that match this query in the current time range'
                                      : "Search for this node and it's neighbors in the current time range"
                            }
                        >
                            <div className="bg-gray-900 rounded-full pl-3">
                                <Switch.Group
                                    as="div"
                                    className={classNames('flex items-center', disableSearchToggle ? 'opacity-50' : '')}
                                >
                                    <Switch.Label as="span" className="mr-2 text-xs text-gray-400">
                                        Search
                                    </Switch.Label>
                                    <Switch
                                        disabled={disableSearchToggle}
                                        checked={isNodeInQuery(node)}
                                        onChange={(enabled) => {
                                            enabled ? addNodeToQuery(node) : removeNodeFromQuery(node);
                                        }}
                                        className={classNames(
                                            isNodeInQuery(node) ? 'bg-blue-800' : 'bg-gray-600',
                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0',
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                isNodeInQuery(node) ? 'translate-x-5' : 'translate-x-0',
                                                !isNodeInQuery(node) && !hasBeenInQuery && !disableSearchToggle
                                                    ? 'animation-slide'
                                                    : '',
                                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-gray-300 shadow ring-0 transition duration-200 ease-in-out',
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                            </div>
                        </Tooltip>

                        <Tooltip label="Show Actions">
                            <button
                                id="Menu"
                                type="button"
                                onClick={displayContextMenu}
                                className="btn text-xs rounded-md p-1 block"
                            >
                                <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                {expanded && (
                    <div className="text-xs p-4 pt-0">
                        <GetPanelDisplay node={node} graphRef={graphRef} />
                    </div>
                )}
            </div>
            <Portal>
                <ContextMenu id={node.id} node={node} />
            </Portal>
        </>
    );
};

const GetPanelDisplay = ({
    node,
    graphRef,
}: {
    node: Node;
    graphRef: React.MutableRefObject<ForceGraphMethods | undefined> | undefined;
}): JSX.Element => {
    const { zoomToNode } = useGraphControls();

    const formatDate = (ms: Date | string | number): string => {
        return format(new Date(ms), 'MMM dd, hh:mm a');
    };
    if (node.label == 'actor') {
        return (
            <>
                <div className="ml-9">
                    <ul className="space-y-3">
                        {node.neighbors.map((node: Node) => {
                            return (
                                <li key={node.id} className="space-y-1">
                                    <div
                                        className="mb-2 flex items-center cursor-pointer hover:text-white"
                                        onClick={() => zoomToNode(node, graphRef)}
                                    >
                                        <div className="flex-shrink-0 rounded-full border border-gray-600 p-1 mr-7">
                                            <div className="h-6 w-6 flex-shrink-0 flex-grow-0">
                                                <Avatar
                                                    size="100%"
                                                    name={getDisplayName(node)}
                                                    round={true}
                                                    maxInitials={2}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="text-xs">{node.props.displayName}</p>
                                            <p className="text-xs text-gray-500">
                                                {node.props.sessionStart && (
                                                    <span>
                                                        Session started on{' '}
                                                        {formatDate(node.props.sessionStart / 1000000)}
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="ml-14 space-y-2 mb-2">
                                        {node.neighbors.map((node: Node) => {
                                            if (node.label == 'target') {
                                                return (
                                                    <li
                                                        key={node.id}
                                                        className="flex items-center cursor-pointer hover:text-white"
                                                        onClick={() => zoomToNode(node, graphRef)}
                                                    >
                                                        <img
                                                            src={getIconSourceURL(getNodeIconElement(node))}
                                                            className="flex-shrink-0 h-4 w-4 mr-5"
                                                        />
                                                        <p className="text-xs">{getDisplayName(node)}</p>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </>
        );
    } else if (node.label == 'identity') {
        return (
            <ul className="ml-9 space-y-2">
                {node.neighbors.map((node: Node) => {
                    if (node.label == 'target') {
                        return (
                            <li
                                key={node.id}
                                className="flex items-center cursor-pointer hover:text-white"
                                onClick={() => zoomToNode(node, graphRef)}
                            >
                                <div className="flex-shrink-0 rounded-full border border-gray-600 p-1 mr-7">
                                    <img src={getIconSourceURL(getNodeIconElement(node))} className="h-4 w-4" />
                                </div>
                                <p className="text-xs">{getDisplayName(node)}</p>
                            </li>
                        );
                    }
                })}
            </ul>
        );
    } else if (node.label == 'application') {
        return (
            <ul className="ml-9 space-y-2">
                {node.neighbors.map((node: Node) => {
                    if (node.label == 'identity') {
                        return (
                            <li
                                key={node.id}
                                className="flex items-center cursor-pointer hover:text-white"
                                onClick={() => zoomToNode(node, graphRef)}
                            >
                                <div className="flex-shrink-0 rounded-full border border-gray-600 p-1 mr-7">
                                    <img src={getIconSourceURL(getNodeIconElement(node))} className="h-4 w-4" />
                                </div>
                                <p className="text-xs">{getDisplayName(node)}</p>
                            </li>
                        );
                    }
                })}
            </ul>
        );
    } else if (node.label == 'device') {
        return (
            <ul className="ml-9 space-y-2">
                {node.neighbors.map((node: Node) => {
                    if (node.label == 'identity') {
                        return (
                            <li
                                key={node.id}
                                className="flex items-center cursor-pointer hover:text-white"
                                onClick={() => zoomToNode(node, graphRef)}
                            >
                                <div className="flex-shrink-0 rounded-full border border-gray-600 p-1 mr-7">
                                    <img src={getIconSourceURL(getNodeIconElement(node))} className="h-4 w-4" />
                                </div>
                                <p className="text-xs">{getDisplayName(node)}</p>
                            </li>
                        );
                    }
                })}
            </ul>
        );
    } else if (node.label == 'target') {
        return (
            <ul className="ml-9 space-y-2">
                {node.neighbors.map((node) =>
                    node.neighbors
                        .filter((node) => node.label == 'actor')
                        .map((node) => {
                            return (
                                <li
                                    key={node.id}
                                    className="flex items-center cursor-pointer hover:text-white"
                                    onClick={() => zoomToNode(node, graphRef)}
                                >
                                    <div className="flex-shrink-0 rounded-full border border-gray-600 p-1 mr-7">
                                        <img src={getIconSourceURL(getNodeIconElement(node))} className="h-4 w-4" />
                                    </div>
                                    <p className="text-xs">{getDisplayName(node)}</p>
                                </li>
                            );
                        }),
                )}
            </ul>
        );
    } else {
        return (
            <div className="ml-24 text-xs p-3 pt-0 pl-2 ">
                <h4>{node.neighbors.length} Related Nodes</h4>
            </div>
        );
    }
};
