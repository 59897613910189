import { DashboardTabs } from 'Dashboard/DashboardModalTypes';
import { GET_ENTITY_BY_STATUS, GET_ENTITY_LIST, GET_GLOBAL_EVENTS_HISTORY, GET_STATUS_REASONS } from 'Graph/queries';
import { BackendNode } from 'Map/Graph/Data';
import { DocumentNode } from 'graphql';

type QuerySpec = {
    query: DocumentNode;
    queryName: string;
    reportName: string;
    fileName: string;
    variables: Record<string, string | number | boolean>;
    processItem: (item: Record<string, string>, node?: BackendNode) => Record<string, string>;
    alternateNodeKey?: string;
    resolveNodes?: boolean;
    hasLimit?: boolean;
    hasUnit?: boolean;
};

type AggregatedQuerySpec = QuerySpec[];

type CSVSpecDefinitionsType = {
    [K in DashboardTabs]: {
        name: string;
        aggregatedQueries: AggregatedQuerySpec;
    };
};

export const CSVSpecDefinitions: CSVSpecDefinitionsType = {
    access: {
        name: 'Overview',
        aggregatedQueries: [
            {
                query: GET_GLOBAL_EVENTS_HISTORY,
                queryName: 'getEntityHistory',
                fileName: 'access-history-time-series.csv',
                reportName: 'Access History Time Series',
                hasUnit: true,
                processItem: (item) => {
                    return {
                        timestamp: item.date,
                        date: new Date(item.date).toISOString(),
                        success: item.successCount + item.allowCount,
                        challenge: item.challengeCount,
                        failure: item.denyCount + item.failureCount,
                    };
                },
                variables: {
                    entityType: 'STATS_ENTITY_TYPE_ACTOR',
                    detailed: false,
                },
            },
            {
                query: GET_ENTITY_LIST,
                queryName: 'getEntityList',
                fileName: 'actor-activity.csv',
                reportName: 'Actor Activity',
                hasLimit: true,
                resolveNodes: true,
                processItem: (item, node) => {
                    return {
                        id: item.id,
                        name: node?.props.displayName ?? '',
                        alternateId: node?.props.alternateId ?? '',
                        accessCount: item.accessCount,
                    };
                },
                variables: {
                    entityType: 'STATS_ENTITY_TYPE_ACTOR',
                    order: 'STATS_ORDER_DESC',
                },
            },
            {
                query: GET_ENTITY_BY_STATUS,
                queryName: 'getEntityByStatus',
                fileName: 'top-failing-actors.csv',
                reportName: 'Top Failing Actors',
                hasLimit: true,
                resolveNodes: true,
                processItem: (item, node) => {
                    return {
                        id: item.id,
                        name: node?.props.displayName ?? '',
                        alternateId: node?.props.alternateId ?? '',
                        totalCount: item.totalCount,
                        failCount: item.count,
                        failRate: item.rate,
                    };
                },
                variables: {
                    entityType: 'STATS_ENTITY_TYPE_ACTOR',
                    order: 'STATS_ORDER_DESC',
                    status: 'OUTCOME_DENY',
                },
            },
            {
                query: GET_ENTITY_LIST,
                queryName: 'getEntityList',
                fileName: 'target-activity.csv',
                reportName: 'Target Activity',
                hasLimit: true,
                resolveNodes: true,
                processItem: (item, node) => {
                    return {
                        id: item.id,
                        nodeType: node?.nodeType ?? '',
                        name: node?.props.displayName ?? '',
                        alternateId: node?.props.alternateId ?? '',
                        accessCount: item.accessCount,
                    };
                },
                variables: {
                    entityType: 'STATS_ENTITY_TYPE_TARGET',
                    order: 'STATS_ORDER_DESC',
                },
            },
            {
                query: GET_STATUS_REASONS,
                queryName: 'getStatusReasons',
                fileName: 'top-access-failure-reason.csv',
                reportName: 'Top Access Failure Reason',
                hasLimit: true,
                resolveNodes: false,
                processItem: (item) => {
                    return {
                        reason: item.reason,
                        count: item.count,
                    };
                },
                variables: {
                    status: 'OUTCOME_DENY',
                    order: 'STATS_ORDER_DESC',
                },
            },
        ],
    },
    actors: {
        name: 'Actor Overview',
        aggregatedQueries: [],
    },
    targets: {
        name: 'Target Overview',
        aggregatedQueries: [],
    },
    events: {
        name: 'Event Overview',
        aggregatedQueries: [],
    },
    location: {
        name: 'Location Overview',
        aggregatedQueries: [],
    },
    trust: {
        name: 'Trust Overview',
        aggregatedQueries: [],
    },
    'location-future': {
        name: 'Location Future',
        aggregatedQueries: [],
    },
    'trust-future': {
        name: 'Trust Future',
        aggregatedQueries: [],
    },
};
