import {
    CogIcon,
    ExclamationTriangleIcon,
    IdentificationIcon,
    KeyIcon,
    BoltIcon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UsersIcon,
    SquaresPlusIcon,
    XMarkIcon,
    ChatBubbleLeftEllipsisIcon,
    ShareIcon,
} from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Fragment, useState, useContext, ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';
import { Transition } from '@headlessui/react';
import Account from './Panels/Account';
import UsersPanel from './Panels/Users';
import Profile from './Panels/Profile';
import ProviderPanel from './Panels/ProvidersPanel';
import Advanced from './Panels/Advanced';
import SSOPanel from './Panels/SSO';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { useHotkeys } from 'react-hotkeys-hook';
import { classNames } from 'Utilities/utils';
import Keybindings from './Panels/Keybindings';

import { useQuery } from '@apollo/client';
import {
    GET_PROVIDER_FIELD_MAP,
    GET_TENANT,
    LIST_API_KEYS,
    LIST_PROVIDERS,
    LIST_ROLES,
    LIST_USERS,
} from 'Graph/queries';
import { useProductTutorial, useTenant } from 'Hooks/Hooks';
import { useHistory } from 'react-router-dom';
import ApiKeys from './Panels/ApiKeys';
import { Tooltip } from 'Library/Tooltip';
import { Webhooks } from './Panels/Webhooks';
import { useOrganization } from 'Utilities/OrganizationProvider';

export enum ConfigurationPanel {
    Profile,
    Account,
    Users,
    Providers,
    Security,
    Notifications,
    SSO,
    Keybindings,
    Advanced,
    Api,
    Webhooks,
}

interface NavItemProps {
    id: string;
    panel: ConfigurationPanel;
    Icon: ForwardRefExoticComponent<SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>>;
}

export const ConfigurationModal = (): JSX.Element => {
    const { advancedSettings, enableSingleSignOn, enableApi, enableWebhooks } = useFlags();
    const [activePanel, setActivePanel] = useState(ConfigurationPanel.Profile);
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { isAuth0Auth } = useOrganization();

    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();

    const closeConfig = () => dispatch({ type: 'toggle-config' });
    const history = useHistory();

    const terms = (): void => {
        history.push('/public/terms');
    };

    useHotkeys('esc', closeConfig);

    // Pre-fetches the data that will be used in the configuration modal
    // If there are new queries, add them here
    const tenantId = useTenant();
    useQuery(GET_TENANT, { variables: { tenantId } });
    useQuery(LIST_USERS, { variables: { tenantId } });
    useQuery(LIST_ROLES, { variables: { tenantId } });
    useQuery(LIST_PROVIDERS, { variables: { tenantId } });
    useQuery(GET_PROVIDER_FIELD_MAP, { variables: { tenantId } });
    useQuery(LIST_API_KEYS, { variables: { tenantId }, skip: !enableApi || !isAuth0Auth });

    const NavItem = ({ id, panel, Icon }: NavItemProps) => {
        const title = ConfigurationPanel[panel];
        const current = panel === activePanel;
        return (
            <span
                id={id}
                key={title}
                onClick={() => {
                    setActivePanel(panel);
                    runOnTutorialStep(['Users Nav', 'Providers Nav', 'Keybinds Nav'], () => {
                        moveToNextTutorialStep();
                    });
                }}
                className={classNames(
                    current
                        ? 'bg-blue-800 border-blue-700 text-gray-200'
                        : 'border-transparent  hover:bg-gray-700 text-gray-400 hover:text-gray-200',
                    'cursor-pointer group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                )}
                aria-current={current ? 'page' : undefined}
            >
                <Helmet>
                    <title>Settings</title>
                </Helmet>
                <Icon
                    className={classNames(
                        current ? '' : 'text-gray-400 group-hover:text-gray-200',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    )}
                    aria-hidden="true"
                />
                <span className="truncate">{panel == ConfigurationPanel.Api ? 'API Keys' : title}</span>

                {panel == ConfigurationPanel.Providers && mapState.hasProviderError && (
                    <div className="flex-1 flex items-center justify-end">
                        <ExclamationTriangleIcon className="h-5 w-5 rounded-full text-yellow-500" />
                    </div>
                )}

                {panel == ConfigurationPanel.Providers && mapState.hasProviderInfo && (
                    <div className="flex-1 flex items-center justify-end">
                        <Tooltip label="You can configure your first provider here ⚡️">
                            <div className="h-7 w-7 p-1 rounded-full bg-blue-700 place-items-center">
                                <ChatBubbleLeftEllipsisIcon className="h-5 w-5 rounded-full text-white" />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </span>
        );
    };

    const Navigation = (): JSX.Element => (
        <nav className="space-y-1">
            <p className="uppercase tracking-wider px-4 font-bold text-xs text-gray-400 mb-3">Settings</p>
            <NavItem id="Profile" panel={ConfigurationPanel.Profile} Icon={UserCircleIcon} />
            <NavItem id="Account" panel={ConfigurationPanel.Account} Icon={BuildingOfficeIcon} />
            <NavItem id="Users" panel={ConfigurationPanel.Users} Icon={UsersIcon} />
            <NavItem id="Providers" panel={ConfigurationPanel.Providers} Icon={SquaresPlusIcon} />
            <NavItem id="Keybindings" panel={ConfigurationPanel.Keybindings} Icon={BoltIcon} />
            {enableSingleSignOn && <NavItem id="SSO" panel={ConfigurationPanel.SSO} Icon={IdentificationIcon} />}
            {enableApi && <NavItem id="API" panel={ConfigurationPanel.Api} Icon={KeyIcon} />}
            {enableWebhooks && <NavItem id="WebHooks" panel={ConfigurationPanel.Webhooks} Icon={ShareIcon} />}
            {advancedSettings && <NavItem id="Advanced" panel={ConfigurationPanel.Advanced} Icon={CogIcon} />}
        </nav>
    );

    return (
        <Transition.Root show={true} as={Fragment}>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity top-[64px] h-[calc(100vh-64px)]"
                        onClick={closeConfig}
                    />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <div
                        className="inline-block text-left relative shadow-xl transform 
                               transition-all align-middle md:w-7/12 md:max-w-7xl sm:2/3 w-full"
                    >
                        <div
                            id="Config"
                            className="overflow-hidden bg-gray-800 rounded-md divide-y divide-gray-600 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
                        >
                            <aside className="py-6 lg:col-span-3 relative">
                                <Navigation />
                                <div className="absolute flex justify-center left-0 w-full bottom-4">
                                    <button
                                        onClick={terms}
                                        className="text-xs text-gray-600 hover:text-gray-400 focus:outline-none"
                                        data-test="terms-of-service"
                                    >
                                        About SailPoint Identity Risk
                                    </button>
                                </div>
                            </aside>
                            <div className="divide-y divide-gray-600 lg:col-span-9">
                                {activePanel === ConfigurationPanel.Profile && <Profile />}
                                {activePanel === ConfigurationPanel.Account && <Account />}
                                {activePanel === ConfigurationPanel.Users && <UsersPanel />}
                                {activePanel === ConfigurationPanel.Providers && <ProviderPanel />}
                                {activePanel === ConfigurationPanel.Keybindings && <Keybindings />}
                                {enableSingleSignOn ? activePanel === ConfigurationPanel.SSO && <SSOPanel /> : null}
                                {enableApi ? activePanel === ConfigurationPanel.Api && <ApiKeys /> : null}
                                {enableApi ? activePanel === ConfigurationPanel.Webhooks && <Webhooks /> : null}
                                {advancedSettings ? activePanel === ConfigurationPanel.Advanced && <Advanced /> : null}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md"
                            onClick={closeConfig}
                            data-test="modal-close"
                        >
                            <XMarkIcon className="h-4 w-4 text-gray-200" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </Transition.Root>
    );
};
