import { GET_GLOBAL_EVENTS_HISTORY } from 'Graph/queries';
import { BackendEventHistoryData } from 'Types/types';
import { BaseChart, DashboardChartProps } from './BaseChart';

export const ActorsOutcomeChart = ({
    startDate,
    endDate,
    interval,
    onBarClick,
    selectedBar,
    plotTrust,
    trustData,
}: DashboardChartProps) => {
    return (
        <BaseChart
            query={GET_GLOBAL_EVENTS_HISTORY}
            queryName="getEntityHistory"
            variables={{
                entityType: 'STATS_ENTITY_TYPE_ACTOR',
                detailed: false,
            }}
            processRow={(row: BackendEventHistoryData) => {
                let trustScore = null;

                if (row.trustScore && row.trustScore >= 0) {
                    trustScore = Math.round(row.trustScore);
                }

                return {
                    Success: row.successCount,
                    Challenge: row.challengeCount,
                    Failure: row.denyCount + row.failureCount,
                    Trust: trustScore,
                };
            }}
            bars={[
                {
                    name: 'Success',
                    dataKey: 'Success',
                    activeColor: 'rgba(101, 163, 13, 1)',
                    inactiveColor: 'rgba(101, 163, 13, 0.5)',
                },
                {
                    name: 'Challenge',
                    dataKey: 'Challenge',
                    activeColor: 'rgba(245, 158, 11, 1)',
                    inactiveColor: 'rgba(245, 158, 11, 0.5)',
                },
                {
                    name: 'Failure',
                    dataKey: 'Failure',
                    activeColor: 'rgba(225, 29, 72, 1)',
                    inactiveColor: 'rgba(225, 29, 72, 0.5)',
                },
            ]}
            startDate={startDate}
            endDate={endDate}
            interval={interval}
            onBarClick={onBarClick}
            selectedBar={selectedBar}
            plotTrust={plotTrust}
            trustData={trustData}
        />
    );
};
