/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    Any: { input: any; output: any };
    Int32: { input: any; output: any };
    Int64: { input: any; output: any };
    Map: { input: any; output: any };
    MapString: { input: any; output: any };
    ProtoAny: { input: any; output: any };
    ProtoBool: { input: any; output: any };
    ProtoInt32: { input: any; output: any };
    ProtoTime: { input: any; output: any };
    Time: { input: any; output: any };
};

export type ApiKey = {
    apiKeyId: Scalars['String']['output'];
    clientKey: Scalars['String']['output'];
    clientSecret: Scalars['String']['output'];
    createdAt: Scalars['Int64']['output'];
    createdByUserId: Scalars['String']['output'];
    name: Scalars['String']['output'];
    roles: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
};

export type AccessEvent = {
    accessOutcome: Scalars['String']['output'];
    accessOutcomeDetails: Scalars['String']['output'];
    dataVolumeInBytes: Scalars['Int64']['output'];
    eventId: Scalars['String']['output'];
    from: Scalars['String']['output'];
    raw: Scalars['String']['output'];
    requestedResource: Scalars['String']['output'];
    tags: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    to: Scalars['String']['output'];
};

export enum AccessOutcomeType {
    OutcomeAllow = 'OUTCOME_ALLOW',
    OutcomeChallenge = 'OUTCOME_CHALLENGE',
    OutcomeDeny = 'OUTCOME_DENY',
    OutcomeFailure = 'OUTCOME_FAILURE',
    OutcomeSkipped = 'OUTCOME_SKIPPED',
    OutcomeSuccess = 'OUTCOME_SUCCESS',
    OutcomeUnknown = 'OUTCOME_UNKNOWN',
}

export type Actor = {
    /** returns the access edges for a given user in the specified timeframe of the query */
    accesses: Scalars['Map']['output'];
    alternateId: Scalars['String']['output'];
    createdAt: Scalars['Int64']['output'];
    devices: Array<Scalars['Map']['output']>;
    displayName: Scalars['String']['output'];
    id: Scalars['String']['output'];
    svid: Scalars['String']['output'];
    tags: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    trustScore: Scalars['Float']['output'];
    updatedAt: Scalars['Int64']['output'];
};

export type ActorQueryInput = {
    actorAlternateId?: InputMaybe<StringQueryInput>;
    actorDisplayName?: InputMaybe<StringQueryInput>;
    actorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    actorType?: InputMaybe<ActorType>;
    externalIdentifiers?: InputMaybe<Array<InputMaybe<ExternalIdentifierInput>>>;
    tags?: InputMaybe<TagQueryInput>;
};

/** Actor type */
export enum ActorType {
    ActorTypeAdministrator = 'ACTOR_TYPE_ADMINISTRATOR',
    ActorTypeCloudOperator = 'ACTOR_TYPE_CLOUD_OPERATOR',
    ActorTypeExternal = 'ACTOR_TYPE_EXTERNAL',
    ActorTypeInternal = 'ACTOR_TYPE_INTERNAL',
    ActorTypeMachine = 'ACTOR_TYPE_MACHINE',
    ActorTypeUnknown = 'ACTOR_TYPE_UNKNOWN',
}

export type AddAlertDefinitionInput = {
    alertThreshold: Scalars['Int32']['input'];
    jobPayload?: InputMaybe<Scalars['String']['input']>;
    jobType: JobType;
    message: Scalars['String']['input'];
    name: Scalars['String']['input'];
    query: Scalars['String']['input'];
    warningThreshold: Scalars['Int32']['input'];
};

export type AddAlertDefinitionOutput = {
    id: Scalars['String']['output'];
};

export type AddAlertInput = {
    definitionId: Scalars['String']['input'];
    muted: Scalars['Boolean']['input'];
    observedCount: Scalars['Int32']['input'];
    status: AlertStatus;
    triggeredNodeId: Scalars['String']['input'];
};

export type AddAlertOutput = {
    id: Scalars['String']['output'];
};

export type Agent = {
    agentId: Scalars['String']['output'];
    arch: AgentArch;
    createdAt: Scalars['Int64']['output'];
    hostname: Scalars['String']['output'];
    identifiers?: Maybe<Array<AgentIdentifier>>;
    lastLocation?: Maybe<AgentLocation>;
    os: AgentOs;
    status: AgentStatus;
    tenantId: Scalars['String']['output'];
    type: AgentType;
    updatedAt: Scalars['Int64']['output'];
    version: Scalars['String']['output'];
};

export enum AgentArch {
    AgentArchArm = 'AGENT_ARCH_ARM',
    AgentArchArm64 = 'AGENT_ARCH_ARM64',
    AgentArchUnknown = 'AGENT_ARCH_UNKNOWN',
    AgentArchX86 = 'AGENT_ARCH_X86',
    AgentArchX86_64 = 'AGENT_ARCH_X86_64',
}

export type AgentControlMessage = {
    content?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    signature?: Maybe<Scalars['String']['output']>;
    targetAgent: Scalars['String']['output'];
    targetTenant: Scalars['String']['output'];
    type: AgentControlMessageType;
};

export enum AgentControlMessageType {
    AgentControlMessageTypeBla = 'AGENT_CONTROL_MESSAGE_TYPE_BLA',
}

export type AgentDataChannelOutput = {
    dns?: Maybe<Array<Scalars['String']['output']>>;
};

export type AgentIdentifier = {
    name: Scalars['String']['output'];
    type: AgentIdentifierType;
    value: Scalars['String']['output'];
};

export type AgentIdentifierInput = {
    name: Scalars['String']['input'];
    type: AgentIdentifierType;
    value: Scalars['String']['input'];
};

export enum AgentIdentifierType {
    AgentIdentifierBiosUuid = 'AGENT_IDENTIFIER_BIOS_UUID',
    AgentIdentifierBluetoothMac = 'AGENT_IDENTIFIER_BLUETOOTH_MAC',
    AgentIdentifierCpuId = 'AGENT_IDENTIFIER_CPU_ID',
    AgentIdentifierUnknown = 'AGENT_IDENTIFIER_UNKNOWN',
    AgentIdentifierWifiMac = 'AGENT_IDENTIFIER_WIFI_MAC',
    AgentIdentifierWiredMac = 'AGENT_IDENTIFIER_WIRED_MAC',
}

export type AgentInput = {
    arch: AgentArch;
    currentLocation: AgentLocationInput;
    hostname: Scalars['String']['input'];
    identifiers?: InputMaybe<Array<AgentIdentifierInput>>;
    os: AgentOs;
    tenantId: Scalars['String']['input'];
    type: AgentType;
    version: Scalars['String']['input'];
};

export type AgentLocation = {
    latitude: Scalars['Float']['output'];
    longitude: Scalars['Float']['output'];
};

export type AgentLocationInput = {
    latitude?: InputMaybe<Scalars['Float']['input']>;
    longitude?: InputMaybe<Scalars['Float']['input']>;
};

export enum AgentOs {
    AgentOsMacos = 'AGENT_OS_MACOS',
    AgentOsUnknown = 'AGENT_OS_UNKNOWN',
}

export type AgentQueryInput = {
    hostname?: InputMaybe<StringQueryInput>;
    version?: InputMaybe<StringQueryInput>;
};

export enum AgentStatus {
    AgentStatusOffline = 'AGENT_STATUS_OFFLINE',
    AgentStatusOnline = 'AGENT_STATUS_ONLINE',
    AgentStatusPending = 'AGENT_STATUS_PENDING',
    AgentStatusUnknown = 'AGENT_STATUS_UNKNOWN',
}

export enum AgentType {
    AgentTypeClientComputer = 'AGENT_TYPE_CLIENT_COMPUTER',
    AgentTypeClientMobile = 'AGENT_TYPE_CLIENT_MOBILE',
    AgentTypeServer = 'AGENT_TYPE_SERVER',
    AgentTypeUnknown = 'AGENT_TYPE_UNKNOWN',
}

export type Alert = {
    definitionId: Scalars['String']['output'];
    eventDate?: Maybe<Scalars['Int64']['output']>;
    id: Scalars['String']['output'];
    message: Scalars['String']['output'];
    muted: Scalars['Boolean']['output'];
    observedCount: Scalars['Int32']['output'];
    query: Scalars['String']['output'];
    raisedAt: Scalars['Int64']['output'];
    resolved: Scalars['Boolean']['output'];
    status: AlertStatus;
    tenantId: Scalars['String']['output'];
    triggeredNodeId: Scalars['String']['output'];
};

export type AlertDefinition = {
    alertThreshold: Scalars['Int32']['output'];
    enabled: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    jobPayload?: Maybe<Scalars['String']['output']>;
    jobType: JobType;
    message: Scalars['String']['output'];
    name: Scalars['String']['output'];
    query: Scalars['String']['output'];
    tenantId: Scalars['String']['output'];
    warningThreshold: Scalars['Int32']['output'];
};

export enum AlertDestination {
    AlertDestinationEmail = 'ALERT_DESTINATION_EMAIL',
    AlertDestinationKafka = 'ALERT_DESTINATION_KAFKA',
    AlertDestinationNotification = 'ALERT_DESTINATION_NOTIFICATION',
    AlertDestinationPagerDuty = 'ALERT_DESTINATION_PAGER_DUTY',
    AlertDestinationSlack = 'ALERT_DESTINATION_SLACK',
    AlertDestinationSplunk = 'ALERT_DESTINATION_SPLUNK',
    AlertDestinationWebhook = 'ALERT_DESTINATION_WEBHOOK',
    AlertDestinationZoomchat = 'ALERT_DESTINATION_ZOOMCHAT',
}

export type AlertInput = {
    definitionId: Scalars['String']['input'];
    id: Scalars['String']['input'];
    muted: Scalars['Boolean']['input'];
    status: AlertStatus;
    tenantId: Scalars['String']['input'];
};

/** Alert type */
export enum AlertStatus {
    AlertAlert = 'ALERT_ALERT',
    AlertNoData = 'ALERT_NO_DATA',
    AlertUnknown = 'ALERT_UNKNOWN',
    AlertWarning = 'ALERT_WARNING',
}

export type AlertWithAdditionalInfo = {
    alertDefinition?: Maybe<AlertDefinition>;
    alertDefinitionName: Scalars['String']['output'];
    definitionId: Scalars['String']['output'];
    eventDate: Scalars['Int64']['output'];
    id: Scalars['String']['output'];
    message: Scalars['String']['output'];
    muted: Scalars['Boolean']['output'];
    observedCount: Scalars['Int32']['output'];
    query: Scalars['String']['output'];
    raisedAt: Scalars['Int64']['output'];
    resolved: Scalars['Boolean']['output'];
    status: AlertStatus;
    tenantId: Scalars['String']['output'];
    triggeredNodeDetails?: Maybe<TriggeredNodeDetails>;
    triggeredNodeId: Scalars['String']['output'];
};

export type Application = {
    /** returns the actors for a given target in the specified timeframe of the query */
    actors: Array<Actor>;
    createdAt: Scalars['Int64']['output'];
    displayName: Scalars['String']['output'];
    id: Scalars['String']['output'];
    svid: Scalars['String']['output'];
    tags: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
};

export type ApplicationQueryInput = {
    applicationDisplayName?: InputMaybe<StringQueryInput>;
    applicationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    applicationProviderIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    applicationType?: InputMaybe<ApplicationType>;
    applicationUserAgent?: InputMaybe<StringQueryInput>;
    applicationVersion?: InputMaybe<VersionQueryInput>;
    tags?: InputMaybe<TagQueryInput>;
};

/** Application type */
export enum ApplicationType {
    ApplicationTypeDesktop = 'APPLICATION_TYPE_DESKTOP',
    ApplicationTypeMobile = 'APPLICATION_TYPE_MOBILE',
    ApplicationTypeService = 'APPLICATION_TYPE_SERVICE',
    ApplicationTypeUnknown = 'APPLICATION_TYPE_UNKNOWN',
    ApplicationTypeWeb = 'APPLICATION_TYPE_WEB',
}

export type BlobInput = {
    accessKey: Scalars['String']['input'];
    bucket: Scalars['String']['input'];
    disableSSL?: InputMaybe<Scalars['Boolean']['input']>;
    endpoint: Scalars['String']['input'];
    forcePathStyle?: InputMaybe<Scalars['Boolean']['input']>;
    insecureSkipVerify?: InputMaybe<Scalars['Boolean']['input']>;
    prefix?: InputMaybe<Scalars['String']['input']>;
    region?: InputMaybe<Scalars['String']['input']>;
    secretKey: Scalars['String']['input'];
    storageType?: InputMaybe<Scalars['String']['input']>;
    type: Scalars['String']['input'];
};

export enum ComparisonType {
    ComparisonTypeBetween = 'COMPARISON_TYPE_BETWEEN',
    ComparisonTypeContains = 'COMPARISON_TYPE_CONTAINS',
    ComparisonTypeEqual = 'COMPARISON_TYPE_EQUAL',
    ComparisonTypeGreaterThan = 'COMPARISON_TYPE_GREATER_THAN',
    ComparisonTypeLessThan = 'COMPARISON_TYPE_LESS_THAN',
}

export type DeleteAlertDefinitionInput = {
    id: Scalars['String']['input'];
};

export type DeleteAlertDefinitionOutput = {
    success: Scalars['Boolean']['output'];
};

export type Device = {
    createdAt: Scalars['Int64']['output'];
    deviceType: DeviceType;
    id: Scalars['String']['output'];
    operatingSystem: DeviceOperatingSystem;
    svid: Scalars['String']['output'];
    tags: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
    userAgent: Scalars['String']['output'];
};

/** Device operating system */
export enum DeviceOperatingSystem {
    OsTypeAndroid = 'OS_TYPE_ANDROID',
    OsTypeChromeos = 'OS_TYPE_CHROMEOS',
    OsTypeDebian = 'OS_TYPE_DEBIAN',
    OsTypeFreebsd = 'OS_TYPE_FREEBSD',
    OsTypeGentoo = 'OS_TYPE_GENTOO',
    OsTypeIos = 'OS_TYPE_IOS',
    OsTypeLinux = 'OS_TYPE_LINUX',
    OsTypeMacos = 'OS_TYPE_MACOS',
    OsTypeRedhat = 'OS_TYPE_REDHAT',
    OsTypeSolaris = 'OS_TYPE_SOLARIS',
    OsTypeTvos = 'OS_TYPE_TVOS',
    OsTypeUbuntu = 'OS_TYPE_UBUNTU',
    OsTypeUnknown = 'OS_TYPE_UNKNOWN',
    OsTypeWatchos = 'OS_TYPE_WATCHOS',
    OsTypeWindows = 'OS_TYPE_WINDOWS',
    OsTypeWindowsPhone = 'OS_TYPE_WINDOWS_PHONE',
}

export type DeviceQueryInput = {
    deviceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    deviceOperatingSystem?: InputMaybe<DeviceOperatingSystem>;
    deviceType?: InputMaybe<DeviceType>;
    deviceUserAgent?: InputMaybe<StringQueryInput>;
    externalIdentifiers?: InputMaybe<Array<InputMaybe<ExternalIdentifierInput>>>;
    tags?: InputMaybe<TagQueryInput>;
};

/** Device type */
export enum DeviceType {
    DeviceTypeComputer = 'DEVICE_TYPE_COMPUTER',
    DeviceTypeMobile = 'DEVICE_TYPE_MOBILE',
    DeviceTypeServer = 'DEVICE_TYPE_SERVER',
    DeviceTypeTablet = 'DEVICE_TYPE_TABLET',
    DeviceTypeUnknown = 'DEVICE_TYPE_UNKNOWN',
}

export type Edge = {
    edgeId: Scalars['String']['output'];
    inV: Scalars['String']['output'];
    label: Scalars['String']['output'];
    outV: Scalars['String']['output'];
    props?: Maybe<Scalars['MapString']['output']>;
    tags: Array<Maybe<Scalars['String']['output']>>;
    tenantId: Scalars['String']['output'];
};

export type Element = {
    element: Scalars['ProtoAny']['output'];
    elementId: Scalars['String']['output'];
    elementStatus: ElementStatus;
    elementType: ElementType;
    label: Scalars['String']['output'];
};

export enum ElementStatus {
    ElementStatusDeleted = 'ELEMENT_STATUS_DELETED',
    ElementStatusModified = 'ELEMENT_STATUS_MODIFIED',
    ElementStatusNew = 'ELEMENT_STATUS_NEW',
    ElementStatusUnknown = 'ELEMENT_STATUS_UNKNOWN',
    ElementStatusUnmodified = 'ELEMENT_STATUS_UNMODIFIED',
}

export enum ElementType {
    ElementTypeEdge = 'ELEMENT_TYPE_EDGE',
    ElementTypeNode = 'ELEMENT_TYPE_NODE',
    ElementTypeUnknown = 'ELEMENT_TYPE_UNKNOWN',
}

export type Entity = {
    data?: Maybe<Scalars['ProtoAny']['output']>;
    entityId?: Maybe<Scalars['String']['output']>;
    entityType?: Maybe<StatsEntityType>;
    tenantId?: Maybe<Scalars['String']['output']>;
};

export type EntityNameType = {
    plural: Scalars['String']['output'];
    singular: Scalars['String']['output'];
};

export type EntitySpiderDimensions = {
    activities: Scalars['Int']['output'];
    actor: Scalars['Int']['output'];
    devices: Scalars['Int']['output'];
    policies: Scalars['Int']['output'];
    processes: Scalars['Int']['output'];
    sessions: Scalars['Int']['output'];
    targets: Scalars['Int']['output'];
};

export type ErrorLocation = {
    col?: Maybe<Scalars['Int']['output']>;
    file?: Maybe<Scalars['String']['output']>;
    offset?: Maybe<Scalars['Int']['output']>;
    row?: Maybe<Scalars['Int']['output']>;
    text?: Maybe<Scalars['String']['output']>;
};

export type EventData = {
    data: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type EventQueryInput = {
    eventIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    location?: InputMaybe<LocationQueryInput>;
    tags?: InputMaybe<TagQueryInput>;
};

export type ExternalIdentifierInput = {
    source: Scalars['String']['input'];
    type: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export enum FactorType {
    FactorTypeApp = 'FACTOR_TYPE_APP',
    FactorTypeEmail = 'FACTOR_TYPE_EMAIL',
    FactorTypeFirstFactorClaim = 'FACTOR_TYPE_FIRST_FACTOR_CLAIM',
    FactorTypeMfaClaim = 'FACTOR_TYPE_MFA_CLAIM',
    FactorTypePassword = 'FACTOR_TYPE_PASSWORD',
    FactorTypePasswordless = 'FACTOR_TYPE_PASSWORDLESS',
    FactorTypePhone = 'FACTOR_TYPE_PHONE',
    FactorTypePreviouslySatisfied = 'FACTOR_TYPE_PREVIOUSLY_SATISFIED',
    FactorTypeSms = 'FACTOR_TYPE_SMS',
    FactorTypeTotp = 'FACTOR_TYPE_TOTP',
    FactorTypeUnknown = 'FACTOR_TYPE_UNKNOWN',
    FactorTypeWindowsBroker = 'FACTOR_TYPE_WINDOWS_BROKER',
}

export type Field = {
    DisplayName: Scalars['String']['output'];
    Enum?: Maybe<Array<KeyValueTuple>>;
    Generated: Scalars['Boolean']['output'];
    HelpURI?: Maybe<Scalars['String']['output']>;
    Hook: Scalars['Boolean']['output'];
    InputName: Scalars['String']['output'];
    Mandatory: Scalars['Boolean']['output'];
    Name: Scalars['String']['output'];
    Output: Scalars['Boolean']['output'];
    RegexValidation?: Maybe<Scalars['String']['output']>;
    Value: Scalars['String']['output'];
};

export type Filter = {
    groups?: InputMaybe<Array<InputMaybe<FilterGroup>>>;
    tenantId: Scalars['ID']['input'];
};

export type FilterElement = {
    actorQuery?: InputMaybe<ActorQueryInput>;
    applicationQuery?: InputMaybe<ApplicationQueryInput>;
    deviceQuery?: InputMaybe<DeviceQueryInput>;
    eventQuery?: InputMaybe<EventQueryInput>;
    identityQuery?: InputMaybe<IdentityQueryInput>;
    reasonQuery?: InputMaybe<ReasonQueryInput>;
    targetQuery?: InputMaybe<TargetQueryInput>;
};

export type FilterField = {
    comparison?: InputMaybe<ComparisonType>;
    field_name?: InputMaybe<Scalars['String']['input']>;
    numeric_end_value?: InputMaybe<Scalars['Int64']['input']>;
    numeric_start_value?: InputMaybe<Scalars['Int64']['input']>;
    numeric_value?: InputMaybe<Scalars['Int64']['input']>;
    string_value?: InputMaybe<Scalars['String']['input']>;
};

export type FilterGroup = {
    elements?: InputMaybe<Array<InputMaybe<FilterElement>>>;
    operator?: InputMaybe<FilterOperator>;
};

export enum FilterOperator {
    FilterOperatorAnd = 'FILTER_OPERATOR_AND',
    FilterOperatorOr = 'FILTER_OPERATOR_OR',
    FilterOperatorUnknown = 'FILTER_OPERATOR_UNKNOWN',
}

export enum FilterType {
    FilterTypeActor = 'FILTER_TYPE_ACTOR',
    FilterTypeApplication = 'FILTER_TYPE_APPLICATION',
    FilterTypeDevice = 'FILTER_TYPE_DEVICE',
    FilterTypeGroup = 'FILTER_TYPE_GROUP',
    FilterTypePolicy = 'FILTER_TYPE_POLICY',
    FilterTypeTarget = 'FILTER_TYPE_TARGET',
    FilterTypeUnknown = 'FILTER_TYPE_UNKNOWN',
}

export type GeographicalContext = {
    city: Scalars['String']['output'];
    geolocation?: Maybe<Geolocation>;
    postalCode: Scalars['String']['output'];
    state: Scalars['String']['output'];
};

export type Geolocation = {
    latitude: Scalars['Float']['output'];
    longitude: Scalars['Float']['output'];
};

export type GetActorOutput = {
    actors: Array<Actor>;
    pageInfo: PageInfo;
};

export type GetAlertCountInput = {
    endTime: Scalars['Int64']['input'];
    startTime: Scalars['Int64']['input'];
};

export type GetAlertCountOutput = {
    count: Scalars['Int32']['output'];
};

export type GetApplicationOutput = {
    applications: Array<Application>;
    pageInfo: PageInfo;
};

export type GetAttributesSearchField = {
    fieldName: Scalars['String']['output'];
    fieldType: Scalars['String']['output'];
};

export type GetAttributesSearchFieldsOutput = {
    fields?: Maybe<Array<Maybe<GetAttributesSearchField>>>;
};

export type GetEntitiesByTypeOutput = {
    cursor?: Maybe<Scalars['String']['output']>;
    nodes?: Maybe<Array<Node>>;
};

export type GetEntityAccessorsEntityItem = {
    count: Scalars['Int32']['output'];
    id: Scalars['String']['output'];
    managed: Scalars['Boolean']['output'];
    risk: Scalars['Boolean']['output'];
};

export type GetEntityAccessorsOutput = {
    actors?: Maybe<Array<Maybe<GetEntityAccessorsEntityItem>>>;
    actorsCount: Scalars['Int32']['output'];
    applications?: Maybe<Array<Maybe<GetEntityAccessorsEntityItem>>>;
    applicationsCount: Scalars['Int32']['output'];
    devices?: Maybe<Array<Maybe<GetEntityAccessorsEntityItem>>>;
    devicesCount: Scalars['Int32']['output'];
    highRiskActorsCount: Scalars['Int32']['output'];
    highRiskApplicationsCount: Scalars['Int32']['output'];
    highRiskDevicesCount: Scalars['Int32']['output'];
    highRiskIdentitiesCount: Scalars['Int32']['output'];
    highRiskTargetsCount: Scalars['Int32']['output'];
    identities?: Maybe<Array<Maybe<GetEntityAccessorsEntityItem>>>;
    identitiesCount: Scalars['Int32']['output'];
    targets?: Maybe<Array<Maybe<GetEntityAccessorsEntityItem>>>;
    targetsCount: Scalars['Int32']['output'];
};

export type GetEntityByNeighborCountItem = {
    id: Scalars['String']['output'];
    neighborCount: Scalars['Int32']['output'];
};

export type GetEntityByNeighborCountOutput = {
    items?: Maybe<Array<Maybe<GetEntityByNeighborCountItem>>>;
};

export type GetEntityByStatusItem = {
    count: Scalars['Int32']['output'];
    id: Scalars['String']['output'];
    rate: Scalars['Int32']['output'];
    totalCount: Scalars['Int32']['output'];
};

export type GetEntityByStatusOutput = {
    items?: Maybe<Array<Maybe<GetEntityByStatusItem>>>;
};

export type GetEntityByTrustItem = {
    accessCount: Scalars['Int64']['output'];
    entityId: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    trustScore: Scalars['Float']['output'];
};

export type GetEntityByTrustOutput = {
    items: Array<GetEntityByTrustItem>;
};

export type GetEntityCountHistoryItem = {
    date: Scalars['Int64']['output'];
    tags: Array<Maybe<GetEntityCountHistoryItemTag>>;
};

export type GetEntityCountHistoryItemTag = {
    count: Scalars['Int32']['output'];
    list: Array<Maybe<Scalars['String']['output']>>;
    tag: IdentityTags;
};

export type GetEntityCountHistoryOutput = {
    items?: Maybe<Array<Maybe<GetEntityCountHistoryItem>>>;
};

export type GetEntityDormantItem = {
    dormantTime: Scalars['Int64']['output'];
    id: Scalars['String']['output'];
    lastAccessDate: Scalars['Int64']['output'];
};

export type GetEntityDormantOutput = {
    items?: Maybe<Array<Maybe<GetEntityDormantItem>>>;
};

export type GetEntityEventsItem = {
    accesses: Scalars['Int32']['output'];
    actorId: Scalars['String']['output'];
    actorPreviousAccess: Scalars['Int64']['output'];
    actorType: Scalars['Int64']['output'];
    agentPreviousAccess: Scalars['Int64']['output'];
    agentid: Scalars['String']['output'];
    applicationId: Scalars['String']['output'];
    applicationType: Scalars['Int64']['output'];
    date: Scalars['Int64']['output'];
    deviceId: Scalars['String']['output'];
    devicePreviousAccess: Scalars['Int64']['output'];
    deviceType: Scalars['Int64']['output'];
    groupingLevel1: Scalars['String']['output'];
    groupingLevel2: Scalars['String']['output'];
    groupingLevel3: Scalars['String']['output'];
    groupingLevel4: Scalars['String']['output'];
    groupingLevel5: Scalars['String']['output'];
    groupingLevel6: Scalars['String']['output'];
    identityId: Scalars['String']['output'];
    identityPreviousAccess: Scalars['Int64']['output'];
    location?: Maybe<GeographicalContext>;
    mfa: Scalars['ProtoBool']['output'];
    provider: Scalars['String']['output'];
    risk: Scalars['String']['output'];
    status: AccessOutcomeType;
    tags?: Maybe<Array<Maybe<IdentityTags>>>;
    targetId: Scalars['String']['output'];
    targetPreviousAccess: Scalars['Int64']['output'];
    targetType: Scalars['Int64']['output'];
};

export type GetEntityEventsOutput = {
    items?: Maybe<Array<GetEntityEventsItem>>;
};

export type GetEntityEventsSummaryCodesItem = {
    codeId: Scalars['String']['output'];
    count: Scalars['Int32']['output'];
    reason: Scalars['String']['output'];
    risk: Scalars['String']['output'];
    summaryId: Scalars['String']['output'];
};

export type GetEntityEventsSummaryCodesOutput = {
    items?: Maybe<Array<Maybe<GetEntityEventsSummaryCodesItem>>>;
};

export type GetEntityEventsSummaryItem = {
    count: Scalars['Int32']['output'];
    entityTargetId: Scalars['String']['output'];
    risk: Scalars['String']['output'];
    status: AccessOutcomeType;
    summaryId: Scalars['String']['output'];
};

export type GetEntityEventsSummaryOutput = {
    items?: Maybe<Array<Maybe<GetEntityEventsSummaryItem>>>;
};

export type GetEntityHistoryItem = {
    accessCount: Scalars['Int32']['output'];
    allowCount: Scalars['Int32']['output'];
    challengeCount: Scalars['Int32']['output'];
    date: Scalars['Int64']['output'];
    denyCount: Scalars['Int32']['output'];
    entityCount: Scalars['Int32']['output'];
    failureCount: Scalars['Int32']['output'];
    mfaCount: Scalars['Int32']['output'];
    otherCount: Scalars['Int32']['output'];
    riskCount: Scalars['Int32']['output'];
    skippedCount: Scalars['Int32']['output'];
    successCount: Scalars['Int32']['output'];
    trustScore: Scalars['Float']['output'];
    unknownCount: Scalars['Int32']['output'];
};

export type GetEntityHistoryOutput = {
    items?: Maybe<Array<Maybe<GetEntityHistoryItem>>>;
};

export type GetEntityLastCreatedItem = {
    creationDate: Scalars['Int64']['output'];
    id: Scalars['String']['output'];
};

export type GetEntityLastCreatedOutput = {
    items?: Maybe<Array<Maybe<GetEntityLastCreatedItem>>>;
};

export type GetEntityListItem = {
    accessCount: Scalars['Int32']['output'];
    id: Scalars['String']['output'];
};

export type GetEntityListOutput = {
    items?: Maybe<Array<Maybe<GetEntityListItem>>>;
};

export type GetEntityLocationsItem = {
    date: Scalars['Int64']['output'];
    id: Scalars['String']['output'];
    locations: Array<Maybe<GeographicalContext>>;
};

export type GetEntityLocationsOutput = {
    items?: Maybe<Array<Maybe<GetEntityLocationsItem>>>;
};

export type GetEntityPermissionStatsOutput = {
    groupCount: Scalars['Int32']['output'];
    policyCount: Scalars['Int32']['output'];
    targetCount: Scalars['Int32']['output'];
};

export type GetEntityProfileOutput = {
    firstAccessed: Scalars['Int64']['output'];
    lastAccessed: Scalars['Int64']['output'];
    mfaMethods: Array<Maybe<FactorType>>;
    providers: Array<Maybe<Scalars['String']['output']>>;
    tags: Array<Scalars['String']['output']>;
};

export type GetEntityReturnToLifeItem = {
    dormantTime: Scalars['Int64']['output'];
    id: Scalars['String']['output'];
    lastAccessDate: Scalars['Int64']['output'];
    returnedToLife: Scalars['Int64']['output'];
};

export type GetEntityReturnToLifeOutput = {
    items?: Maybe<Array<Maybe<GetEntityReturnToLifeItem>>>;
};

export type GetEntityRiskyItem = {
    id: Scalars['String']['output'];
    riskyCount: Scalars['Int32']['output'];
};

export type GetEntityRiskyOutput = {
    items?: Maybe<Array<Maybe<GetEntityRiskyItem>>>;
};

export type GetEntitySpiderOutput = {
    dimensions: EntitySpiderDimensions;
};

export type GetEntityTrustProfileItem = {
    description: Scalars['String']['output'];
    displayName: Scalars['String']['output'];
    policies: Array<Maybe<GetEntityTrustProfilePolicy>>;
    profileId: Scalars['String']['output'];
    tenantId: Scalars['String']['output'];
};

export type GetEntityTrustProfileOutput = {
    profiles: Array<GetEntityTrustProfileItem>;
};

export type GetEntityTrustProfilePolicy = {
    author: Scalars['String']['output'];
    category: Scalars['String']['output'];
    description: Scalars['String']['output'];
    displayName: Scalars['String']['output'];
    icon: Scalars['String']['output'];
    isCritical: Scalars['Boolean']['output'];
    policyContext: Scalars['MapString']['output'];
    policyDocumentId: Scalars['String']['output'];
    policyOutcome: Scalars['String']['output'];
    tenantId: Scalars['String']['output'];
};

export type GetGlobalTrustStatisticsOutput = {
    buckets: Array<TrustStatisticBucket>;
    globalTrustScore: Scalars['Float']['output'];
    tenantId: Scalars['String']['output'];
};

export type GetInventoryStatisticsOutput = {
    items?: Maybe<Array<Maybe<InventoryStatisticsItem>>>;
    tenantId?: Maybe<Scalars['String']['output']>;
};

export type GetLocationHistoryItem = {
    challengeCount: Scalars['Int32']['output'];
    count: Scalars['Int32']['output'];
    date: Scalars['Int64']['output'];
    denyCount: Scalars['Int32']['output'];
    list: Array<Maybe<Scalars['String']['output']>>;
    locations: Array<Maybe<Scalars['String']['output']>>;
    otherCount: Scalars['Int32']['output'];
    riskCount: Scalars['Int32']['output'];
    successCount: Scalars['Int32']['output'];
};

export type GetLocationHistoryOutput = {
    items?: Maybe<Array<Maybe<GetLocationHistoryItem>>>;
};

export type GetStatusReasonsItem = {
    count: Scalars['Int32']['output'];
    reason: Scalars['String']['output'];
};

export type GetStatusReasonsOutput = {
    items?: Maybe<Array<Maybe<GetStatusReasonsItem>>>;
};

export type GetTargetOutput = {
    pageInfo: PageInfo;
    targets: Array<Target>;
};

export type GetTotalAlertCountOutput = {
    count: Scalars['Int32']['output'];
};

export type GetTrustProfileHistoryItem = {
    date: Scalars['Int64']['output'];
    trustScore: Scalars['Map']['output'];
};

export type GetTrustProfileHistoryOutput = {
    items?: Maybe<Array<Maybe<GetTrustProfileHistoryItem>>>;
};

export type GridCol = {
    Cols?: Maybe<Array<Maybe<Scalars['Int64']['output']>>>;
};

export type Header = {
    key: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type HeaderInput = {
    key: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type HelpscoutToken = {
    token?: Maybe<Scalars['String']['output']>;
};

export enum HttpMethod {
    Delete = 'DELETE',
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Unknown = 'UNKNOWN',
}

export type IdentityMap = {
    edges: Array<Maybe<Edge>>;
    endTime: Scalars['Int']['output'];
    isLoading: Scalars['Boolean']['output'];
    nodes: Array<Maybe<Node>>;
    queryId?: Maybe<Scalars['String']['output']>;
    startTime: Scalars['Int']['output'];
    tenantId: Scalars['String']['output'];
};

export enum IdentityMapFilterTags {
    TagSourceIdp = 'TAG_SOURCE_IDP',
}

export type IdentityPayload = {
    edges: Array<Maybe<Element>>;
    eventFlagged: Scalars['Boolean']['output'];
    eventTime: Scalars['Int64']['output'];
    nodes: Array<Element>;
    tenantId: Scalars['String']['output'];
    triggerEventId: Scalars['String']['output'];
    triggerProcessorType: Scalars['String']['output'];
    triggerProviderId: Scalars['String']['output'];
};

export type IdentityQueryInput = {
    identityIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    tags?: InputMaybe<TagQueryInput>;
};

export enum IdentityTags {
    TagActor = 'TAG_ACTOR',
    TagActorAdministrator = 'TAG_ACTOR_ADMINISTRATOR',
    TagActorAtHome = 'TAG_ACTOR_AT_HOME',
    TagActorAtWork = 'TAG_ACTOR_AT_WORK',
    TagActorCloudOperator = 'TAG_ACTOR_CLOUD_OPERATOR',
    TagActorExternal = 'TAG_ACTOR_EXTERNAL',
    TagActorInternal = 'TAG_ACTOR_INTERNAL',
    TagActorMachine = 'TAG_ACTOR_MACHINE',
    TagActorOffboarding = 'TAG_ACTOR_OFFBOARDING',
    TagActorTravelling = 'TAG_ACTOR_TRAVELLING',
    TagApplicationDesktop = 'TAG_APPLICATION_DESKTOP',
    TagApplicationMobile = 'TAG_APPLICATION_MOBILE',
    TagApplicationService = 'TAG_APPLICATION_SERVICE',
    TagApplicationUnknown = 'TAG_APPLICATION_UNKNOWN',
    TagApplicationWeb = 'TAG_APPLICATION_WEB',
    TagDevice = 'TAG_DEVICE',
    TagDeviceHasAgent = 'TAG_DEVICE_HAS_AGENT',
    TagDeviceHasTpm = 'TAG_DEVICE_HAS_TPM',
    TagDeviceIsMdmManaged = 'TAG_DEVICE_IS_MDM_MANAGED',
    TagDeviceIsVm = 'TAG_DEVICE_IS_VM',
    TagFactorFailed = 'TAG_FACTOR_FAILED',
    TagFactorFirstFactorClaim = 'TAG_FACTOR_FIRST_FACTOR_CLAIM',
    TagFactorMfaCall = 'TAG_FACTOR_MFA_CALL',
    TagFactorMfaClaimExternal = 'TAG_FACTOR_MFA_CLAIM_EXTERNAL',
    TagFactorMfaClaimToken = 'TAG_FACTOR_MFA_CLAIM_TOKEN',
    TagFactorMfaEmail = 'TAG_FACTOR_MFA_EMAIL',
    TagFactorMfaPush = 'TAG_FACTOR_MFA_PUSH',
    TagFactorMfaSms = 'TAG_FACTOR_MFA_SMS',
    TagFactorMfaTotp = 'TAG_FACTOR_MFA_TOTP',
    TagFactorOk = 'TAG_FACTOR_OK',
    TagFactorPassword = 'TAG_FACTOR_PASSWORD',
    TagFactorRejected = 'TAG_FACTOR_REJECTED',
    TagFactorRequireMfa = 'TAG_FACTOR_REQUIRE_MFA',
    TagIdentityAws = 'TAG_IDENTITY_AWS',
    TagIdentityAzure = 'TAG_IDENTITY_AZURE',
    TagIdentityAzuread = 'TAG_IDENTITY_AZUREAD',
    TagIdentityBox = 'TAG_IDENTITY_BOX',
    TagIdentityDropbox = 'TAG_IDENTITY_DROPBOX',
    TagIdentityDummy = 'TAG_IDENTITY_DUMMY',
    TagIdentityGithub = 'TAG_IDENTITY_GITHUB',
    TagIdentityJamf = 'TAG_IDENTITY_JAMF',
    TagIdentityOffice365 = 'TAG_IDENTITY_OFFICE365',
    TagIdentityOkta = 'TAG_IDENTITY_OKTA',
    TagIdentityOnelogin = 'TAG_IDENTITY_ONELOGIN',
    TagRisk = 'TAG_RISK',
    TagRiskAdminConfirmedSigninCompromised = 'TAG_RISK_ADMIN_CONFIRMED_SIGNIN_COMPROMISED',
    TagRiskAdminConfirmedSigninSafe = 'TAG_RISK_ADMIN_CONFIRMED_SIGNIN_SAFE',
    TagRiskAdminDismissedAllRiskForUser = 'TAG_RISK_ADMIN_DISMISSED_ALL_RISK_FOR_USER',
    TagRiskAdminGeneratedTemporaryPassword = 'TAG_RISK_ADMIN_GENERATED_TEMPORARY_PASSWORD',
    TagRiskAiConfirmedSigninSafe = 'TAG_RISK_AI_CONFIRMED_SIGNIN_SAFE',
    TagRiskAnonymizedIpAddress = 'TAG_RISK_ANONYMIZED_IP_ADDRESS',
    TagRiskGeneric = 'TAG_RISK_GENERIC',
    TagRiskHigh = 'TAG_RISK_HIGH',
    TagRiskInvestigationsThreatIntelligence = 'TAG_RISK_INVESTIGATIONS_THREAT_INTELLIGENCE',
    TagRiskLeakedCredentials = 'TAG_RISK_LEAKED_CREDENTIALS',
    TagRiskLow = 'TAG_RISK_LOW',
    TagRiskMaliciousIpAddress = 'TAG_RISK_MALICIOUS_IP_ADDRESS',
    TagRiskMalwareInfectedIpAddress = 'TAG_RISK_MALWARE_INFECTED_IP_ADDRESS',
    TagRiskMedium = 'TAG_RISK_MEDIUM',
    TagRiskSuspiciousIpAddress = 'TAG_RISK_SUSPICIOUS_IP_ADDRESS',
    TagRiskUnfamiliarFeatures = 'TAG_RISK_UNFAMILIAR_FEATURES',
    TagRiskUnlikelyTravel = 'TAG_RISK_UNLIKELY_TRAVEL',
    TagRiskUserPassedMfaDrivenByRiskBasedPolicy = 'TAG_RISK_USER_PASSED_MFA_DRIVEN_BY_RISK_BASED_POLICY',
    TagRiskUserPerformedSecuredPasswordChange = 'TAG_RISK_USER_PERFORMED_SECURED_PASSWORD_CHANGE',
    TagRiskUserPerformedSecuredPasswordReset = 'TAG_RISK_USER_PERFORMED_SECURED_PASSWORD_RESET',
    TagSessionCellular = 'TAG_SESSION_CELLULAR',
    TagSessionCloudEndpoint = 'TAG_SESSION_CLOUD_ENDPOINT',
    TagSessionFlaggedByAzure = 'TAG_SESSION_FLAGGED_BY_AZURE',
    TagSessionFlaggedByOkta = 'TAG_SESSION_FLAGGED_BY_OKTA',
    TagSessionIs_2FaAuthenticated = 'TAG_SESSION_IS_2FA_AUTHENTICATED',
    TagSessionMobileNetwork = 'TAG_SESSION_MOBILE_NETWORK',
    TagSessionTorExit = 'TAG_SESSION_TOR_EXIT',
    TagSessionViaProxy = 'TAG_SESSION_VIA_PROXY',
    TagSessionViaVpn = 'TAG_SESSION_VIA_VPN',
    TagSourceIdp = 'TAG_SOURCE_IDP',
    TagTargetExternal = 'TAG_TARGET_EXTERNAL',
    TagUnknown = 'TAG_UNKNOWN',
}

export type InventoryStatisticsItem = {
    count?: Maybe<Scalars['Int64']['output']>;
    entityType?: Maybe<StatsEntityType>;
    subType?: Maybe<InventorySubtype>;
};

export enum InventorySubtype {
    InventorySubtypeDiscovered = 'INVENTORY_SUBTYPE_DISCOVERED',
    InventorySubtypeExternal = 'INVENTORY_SUBTYPE_EXTERNAL',
    InventorySubtypeInternal = 'INVENTORY_SUBTYPE_INTERNAL',
    InventorySubtypeMachine = 'INVENTORY_SUBTYPE_MACHINE',
    InventorySubtypeManaged = 'INVENTORY_SUBTYPE_MANAGED',
    InventorySubtypeTotal = 'INVENTORY_SUBTYPE_TOTAL',
    InventorySubtypeUnknown = 'INVENTORY_SUBTYPE_UNKNOWN',
}

/** Job type */
export enum JobType {
    FailedAccessFromActors = 'FAILED_ACCESS_FROM_ACTORS',
    FailedAccessToTargets = 'FAILED_ACCESS_TO_TARGETS',
    SuspiciousLocations = 'SUSPICIOUS_LOCATIONS',
    Unknown = 'UNKNOWN',
}

export type KeyValueTuple = {
    key: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type ListAlertDefinitionsOutput = {
    alertDefinitions?: Maybe<Array<AlertDefinition>>;
};

export type ListAlertInput = {
    filter?: InputMaybe<ListAlertsFilter>;
    includeResolvedAlerts: Scalars['Boolean']['input'];
    pageIndex?: InputMaybe<Scalars['Int64']['input']>;
    pageSize?: InputMaybe<Scalars['Int64']['input']>;
    sort?: InputMaybe<ListAlertsSort>;
};

export type ListAlertOutput = {
    alerts?: Maybe<Array<AlertWithAdditionalInfo>>;
    pageInfo?: Maybe<ListAlertPageInfo>;
};

export type ListAlertPageInfo = {
    currentPage?: Maybe<Scalars['Int64']['output']>;
    pageCount?: Maybe<Scalars['Int64']['output']>;
};

export type ListAlertsFilter = {
    fields?: InputMaybe<Array<InputMaybe<FilterField>>>;
};

export type ListAlertsSort = {
    field?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<SortOrder>;
};

export type LocationQueryInput = {
    latitude?: InputMaybe<Scalars['Float']['input']>;
    longitude?: InputMaybe<Scalars['Float']['input']>;
    radius?: InputMaybe<Scalars['Float']['input']>;
};

export type Mutation = {
    addActorsToPolicyProfile: PolicyProfile;
    addAlert: AddAlertOutput;
    addAlertDefinition: AddAlertDefinitionOutput;
    addPolicyProfilePolicy: PolicyProfile;
    addProviderEnrichmentModule: Provider;
    addTargetsToPolicyProfile: PolicyProfile;
    agentCheckin: Agent;
    agentPublish?: Maybe<Scalars['ID']['output']>;
    createAgent: Agent;
    createApiKey: ApiKey;
    createPolicyProfile: PolicyProfile;
    createProvider: ProviderAuthOutput;
    createTenant: Tenant;
    createUser: User;
    createWebhook: Webhook;
    deleteAgent: Scalars['Boolean']['output'];
    deleteAlertDefinition: DeleteAlertDefinitionOutput;
    deleteApiKey: Scalars['Boolean']['output'];
    deletePolicyDocument: Scalars['Boolean']['output'];
    deletePolicyProfile: Scalars['Boolean']['output'];
    deleteProvider: Scalars['Boolean']['output'];
    deleteStorage: Scalars['Boolean']['output'];
    deleteTenant: Scalars['Boolean']['output'];
    deleteUser: Scalars['Boolean']['output'];
    deleteWebhook: Scalars['Boolean']['output'];
    deployInfra: Scalars['Boolean']['output'];
    deploySchema: Scalars['Boolean']['output'];
    generatePasswordReset: Scalars['Boolean']['output'];
    loadBlobData: Scalars['Boolean']['output'];
    onboardTenant: OnboardOutput;
    preCacheWarmChecker?: Maybe<PreCacheWarmCheckerOutput>;
    publishPolicyDocument: PublishPolicyDocumentResponse;
    reloadAuthzModel: Scalars['Boolean']['output'];
    reloadAuthzPolicies: Scalars['Boolean']['output'];
    removeActorsFromPolicyProfile: PolicyProfile;
    removePolicyProfilePolicy: PolicyProfile;
    removeProviderEnrichmentModule: Scalars['Boolean']['output'];
    removeTargetsFromPolicyProfile: PolicyProfile;
    resolveAlerts: ResolveAlertsOutput;
    setStorage: Storage;
    stagePolicyDocument: StagePolicyDocumentResponse;
    updateAlertDefinition: UpdateAlertDefinitionOutput;
    updatePolicyDocument: PolicyDocument;
    updatePolicyProfile: PolicyProfile;
    updatePolicyProfilePolicy: PolicyProfile;
    updatePolicyProfileTarget: PolicyProfile;
    updateProvider: Provider;
    updateTenant: Scalars['Boolean']['output'];
    updateUser: Scalars['Boolean']['output'];
};

export type MutationAddActorsToPolicyProfileArgs = {
    actorIds: Array<Scalars['ID']['input']>;
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationAddAlertArgs = {
    addAlertInput: AddAlertInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationAddAlertDefinitionArgs = {
    addAlertDefinitionInput: AddAlertDefinitionInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationAddPolicyProfilePolicyArgs = {
    policies: Array<InputMaybe<PolicyProfilePolicyInput>>;
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationAddProviderEnrichmentModuleArgs = {
    enrichmentModule?: InputMaybe<Array<ProviderEnrichmentModuleInput>>;
    providerId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationAddTargetsToPolicyProfileArgs = {
    policyProfileId: Scalars['ID']['input'];
    target: Array<PolicyProfileTargetInput>;
    tenantId: Scalars['ID']['input'];
};

export type MutationAgentCheckinArgs = {
    agentId: Scalars['ID']['input'];
    input?: InputMaybe<AgentInput>;
    tenantId: Scalars['ID']['input'];
};

export type MutationAgentPublishArgs = {
    agentId: Scalars['ID']['input'];
    content?: InputMaybe<Scalars['String']['input']>;
    tenantId: Scalars['ID']['input'];
    type: AgentControlMessageType;
};

export type MutationCreateAgentArgs = {
    input: AgentInput;
};

export type MutationCreateApiKeyArgs = {
    apiKeyName: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationCreatePolicyProfileArgs = {
    policyProfileInput: PolicyProfileInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationCreateProviderArgs = {
    input: ProviderInput;
};

export type MutationCreateTenantArgs = {
    input: TenantInput;
};

export type MutationCreateUserArgs = {
    input: UserInput;
};

export type MutationCreateWebhookArgs = {
    tenantId: Scalars['ID']['input'];
    webhookInput: WebhookInput;
};

export type MutationDeleteAgentArgs = {
    agentId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationDeleteAlertDefinitionArgs = {
    deleteAlertDefinitionInput: DeleteAlertDefinitionInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationDeleteApiKeyArgs = {
    apiKeyId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationDeletePolicyDocumentArgs = {
    policyDocumentId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
    version?: InputMaybe<Scalars['Int64']['input']>;
};

export type MutationDeletePolicyProfileArgs = {
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationDeleteProviderArgs = {
    providerId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationDeleteStorageArgs = {
    key: Scalars['String']['input'];
    shared: Scalars['Boolean']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationDeleteTenantArgs = {
    tenantId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationDeleteWebhookArgs = {
    tenantId: Scalars['ID']['input'];
    webhookId: Scalars['ID']['input'];
};

export type MutationDeployInfraArgs = {
    tenantId: Scalars['ID']['input'];
};

export type MutationDeploySchemaArgs = {
    cassandraSchema: Scalars['Boolean']['input'];
    elasticSchema: Scalars['Boolean']['input'];
    mongoSchema: Scalars['Boolean']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationGeneratePasswordResetArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationLoadBlobDataArgs = {
    blobInput: BlobInput;
    providerId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationOnboardTenantArgs = {
    input: OnboardInput;
};

export type MutationPreCacheWarmCheckerArgs = {
    endDate: Scalars['Int64']['input'];
    force: Scalars['Boolean']['input'];
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationPublishPolicyDocumentArgs = {
    action?: InputMaybe<PolicyAction>;
    policyDocumentId: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
    version?: InputMaybe<Scalars['Int64']['input']>;
};

export type MutationRemoveActorsFromPolicyProfileArgs = {
    actorIds: Array<Scalars['ID']['input']>;
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationRemovePolicyProfilePolicyArgs = {
    policyIds: Array<Scalars['ID']['input']>;
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationRemoveProviderEnrichmentModuleArgs = {
    moduleType: ProviderEnrichmentModuleType;
    providerId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationRemoveTargetsFromPolicyProfileArgs = {
    policyProfileId: Scalars['ID']['input'];
    targetIds: Array<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type MutationResolveAlertsArgs = {
    resolveAlertsInput: ResolveAlertsInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationSetStorageArgs = {
    data: Scalars['String']['input'];
    key: Scalars['String']['input'];
    shared: Scalars['Boolean']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationStagePolicyDocumentArgs = {
    author?: InputMaybe<Scalars['String']['input']>;
    category?: InputMaybe<Scalars['String']['input']>;
    dataSources?: InputMaybe<Array<Scalars['String']['input']>>;
    description?: InputMaybe<Scalars['String']['input']>;
    icon?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    policyDocument: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
    validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateAlertDefinitionArgs = {
    tenantId: Scalars['ID']['input'];
    updateAlertDefinitionInput: UpdateAlertDefinitionInput;
};

export type MutationUpdatePolicyDocumentArgs = {
    policyUpdates: UpdatePolicyDocumentFieldsInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationUpdatePolicyProfileArgs = {
    policyProfileId: Scalars['ID']['input'];
    policyProfileInput: PolicyProfileInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationUpdatePolicyProfilePolicyArgs = {
    policy: PolicyProfilePolicyInput;
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type MutationUpdatePolicyProfileTargetArgs = {
    policyProfileId: Scalars['ID']['input'];
    target: PolicyProfileTargetInput;
    tenantId: Scalars['ID']['input'];
};

export type MutationUpdateProviderArgs = {
    input: ProviderInput;
    providerId: Scalars['ID']['input'];
};

export type MutationUpdateTenantArgs = {
    tenantData?: InputMaybe<TenantUpdateInput>;
    tenantId: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
    tenantId: Scalars['ID']['input'];
    userData?: InputMaybe<UserUpdateInput>;
    userId: Scalars['ID']['input'];
};

export type Node = {
    attributes?: Maybe<Array<Maybe<TimeSeriesAttribute>>>;
    currentLevelTrail?: Maybe<Scalars['String']['output']>;
    groupingData?: Maybe<Scalars['MapString']['output']>;
    hasNextLevel?: Maybe<Scalars['Boolean']['output']>;
    label: Scalars['String']['output'];
    nextLevelBase64?: Maybe<Scalars['String']['output']>;
    nodeId: Scalars['String']['output'];
    nodeType: NodeType;
    props?: Maybe<Scalars['MapString']['output']>;
    tags: Array<Maybe<Scalars['String']['output']>>;
    tenantId: Scalars['String']['output'];
};

export enum NodeType {
    NodeTypeFile = 'NODE_TYPE_FILE',
    NodeTypeGroup = 'NODE_TYPE_GROUP',
    NodeTypeMailbox = 'NODE_TYPE_MAILBOX',
    NodeTypeService = 'NODE_TYPE_SERVICE',
    NodeTypeUnknown = 'NODE_TYPE_UNKNOWN',
    NodeTypeWeb = 'NODE_TYPE_WEB',
}

export type OnboardInput = {
    cloud?: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    name: Scalars['String']['input'];
    region?: InputMaybe<Scalars['String']['input']>;
    slug: Scalars['String']['input'];
    tos: Scalars['Boolean']['input'];
};

export type OnboardOutput = {
    orgId: Scalars['String']['output'];
    result?: Maybe<Scalars['ProtoBool']['output']>;
    tenantId: Scalars['String']['output'];
};

export type PageInfo = {
    currentPage?: Maybe<Scalars['String']['output']>;
    hasNextPage?: Maybe<Scalars['Boolean']['output']>;
    nextPage?: Maybe<Scalars['String']['output']>;
};

export type PaginationInput = {
    currentPage?: InputMaybe<Scalars['String']['input']>;
};

export type PermissionsFilter = {
    filter?: InputMaybe<Scalars['Map']['input']>;
};

export type PermissionsMapOutput = {
    permissions: Array<Maybe<Edge>>;
};

export enum PolicyAction {
    PolicyActionAlert = 'POLICY_ACTION_ALERT',
    PolicyActionDisable = 'POLICY_ACTION_DISABLE',
    PolicyActionEnforce = 'POLICY_ACTION_ENFORCE',
    PolicyActionIscWorkflow = 'POLICY_ACTION_ISC_WORKFLOW',
    PolicyActionMonitor = 'POLICY_ACTION_MONITOR',
    PolicyActionUnknown = 'POLICY_ACTION_UNKNOWN',
}

export type PolicyDocument = {
    author: Scalars['String']['output'];
    category: Scalars['String']['output'];
    createdAt: Scalars['Int64']['output'];
    dataSources: Array<Scalars['String']['output']>;
    deletedAt: Scalars['Int64']['output'];
    description: Scalars['String']['output'];
    displayName: Scalars['String']['output'];
    externalReadOnly: Scalars['Boolean']['output'];
    icon: Scalars['String']['output'];
    originVersion: Scalars['Int64']['output'];
    policyDocument: Scalars['String']['output'];
    policyDocumentId: Scalars['String']['output'];
    requestedState: PolicyAction;
    signature: Scalars['String']['output'];
    status: PolicyStatus;
    tags: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
    version: Scalars['Int64']['output'];
};

export type PolicyDocumentQueryInput = {
    policyDocumentId?: InputMaybe<Scalars['String']['input']>;
    policyName?: InputMaybe<StringQueryInput>;
    tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type PolicyError = {
    code: Scalars['String']['output'];
    location?: Maybe<ErrorLocation>;
    message: Scalars['String']['output'];
};

export enum PolicyOptions {
    PolicyOptionsUnknown = 'POLICY_OPTIONS_UNKNOWN',
}

export type PolicyProfile = {
    actorIds: Array<Scalars['ID']['output']>;
    createdAt: Scalars['Int64']['output'];
    deletedAt: Scalars['Int64']['output'];
    description: Scalars['String']['output'];
    displayName: Scalars['String']['output'];
    externalReadOnly: Scalars['Boolean']['output'];
    originVersion: Scalars['Int64']['output'];
    policies: Array<Maybe<PolicyProfilePolicy>>;
    profileId: Scalars['String']['output'];
    targets: Array<Maybe<PolicyProfileTarget>>;
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
    version: Scalars['Int64']['output'];
};

export type PolicyProfileInput = {
    actorIds: Array<Scalars['ID']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    displayName?: InputMaybe<Scalars['String']['input']>;
    originVersion?: InputMaybe<Scalars['Int64']['input']>;
    policies: Array<PolicyProfilePolicyInput>;
    targets: Array<PolicyProfileTargetInput>;
    tenantId: Scalars['String']['input'];
};

export type PolicyProfilePolicy = {
    policyId: Scalars['String']['output'];
    profileId: Scalars['String']['output'];
    weight: PolicyWeight;
};

export type PolicyProfilePolicyInput = {
    policyId: Scalars['String']['input'];
    weight?: InputMaybe<PolicyWeight>;
};

export type PolicyProfileQueryInput = {
    actorIds?: InputMaybe<Array<Scalars['String']['input']>>;
    displayName?: InputMaybe<StringQueryInput>;
    policyIds?: InputMaybe<Array<Scalars['String']['input']>>;
    profileId?: InputMaybe<Scalars['String']['input']>;
    targetIds?: InputMaybe<Array<Scalars['String']['input']>>;
    tenantId?: InputMaybe<Scalars['String']['input']>;
    version?: InputMaybe<Scalars['Int64']['input']>;
};

export type PolicyProfileTarget = {
    action: PolicyAction;
    options: Array<PolicyOptions>;
    profileId: Scalars['String']['output'];
    targetId: Scalars['String']['output'];
    threshold: Scalars['Float']['output'];
};

export type PolicyProfileTargetInput = {
    action?: InputMaybe<PolicyAction>;
    options: Array<PolicyOptions>;
    targetId: Scalars['String']['input'];
    threshold?: InputMaybe<Scalars['Float']['input']>;
};

export enum PolicyStatus {
    PolicyStatusError = 'POLICY_STATUS_ERROR',
    PolicyStatusPending = 'POLICY_STATUS_PENDING',
    PolicyStatusStaged = 'POLICY_STATUS_STAGED',
    PolicyStatusSynced = 'POLICY_STATUS_SYNCED',
    PolicyStatusUnknown = 'POLICY_STATUS_UNKNOWN',
}

export enum PolicyWeight {
    PolicyWeightCritical = 'POLICY_WEIGHT_CRITICAL',
    PolicyWeightDisabled = 'POLICY_WEIGHT_DISABLED',
    PolicyWeightHigh = 'POLICY_WEIGHT_HIGH',
    PolicyWeightLow = 'POLICY_WEIGHT_LOW',
    PolicyWeightMedium = 'POLICY_WEIGHT_MEDIUM',
    PolicyWeightUnknown = 'POLICY_WEIGHT_UNKNOWN',
}

export type PreCacheWarmCheckerOutput = {
    error: Scalars['String']['output'];
    progress: Scalars['Int64']['output'];
    running: Scalars['Boolean']['output'];
    tenantId: Scalars['String']['output'];
};

export type Provider = {
    firstEventAt: Scalars['Int64']['output'];
    lastError?: Maybe<Scalars['String']['output']>;
    lastErrorAt: Scalars['Int64']['output'];
    lastEventAt: Scalars['Int64']['output'];
    name: Scalars['String']['output'];
    providerId: Scalars['String']['output'];
    status: Scalars['String']['output'];
    storageQuota: Scalars['Int64']['output'];
    storageUsed: Scalars['Int64']['output'];
    tenantId: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type ProviderAuthOutput = {
    authHeader: Scalars['String']['output'];
    output?: Maybe<Array<KeyValueTuple>>;
    provider?: Maybe<Provider>;
    url: Scalars['String']['output'];
};

export type ProviderEnrichmentModuleInput = {
    EnableInference?: InputMaybe<Scalars['Boolean']['input']>;
    EnableTraining?: InputMaybe<Scalars['Boolean']['input']>;
    Image?: InputMaybe<Scalars['String']['input']>;
    Replicas?: InputMaybe<Scalars['Int32']['input']>;
    providerId: Scalars['ID']['input'];
    providerType: Scalars['String']['input'];
    type: ProviderEnrichmentModuleType;
};

export enum ProviderEnrichmentModuleType {
    ProviderEnrichmentModulesMorpheusDfp = 'PROVIDER_ENRICHMENT_MODULES_MORPHEUS_DFP',
    ProviderEnrichmentModulesUnknown = 'PROVIDER_ENRICHMENT_MODULES_UNKNOWN',
}

export type ProviderFieldMap = {
    DisplayName: Scalars['String']['output'];
    Fields: Array<Maybe<Field>>;
    HelpURI: Scalars['String']['output'];
    Hook?: Maybe<Scalars['Boolean']['output']>;
    HookAuthorizationKey?: Maybe<Scalars['String']['output']>;
    Name: Scalars['String']['output'];
};

export type ProviderInput = {
    authField1?: InputMaybe<Scalars['String']['input']>;
    authField2?: InputMaybe<Scalars['String']['input']>;
    authField3?: InputMaybe<Scalars['String']['input']>;
    authField4?: InputMaybe<Scalars['String']['input']>;
    authField5?: InputMaybe<Scalars['String']['input']>;
    authField6?: InputMaybe<Scalars['String']['input']>;
    authField7?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
    type: Scalars['String']['input'];
};

export enum ProviderType {
    ProviderTypeAladdin = 'PROVIDER_TYPE_ALADDIN',
    ProviderTypeAws = 'PROVIDER_TYPE_AWS',
    ProviderTypeAzuread = 'PROVIDER_TYPE_AZUREAD',
    ProviderTypeCrowdstrike = 'PROVIDER_TYPE_CROWDSTRIKE',
    ProviderTypeGsuite = 'PROVIDER_TYPE_GSUITE',
    ProviderTypeJamf = 'PROVIDER_TYPE_JAMF',
    ProviderTypeOffice365 = 'PROVIDER_TYPE_OFFICE365',
    ProviderTypeOkta = 'PROVIDER_TYPE_OKTA',
}

export type PublishPolicyDocumentResponse = {
    errors?: Maybe<Array<PolicyError>>;
    policyDocumentId: Scalars['String']['output'];
    status: PolicyStatus;
    tenantId: Scalars['String']['output'];
};

export type Query = {
    getAgent: Agent;
    getAgentDataChannel: AgentDataChannelOutput;
    getAgentDownloadUrl?: Maybe<Scalars['String']['output']>;
    getAlertCount: GetAlertCountOutput;
    /** Get an API key by tenant and API key ID */
    getApiKey: ApiKey;
    getAttributesSearchFields?: Maybe<GetAttributesSearchFieldsOutput>;
    getAuthFieldsProvider: ProviderFieldMap;
    getEntities: Array<Entity>;
    getEntitiesAsNodes: Array<Node>;
    getEntitiesByTypeAsNodes: GetEntitiesByTypeOutput;
    getEntityAccessors?: Maybe<GetEntityAccessorsOutput>;
    getEntityAttributes: Array<Maybe<TimeSeriesAttribute>>;
    getEntityByNeighborCount?: Maybe<GetEntityByNeighborCountOutput>;
    getEntityByStatus?: Maybe<GetEntityByStatusOutput>;
    getEntityByTrust?: Maybe<GetEntityByTrustOutput>;
    getEntityCountHistory?: Maybe<GetEntityCountHistoryOutput>;
    getEntityDormant?: Maybe<GetEntityDormantOutput>;
    getEntityEventHistory?: Maybe<GetEntityHistoryOutput>;
    getEntityEvents?: Maybe<GetEntityEventsOutput>;
    getEntityEventsSummary?: Maybe<GetEntityEventsSummaryOutput>;
    getEntityEventsSummaryCodes?: Maybe<GetEntityEventsSummaryCodesOutput>;
    getEntityEventsSummaryCodesList?: Maybe<GetEntityEventsOutput>;
    getEntityHistory?: Maybe<GetEntityHistoryOutput>;
    /** Get a list of entities ordered by first accessed date. The order field sorts the firstaccessed date field.  */
    getEntityLastCreated?: Maybe<GetEntityLastCreatedOutput>;
    getEntityList?: Maybe<GetEntityListOutput>;
    getEntityLocations?: Maybe<GetEntityLocationsOutput>;
    getEntityPermissionStats?: Maybe<GetEntityPermissionStatsOutput>;
    getEntityProfile?: Maybe<GetEntityProfileOutput>;
    getEntityReturnToLife?: Maybe<GetEntityReturnToLifeOutput>;
    getEntityRisky?: Maybe<GetEntityRiskyOutput>;
    getEntitySpider?: Maybe<GetEntitySpiderOutput>;
    getEntityTrustProfile?: Maybe<GetEntityTrustProfileOutput>;
    /** Get a graph with the nodes and edges for a given levelTrail, this only applies to Targets today */
    getExpandedGrouping: IdentityMap;
    getGlobalTrustStatistics?: Maybe<GetGlobalTrustStatisticsOutput>;
    /** Get Helpscout JWT Token */
    getHelpscoutToken: HelpscoutToken;
    getIdentityMap: IdentityMap;
    getInventoryStatistics?: Maybe<GetInventoryStatisticsOutput>;
    getLocationGrid: QueryGridOutput;
    getLocationHistory?: Maybe<GetLocationHistoryOutput>;
    getPermissionsMap: PermissionsMapOutput;
    /** Get a policy by tenant and policy ID */
    getPolicyDocument: PolicyDocument;
    getPolicyProfile: PolicyProfile;
    getProvider: Provider;
    getProviderFieldMap: Array<Maybe<ProviderFieldMap>>;
    /** Get the raw events for a given tenant, providerType, providerId, and eventId */
    getRawEvent: Array<Maybe<EventData>>;
    getRole: Role;
    getStatusReasons?: Maybe<GetStatusReasonsOutput>;
    getStorage: Storage;
    getStorageKeys: StorageKeysOutput;
    /** Get a tenant by ID */
    getTenant: Tenant;
    /** Get a tenant by Slug */
    getTenantBySlug: Tenant;
    getTotalAlertCount: GetTotalAlertCountOutput;
    getTrustProfileHistory?: Maybe<GetTrustProfileHistoryOutput>;
    /** Get a user by tenant and user ID */
    getUser: User;
    getWallEvents: WallEventsOutput;
    /** Get a webhook by tenant and webhook ID */
    getWebhook: Webhook;
    listAgentTypes: Array<Maybe<Scalars['String']['output']>>;
    listAgents: Array<Maybe<Agent>>;
    listAlertDefinitions: ListAlertDefinitionsOutput;
    listAlerts: ListAlertOutput;
    /** Get all API keys for a given tenant */
    listApiKeys: Array<ApiKey>;
    /** Get all policies for a given tenant ID, optionally filtered by PolicyDocumentQueryInput */
    listPolicyDocuments: Array<Maybe<PolicyDocument>>;
    listPolicyProfiles: Array<PolicyProfile>;
    listProviders: Array<Maybe<Provider>>;
    listRoles: Array<Maybe<Role>>;
    /** Get all tenants */
    listTenants: Array<Maybe<Tenant>>;
    /** List user permissions */
    listUserPermissions: Array<UserPermissions>;
    /** Get all users for a given tenant, optionally filtered by UserQueryInput */
    listUsers: Array<Maybe<User>>;
    /** Get all webhooks for a given tenant */
    listWebhooks: Array<Webhook>;
    queryAccessDetails: Array<AccessEvent>;
    queryActor?: Maybe<GetActorOutput>;
    queryApplication?: Maybe<GetApplicationOutput>;
    queryTarget?: Maybe<GetTargetOutput>;
    searchAttributes?: Maybe<SearchAttributesOutput>;
};

export type QueryGetAgentArgs = {
    agentId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetAgentDataChannelArgs = {
    agentId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetAgentDownloadUrlArgs = {
    arch: AgentArch;
    os: AgentOs;
    version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAlertCountArgs = {
    input: GetAlertCountInput;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetApiKeyArgs = {
    apiKeyId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetAttributesSearchFieldsArgs = {
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetAuthFieldsProviderArgs = {
    providerId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntitiesArgs = {
    entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
    entityType: StatsEntityType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntitiesAsNodesArgs = {
    entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
    entityType: StatsEntityType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntitiesByTypeAsNodesArgs = {
    cursor?: InputMaybe<Scalars['String']['input']>;
    dateInMs?: InputMaybe<Scalars['Int64']['input']>;
    entityType: StatsEntityType;
    permissionsOnly?: InputMaybe<Scalars['Boolean']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityAccessorsArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    grouping?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityAttributesArgs = {
    endTimeInMs: Scalars['Int64']['input'];
    entityId: Scalars['String']['input'];
    entityType: StatsEntityType;
    startTimeInMs: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityByNeighborCountArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    neighborType: StatsEntityType;
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityByStatusArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    status: AccessOutcomeType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityByTrustArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    limit: Scalars['Int']['input'];
    minTrustScore?: InputMaybe<Scalars['Float']['input']>;
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityCountHistoryArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tags: Array<InputMaybe<IdentityTags>>;
    tenantId: Scalars['ID']['input'];
    unit: StatsUnits;
};

export type QueryGetEntityDormantArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    tenantId: Scalars['ID']['input'];
    thresholdInMinutes: Scalars['Int64']['input'];
};

export type QueryGetEntityEventHistoryArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    grouping?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    unit: StatsUnits;
};

export type QueryGetEntityEventsArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    grouping?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityEventsSummaryArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    entityTarget: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityEventsSummaryCodesArgs = {
    entity: StatsEntityType;
    summaryId: Scalars['String']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityEventsSummaryCodesListArgs = {
    codeId: Scalars['String']['input'];
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityHistoryArgs = {
    detailed: Scalars['Boolean']['input'];
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    unit: StatsUnits;
};

export type QueryGetEntityLastCreatedArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityListArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int32']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityLocationsArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    unit: StatsUnits;
};

export type QueryGetEntityPermissionStatsArgs = {
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityProfileArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityReturnToLifeArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    thresholdInMinutes: Scalars['Int64']['input'];
};

export type QueryGetEntityRiskyArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntitySpiderArgs = {
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetEntityTrustProfileArgs = {
    entity: StatsEntityType;
    entityId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetExpandedGroupingArgs = {
    endTime?: InputMaybe<Scalars['Int64']['input']>;
    filter?: InputMaybe<Filter>;
    levelTrails: Array<Scalars['String']['input']>;
    loadAttributes?: InputMaybe<Scalars['Boolean']['input']>;
    startTime?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetGlobalTrustStatisticsArgs = {
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetHelpscoutTokenArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueryGetIdentityMapArgs = {
    aggregated?: InputMaybe<Scalars['Boolean']['input']>;
    edgesOnly?: InputMaybe<Scalars['Boolean']['input']>;
    endTime?: InputMaybe<Scalars['Int64']['input']>;
    filter?: InputMaybe<Filter>;
    loadAttributes?: InputMaybe<Scalars['Boolean']['input']>;
    maxLevelDepth?: InputMaybe<Scalars['Int64']['input']>;
    queryId?: InputMaybe<Scalars['String']['input']>;
    startTime?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetInventoryStatisticsArgs = {
    entity?: InputMaybe<StatsEntityType>;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetLocationGridArgs = {
    endTimeInMs: Scalars['Int64']['input'];
    filter?: InputMaybe<Filter>;
    gridLevel?: InputMaybe<Scalars['Int32']['input']>;
    startTimeInMs: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    trustThreshold?: InputMaybe<Scalars['Float']['input']>;
    xValues?: InputMaybe<Array<Scalars['Float']['input']>>;
    yValues?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type QueryGetLocationHistoryArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    grouping?: InputMaybe<Scalars['String']['input']>;
    groupingLevel: Scalars['String']['input'];
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    unit: StatsUnits;
};

export type QueryGetPermissionsMapArgs = {
    date: Scalars['Int64']['input'];
    entryEntityId?: InputMaybe<Scalars['ID']['input']>;
    exitEntityId?: InputMaybe<Scalars['ID']['input']>;
    filters?: InputMaybe<PermissionsFilter>;
    limit?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetPolicyDocumentArgs = {
    policyId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
    version?: InputMaybe<Scalars['Int64']['input']>;
};

export type QueryGetPolicyProfileArgs = {
    policyProfileId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetProviderArgs = {
    providerId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetProviderFieldMapArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryGetRawEventArgs = {
    eventId: Scalars['ID']['input'];
    providerId: Scalars['String']['input'];
    providerType: ProviderType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetRoleArgs = {
    roleId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetStatusReasonsArgs = {
    endDate: Scalars['Int64']['input'];
    grouping?: InputMaybe<Scalars['String']['input']>;
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    status: AccessOutcomeType;
    tenantId: Scalars['ID']['input'];
};

export type QueryGetStorageArgs = {
    key: Scalars['String']['input'];
    shared: Scalars['Boolean']['input'];
    tenantId: Scalars['ID']['input'];
    userId: Scalars['String']['input'];
};

export type QueryGetStorageKeysArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryGetTenantArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryGetTenantBySlugArgs = {
    slug: Scalars['String']['input'];
};

export type QueryGetTotalAlertCountArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryGetTrustProfileHistoryArgs = {
    endDate: Scalars['Int64']['input'];
    profileId?: InputMaybe<Scalars['ID']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
    unit: StatsUnits;
};

export type QueryGetUserArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueryGetWallEventsArgs = {
    alternateId?: InputMaybe<Scalars['String']['input']>;
    cursor?: InputMaybe<Scalars['String']['input']>;
    endDate: Scalars['Int64']['input'];
    eventType?: InputMaybe<WallEventType>;
    externalId?: InputMaybe<Scalars['String']['input']>;
    key?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Int64']['input']>;
    nodeId?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGetWebhookArgs = {
    tenantId: Scalars['ID']['input'];
    webhookId: Scalars['ID']['input'];
};

export type QueryListAgentTypesArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryListAgentsArgs = {
    cursor?: InputMaybe<Scalars['String']['input']>;
    filter?: InputMaybe<AgentQueryInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryListAlertDefinitionsArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryListAlertsArgs = {
    input: ListAlertInput;
    tenantId: Scalars['ID']['input'];
};

export type QueryListApiKeysArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryListPolicyDocumentsArgs = {
    filter?: InputMaybe<PolicyDocumentQueryInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryListPolicyProfilesArgs = {
    filter?: InputMaybe<PolicyProfileQueryInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryListProvidersArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryListRolesArgs = {
    filter?: InputMaybe<RoleQueryInput>;
    tenantId: Scalars['ID']['input'];
};

export type QueryListTenantsArgs = {
    cursor?: InputMaybe<Scalars['String']['input']>;
    filter?: InputMaybe<TenantQueryInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryListUserPermissionsArgs = {
    tenantId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueryListUsersArgs = {
    cursor?: InputMaybe<Scalars['String']['input']>;
    filter?: InputMaybe<UserQueryInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryListWebhooksArgs = {
    tenantId: Scalars['ID']['input'];
};

export type QueryQueryAccessDetailsArgs = {
    accessGroupId?: InputMaybe<Scalars['String']['input']>;
    endTime?: InputMaybe<Scalars['Int64']['input']>;
    eventIds?: InputMaybe<Array<Scalars['String']['input']>>;
    filter?: InputMaybe<Filter>;
    startTime?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryQueryActorArgs = {
    endTime?: InputMaybe<Scalars['Int64']['input']>;
    pagination?: InputMaybe<PaginationInput>;
    query: ActorQueryInput;
    startTime?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryQueryApplicationArgs = {
    endTime?: InputMaybe<Scalars['Int64']['input']>;
    pagination?: InputMaybe<PaginationInput>;
    query: ApplicationQueryInput;
    startTime?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QueryQueryTargetArgs = {
    endTime?: InputMaybe<Scalars['Int64']['input']>;
    pagination?: InputMaybe<PaginationInput>;
    query: TargetQueryInput;
    startTime?: InputMaybe<Scalars['Int64']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type QuerySearchAttributesArgs = {
    endDate: Scalars['Int64']['input'];
    entity: StatsEntityType;
    search: Scalars['String']['input'];
    searchFields?: InputMaybe<Array<InputMaybe<SearchAttributesField>>>;
    sortField: Scalars['String']['input'];
    startDate: Scalars['Int64']['input'];
    tenantId: Scalars['ID']['input'];
};

export type QueryGridOutput = {
    columns: Scalars['Int32']['output'];
    endTime: Scalars['Int64']['output'];
    grid?: Maybe<Array<Maybe<GridCol>>>;
    gridLevel?: Maybe<Scalars['Int32']['output']>;
    rows: Scalars['Int32']['output'];
    startTime: Scalars['Int64']['output'];
    tenantId: Scalars['String']['output'];
    trust?: Maybe<Array<Maybe<GridCol>>>;
    xValues: Array<Maybe<Scalars['Float']['output']>>;
    yValues: Array<Maybe<Scalars['Float']['output']>>;
};

export type ReasonQueryInput = {
    reason?: InputMaybe<Scalars['String']['input']>;
};

export type ResolveAlertsInput = {
    alertIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ResolveAlertsOutput = {
    count: Scalars['Int32']['output'];
};

export type Role = {
    members: Array<Scalars['ID']['output']>;
    name: Scalars['String']['output'];
    roleId: Scalars['String']['output'];
    tenantId: Scalars['String']['output'];
};

export type RoleQueryInput = {
    roleName?: InputMaybe<StringQueryInput>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchAttributesField = {
    field: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type SearchAttributesItem = {
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    enabled: Scalars['Boolean']['output'];
    entity: Scalars['String']['output'];
    id: Scalars['String']['output'];
    jobTitle: Scalars['String']['output'];
    postalCode: Scalars['String']['output'];
    sid: Scalars['String']['output'];
    state: Scalars['String']['output'];
};

export type SearchAttributesOutput = {
    items?: Maybe<Array<Maybe<SearchAttributesItem>>>;
};

export enum SortOrder {
    SortOrderAscending = 'SORT_ORDER_ASCENDING',
    SortOrderDescending = 'SORT_ORDER_DESCENDING',
}

export type StagePolicyDocumentResponse = {
    errors?: Maybe<Array<PolicyError>>;
    policyDocument?: Maybe<PolicyDocument>;
    policyDocumentId?: Maybe<Scalars['String']['output']>;
    status: StagingStatus;
    tenantId: Scalars['String']['output'];
};

export enum StagingStatus {
    StagingStatusStagedError = 'STAGING_STATUS_STAGED_ERROR',
    StagingStatusStagedOk = 'STAGING_STATUS_STAGED_OK',
    StagingStatusUnknown = 'STAGING_STATUS_UNKNOWN',
    StagingStatusValidateInvalid = 'STAGING_STATUS_VALIDATE_INVALID',
    StagingStatusValidateValid = 'STAGING_STATUS_VALIDATE_VALID',
}

export enum StatsEntityType {
    StatsEntityTypeActor = 'STATS_ENTITY_TYPE_ACTOR',
    StatsEntityTypeApplication = 'STATS_ENTITY_TYPE_APPLICATION',
    StatsEntityTypeDevice = 'STATS_ENTITY_TYPE_DEVICE',
    StatsEntityTypeGroup = 'STATS_ENTITY_TYPE_GROUP',
    StatsEntityTypeIdentity = 'STATS_ENTITY_TYPE_IDENTITY',
    StatsEntityTypeLocation = 'STATS_ENTITY_TYPE_LOCATION',
    StatsEntityTypePolicy = 'STATS_ENTITY_TYPE_POLICY',
    StatsEntityTypeSession = 'STATS_ENTITY_TYPE_SESSION',
    StatsEntityTypeTarget = 'STATS_ENTITY_TYPE_TARGET',
    StatsEntityTypeUnknown = 'STATS_ENTITY_TYPE_UNKNOWN',
}

export enum StatsOrder {
    StatsOrderAsc = 'STATS_ORDER_ASC',
    StatsOrderDesc = 'STATS_ORDER_DESC',
}

export enum StatsUnits {
    StatsUnitsDay = 'STATS_UNITS_DAY',
    StatsUnitsHour = 'STATS_UNITS_HOUR',
    StatsUnitsMinute = 'STATS_UNITS_MINUTE',
    StatsUnitsMonth = 'STATS_UNITS_MONTH',
    StatsUnitsWeek = 'STATS_UNITS_WEEK',
    StatsUnitsYear = 'STATS_UNITS_YEAR',
}

export type Storage = {
    data: Scalars['String']['output'];
    id: Scalars['String']['output'];
    key: Scalars['String']['output'];
    publishedAt: Scalars['Int64']['output'];
    shared: Scalars['Boolean']['output'];
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
    userId: Scalars['String']['output'];
};

export type StorageKey = {
    key: Scalars['String']['output'];
    shared: Scalars['Boolean']['output'];
    userId: Scalars['String']['output'];
};

export type StorageKeysOutput = {
    keys?: Maybe<Array<StorageKey>>;
};

export type StringQueryInput = {
    beginWith?: InputMaybe<Scalars['String']['input']>;
    contains?: InputMaybe<Scalars['String']['input']>;
    notContains?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
    agentControlChannel: AgentControlMessage;
};

export type SubscriptionAgentControlChannelArgs = {
    agentId: Scalars['ID']['input'];
    tenantId: Scalars['ID']['input'];
};

export type TagQueryInput = {
    excludedTags?: InputMaybe<Array<InputMaybe<IdentityTags>>>;
    includedTags?: InputMaybe<Array<InputMaybe<IdentityTags>>>;
};

export type Target = {
    /** returns the actors for a given target in the specified timeframe of the query */
    actors: Array<Actor>;
    createdAt: Scalars['Int64']['output'];
    displayName: Scalars['String']['output'];
    id: Scalars['String']['output'];
    svid: Scalars['String']['output'];
    tags: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
};

export type TargetQueryInput = {
    tags?: InputMaybe<TagQueryInput>;
    targetDisplayName?: InputMaybe<StringQueryInput>;
    targetIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
    targetServiceDomain?: InputMaybe<StringQueryInput>;
    targetServiceId?: InputMaybe<StringQueryInput>;
    targetType?: InputMaybe<TargetType>;
};

export enum TargetType {
    TargetTypeFile = 'TARGET_TYPE_FILE',
    TargetTypeGroup = 'TARGET_TYPE_GROUP',
    TargetTypeMailbox = 'TARGET_TYPE_MAILBOX',
    TargetTypeService = 'TARGET_TYPE_SERVICE',
    TargetTypeUnknown = 'TARGET_TYPE_UNKNOWN',
    TargetTypeWeb = 'TARGET_TYPE_WEB',
}

export type Tenant = {
    cloud: Scalars['String']['output'];
    creationError?: Maybe<Scalars['String']['output']>;
    crmId: Scalars['String']['output'];
    exportEnabled?: Maybe<Scalars['Boolean']['output']>;
    firstEventAt: Scalars['Int64']['output'];
    lastEventAt: Scalars['Int64']['output'];
    metadata?: Maybe<TenantMetadata>;
    name: Scalars['String']['output'];
    region: Scalars['String']['output'];
    slug: Scalars['String']['output'];
    status: TenantStatus;
    storageUsed: Scalars['Int64']['output'];
    tenantId: Scalars['String']['output'];
};

export type TenantInput = {
    cloud?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    orgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    region?: InputMaybe<Scalars['String']['input']>;
    slug: Scalars['String']['input'];
    tenantId?: InputMaybe<Scalars['ID']['input']>;
};

export type TenantMetadata = {
    saasPlatformTenantAccessTokenUrl: Scalars['String']['output'];
    saasPlatformTenantApiUrl: Scalars['String']['output'];
    saasPlatformTenantId: Scalars['String']['output'];
    saasPlatformTenantUrl: Scalars['String']['output'];
};

export type TenantQueryInput = {
    cloud?: InputMaybe<StringQueryInput>;
    name?: InputMaybe<StringQueryInput>;
    region?: InputMaybe<StringQueryInput>;
};

export enum TenantStatus {
    TenantStatusApproved = 'TENANT_STATUS_APPROVED',
    TenantStatusCreationError = 'TENANT_STATUS_CREATION_ERROR',
    TenantStatusCreationInProgress = 'TENANT_STATUS_CREATION_IN_PROGRESS',
    TenantStatusDisabled = 'TENANT_STATUS_DISABLED',
    TenantStatusPending = 'TENANT_STATUS_PENDING',
    TenantStatusProvisioned = 'TENANT_STATUS_PROVISIONED',
    TenantStatusUnknown = 'TENANT_STATUS_UNKNOWN',
}

export type TenantUpdateInput = {
    crmId?: InputMaybe<Scalars['String']['input']>;
    exportEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<TenantStatus>;
};

export type TimeSeriesAttribute = {
    key: Scalars['String']['output'];
    updatedAt: Scalars['Int64']['output'];
    value: Scalars['String']['output'];
};

export type TriggeredNodeDetails = {
    alternateId?: Maybe<Scalars['String']['output']>;
    displayName?: Maybe<Scalars['String']['output']>;
};

export type TrustStatisticBucket = {
    count: Scalars['Int64']['output'];
    endTrustScore: Scalars['Float']['output'];
    name: Scalars['String']['output'];
    startTrustScore: Scalars['Float']['output'];
    tenantId: Scalars['String']['output'];
};

export type UpdateAlertDefinitionInput = {
    alertThreshold: Scalars['Int32']['input'];
    id: Scalars['String']['input'];
    jobPayload: Scalars['String']['input'];
    jobType: JobType;
    message: Scalars['String']['input'];
    name: Scalars['String']['input'];
    query: Scalars['String']['input'];
    warningThreshold: Scalars['Int32']['input'];
};

export type UpdateAlertDefinitionOutput = {
    success: Scalars['Boolean']['output'];
};

export type UpdatePolicyDocumentFieldsInput = {
    author?: InputMaybe<Scalars['String']['input']>;
    category?: InputMaybe<Scalars['String']['input']>;
    dataSources?: InputMaybe<Array<Scalars['String']['input']>>;
    description?: InputMaybe<Scalars['String']['input']>;
    displayName?: InputMaybe<Scalars['String']['input']>;
    icon?: InputMaybe<Scalars['String']['input']>;
    policyDocument?: InputMaybe<Scalars['String']['input']>;
    policyDocumentId: Scalars['String']['input'];
    status?: InputMaybe<PolicyStatus>;
};

export type User = {
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    name: Scalars['String']['output'];
    roleIds: Array<Scalars['String']['output']>;
    tenantId: Scalars['String']['output'];
    userId: Scalars['String']['output'];
};

export type UserInput = {
    email: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    roleIds: Array<Scalars['ID']['input']>;
    tenantId: Scalars['ID']['input'];
};

export type UserPermissions = {
    path: Scalars['String']['output'];
    permissions: Array<Maybe<Scalars['String']['output']>>;
};

export type UserQueryInput = {
    email?: InputMaybe<StringQueryInput>;
    firstName?: InputMaybe<StringQueryInput>;
    lastName?: InputMaybe<StringQueryInput>;
};

export type UserUpdateInput = {
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type VersionQueryInput = {
    major?: InputMaybe<Scalars['String']['input']>;
};

export type WallEvent = {
    alternateId: Scalars['String']['output'];
    displayName: Scalars['String']['output'];
    externalId: Scalars['String']['output'];
    key: Scalars['String']['output'];
    linkedAt: Scalars['Int64']['output'];
    linkedKey: Scalars['String']['output'];
    nodeId: Scalars['String']['output'];
    object: Scalars['String']['output'];
    publishedAt: Scalars['Int64']['output'];
    sourceProcessorId: Scalars['String']['output'];
    sourceProcessorType: Scalars['String']['output'];
    tenantId: Scalars['String']['output'];
    type: WallEventType;
};

export enum WallEventType {
    WallEventTypeActorActivityPaused = 'WallEventType_ACTOR_ACTIVITY_PAUSED',
    WallEventTypeActorActivityStart = 'WallEventType_ACTOR_ACTIVITY_START',
    WallEventTypeActorCreated = 'WallEventType_ACTOR_CREATED',
    WallEventTypeActorDisabled = 'WallEventType_ACTOR_DISABLED',
    WallEventTypeActorEnabled = 'WallEventType_ACTOR_ENABLED',
    WallEventTypeActorUpdated = 'WallEventType_ACTOR_UPDATED',
    WallEventTypeLoginFailed = 'WallEventType_LOGIN_FAILED',
    WallEventTypeOther = 'WallEventType_OTHER',
    WallEventTypeSessionEnd = 'WallEventType_SESSION_END',
    WallEventTypeSessionStart = 'WallEventType_SESSION_START',
}

export type WallEventsOutput = {
    cursor: Scalars['String']['output'];
    events?: Maybe<Array<WallEvent>>;
};

export type Webhook = {
    headers?: Maybe<Array<Maybe<Header>>>;
    method: HttpMethod;
    url: Scalars['String']['output'];
    webhookId: Scalars['ID']['output'];
};

export type WebhookInput = {
    headers?: InputMaybe<Array<InputMaybe<HeaderInput>>>;
    method: HttpMethod;
    url: Scalars['String']['input'];
};

export type GetEntitiesAsNodesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    entityType: StatsEntityType;
    entityIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetEntitiesAsNodesQuery = {
    getEntitiesAsNodes: Array<{
        nodeId: string;
        nodeType: NodeType;
        label: string;
        props?: any | null;
        tags: Array<string | null>;
        groupingData?: any | null;
        currentLevelTrail?: string | null;
        hasNextLevel?: boolean | null;
        nextLevelBase64?: string | null;
        attributes?: Array<{ updatedAt: any; key: string; value: string } | null> | null;
    }>;
};

export type GetInventoryStatisticsQueryVariables = Exact<{
    entity?: InputMaybe<StatsEntityType>;
    tenantId: Scalars['ID']['input'];
}>;

export type GetInventoryStatisticsQuery = {
    getInventoryStatistics?: {
        items?: Array<{
            count?: any | null;
            subType?: InventorySubtype | null;
            entityType?: StatsEntityType | null;
        } | null> | null;
    } | null;
};

export type GetGlobalTrustStatisticsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    entity: StatsEntityType;
}>;

export type GetGlobalTrustStatisticsQuery = {
    getGlobalTrustStatistics?: {
        globalTrustScore: number;
        buckets: Array<{ count: any; name: string; startTrustScore: number; endTrustScore: number }>;
    } | null;
};

export type GetEntityByTrustQueryVariables = Exact<{
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
    limit: Scalars['Int']['input'];
    order: StatsOrder;
    startDate: Scalars['Int64']['input'];
    endDate: Scalars['Int64']['input'];
}>;

export type GetEntityByTrustQuery = {
    getEntityByTrust?: { items: Array<{ entityId: Array<string>; trustScore: number; accessCount: any }> } | null;
};

export type GetEntityProfileQueryVariables = Exact<{
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
    entityId: Scalars['ID']['input'];
    startDate: Scalars['Int64']['input'];
    endDate: Scalars['Int64']['input'];
}>;

export type GetEntityProfileQuery = {
    getEntityProfile?: {
        firstAccessed: any;
        lastAccessed: any;
        providers: Array<string | null>;
        mfaMethods: Array<FactorType | null>;
        tags: Array<string>;
    } | null;
};

export type GetEntityPermissionStatsQueryVariables = Exact<{
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
    entityId: Scalars['ID']['input'];
}>;

export type GetEntityPermissionStatsQuery = {
    getEntityPermissionStats?: { groupCount: any; policyCount: any; targetCount: any } | null;
};

export type GetEntitySpiderQueryVariables = Exact<{
    entity: StatsEntityType;
    tenantId: Scalars['ID']['input'];
    entityId: Scalars['ID']['input'];
}>;

export type GetEntitySpiderQuery = {
    getEntitySpider?: {
        dimensions: {
            actor: number;
            sessions: number;
            policies: number;
            devices: number;
            processes: number;
            targets: number;
            activities: number;
        };
    } | null;
};

export type ListPolicyProfilesQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    filter?: InputMaybe<PolicyProfileQueryInput>;
}>;

export type ListPolicyProfilesQuery = {
    listPolicyProfiles: Array<{
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        deletedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    }>;
};

export type GetPolicyProfileQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    profileId: Scalars['ID']['input'];
}>;

export type GetPolicyProfileQuery = {
    getPolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type CreatePolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileInput: PolicyProfileInput;
}>;

export type CreatePolicyProfileMutation = {
    createPolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type UpdatePolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    profileId: Scalars['ID']['input'];
    policyProfileInput: PolicyProfileInput;
}>;

export type UpdatePolicyProfileMutation = {
    updatePolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type DeletePolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    profileId: Scalars['ID']['input'];
}>;

export type DeletePolicyProfileMutation = { deletePolicyProfile: boolean };

export type AddActorsToPolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    actorIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AddActorsToPolicyProfileMutation = {
    addActorsToPolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type RemoveActorsFromPolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    actorIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveActorsFromPolicyProfileMutation = {
    removeActorsFromPolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type AddTargetsToPolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    targets: Array<PolicyProfileTargetInput> | PolicyProfileTargetInput;
}>;

export type AddTargetsToPolicyProfileMutation = {
    addTargetsToPolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type RemoveTargetsFromPolicyProfileMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    targetIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveTargetsFromPolicyProfileMutation = {
    removeTargetsFromPolicyProfile: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type AddPolicyProfilePolicyMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    policies: Array<InputMaybe<PolicyProfilePolicyInput>> | InputMaybe<PolicyProfilePolicyInput>;
}>;

export type AddPolicyProfilePolicyMutation = {
    addPolicyProfilePolicy: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type RemovePolicyProfilePolicyMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    policyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemovePolicyProfilePolicyMutation = {
    removePolicyProfilePolicy: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type UpdatePolicyProfilePolicyMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyProfileId: Scalars['ID']['input'];
    policy: PolicyProfilePolicyInput;
}>;

export type UpdatePolicyProfilePolicyMutation = {
    updatePolicyProfilePolicy: {
        profileId: string;
        displayName: string;
        description: string;
        actorIds: Array<string>;
        version: any;
        originVersion: any;
        externalReadOnly: boolean;
        createdAt: any;
        updatedAt: any;
        policies: Array<{ profileId: string; policyId: string; weight: PolicyWeight } | null>;
        targets: Array<{
            profileId: string;
            targetId: string;
            threshold: number;
            action: PolicyAction;
            options: Array<PolicyOptions>;
        } | null>;
    };
};

export type ListPolicyDocumentsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    filter?: InputMaybe<PolicyDocumentQueryInput>;
}>;

export type ListPolicyDocumentsQuery = {
    listPolicyDocuments: Array<{
        policyDocumentId: string;
        displayName: string;
        description: string;
        policyDocument: string;
        status: PolicyStatus;
        requestedState: PolicyAction;
        version: any;
        originVersion: any;
        signature: string;
        createdAt: any;
    } | null>;
};

export type GetPolicyDocumentQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyId: Scalars['ID']['input'];
    version?: InputMaybe<Scalars['Int64']['input']>;
}>;

export type GetPolicyDocumentQuery = {
    getPolicyDocument: {
        policyDocumentId: string;
        displayName: string;
        description: string;
        policyDocument: string;
        status: PolicyStatus;
        requestedState: PolicyAction;
        version: any;
        originVersion: any;
        signature: string;
        createdAt: any;
    };
};

export type StagePolicyDocumentMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyDocument: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type StagePolicyDocumentMutation = {
    stagePolicyDocument: {
        status: StagingStatus;
        policyDocument?: { policyDocumentId: string } | null;
        errors?: Array<{ code: string; message: string }> | null;
    };
};

export type PublishPolicyDocumentMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyDocumentId: Scalars['String']['input'];
    action?: InputMaybe<PolicyAction>;
    version?: InputMaybe<Scalars['Int64']['input']>;
}>;

export type PublishPolicyDocumentMutation = {
    publishPolicyDocument: {
        policyDocumentId: string;
        status: PolicyStatus;
        errors?: Array<{ code: string; message: string }> | null;
    };
};

export type DeletePolicyDocumentMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyDocumentId: Scalars['ID']['input'];
    version?: InputMaybe<Scalars['Int64']['input']>;
}>;

export type DeletePolicyDocumentMutation = { deletePolicyDocument: boolean };

export type UpdatePolicyDocumentMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    policyUpdates: UpdatePolicyDocumentFieldsInput;
}>;

export type UpdatePolicyDocumentMutation = {
    updatePolicyDocument: {
        policyDocumentId: string;
        displayName: string;
        description: string;
        policyDocument: string;
        status: PolicyStatus;
        requestedState: PolicyAction;
        version: any;
        originVersion: any;
        signature: string;
        createdAt: any;
    };
};

export type CreateWebhookMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    webhookInput: WebhookInput;
}>;

export type CreateWebhookMutation = {
    createWebhook: {
        webhookId: string;
        url: string;
        method: HttpMethod;
        headers?: Array<{ key: string; value: string } | null> | null;
    };
};

export type ListWebhooksQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type ListWebhooksQuery = {
    listWebhooks: Array<{
        webhookId: string;
        url: string;
        method: HttpMethod;
        headers?: Array<{ key: string; value: string } | null> | null;
    }>;
};

export type GetWebhookQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    webhookId: Scalars['ID']['input'];
}>;

export type GetWebhookQuery = {
    getWebhook: {
        webhookId: string;
        url: string;
        method: HttpMethod;
        headers?: Array<{ key: string; value: string } | null> | null;
    };
};

export type DeleteWebhookMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    webhookId: Scalars['ID']['input'];
}>;

export type DeleteWebhookMutation = { deleteWebhook: boolean };

export type GetTrustProfileHistoryQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    profileId?: InputMaybe<Scalars['ID']['input']>;
    unit: StatsUnits;
    startDate: Scalars['Int64']['input'];
    endDate: Scalars['Int64']['input'];
}>;

export type GetTrustProfileHistoryQuery = {
    getTrustProfileHistory?: { items?: Array<{ date: any; trustScore: any } | null> | null } | null;
};

export type GetStorageKeysQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetStorageKeysQuery = {
    getStorageKeys: { keys?: Array<{ key: string; shared: boolean; userId: string }> | null };
};

export type GetStorageQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    userId: Scalars['String']['input'];
    key: Scalars['String']['input'];
    shared: Scalars['Boolean']['input'];
}>;

export type GetStorageQuery = {
    getStorage: { id: string; key: string; data: string; publishedAt: any; updatedAt: any; shared: boolean };
};

export type SetStorageMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    key: Scalars['String']['input'];
    data: Scalars['String']['input'];
    shared: Scalars['Boolean']['input'];
}>;

export type SetStorageMutation = {
    setStorage: { id: string; key: string; data: string; publishedAt: any; updatedAt: any; shared: boolean };
};

export type DeleteStorageMutationVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    key: Scalars['String']['input'];
    shared: Scalars['Boolean']['input'];
}>;

export type DeleteStorageMutation = { deleteStorage: boolean };

export type ListAlertsQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
    input: ListAlertInput;
}>;

export type ListAlertsQuery = {
    listAlerts: {
        alerts?: Array<{
            id: string;
            raisedAt: any;
            eventDate: any;
            status: AlertStatus;
            muted: boolean;
            observedCount: any;
            definitionId: string;
            alertDefinitionName: string;
            triggeredNodeId: string;
        }> | null;
        pageInfo?: { currentPage?: any | null; pageCount?: any | null } | null;
    };
};

export type GetTotalAlertCountQueryVariables = Exact<{
    tenantId: Scalars['ID']['input'];
}>;

export type GetTotalAlertCountQuery = { getTotalAlertCount: { count: any } };

export type GetTenantBySlugQueryVariables = Exact<{
    slug: Scalars['String']['input'];
}>;

export type GetTenantBySlugQuery = {
    getTenantBySlug: {
        name: string;
        slug: string;
        tenantId: string;
        metadata?: {
            saasPlatformTenantId: string;
            saasPlatformTenantUrl: string;
            saasPlatformTenantApiUrl: string;
            saasPlatformTenantAccessTokenUrl: string;
        } | null;
    };
};

export const GetEntitiesAsNodesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEntitiesAsNodes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getEntitiesAsNodes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entityType' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityType' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entityIds' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'nodeId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nodeType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'props' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attributes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupingData' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'currentLevelTrail' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasNextLevel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nextLevelBase64' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEntitiesAsNodesQuery, GetEntitiesAsNodesQueryVariables>;
export const GetInventoryStatisticsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInventoryStatistics' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getInventoryStatistics' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entity' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInventoryStatisticsQuery, GetInventoryStatisticsQueryVariables>;
export const GetGlobalTrustStatisticsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getGlobalTrustStatistics' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getGlobalTrustStatistics' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entity' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'globalTrustScore' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'buckets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'startTrustScore' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endTrustScore' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGlobalTrustStatisticsQuery, GetGlobalTrustStatisticsQueryVariables>;
export const GetEntityByTrustDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEntityByTrust' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsOrder' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getEntityByTrust' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entity' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'startDate' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'endDate' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'trustScore' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEntityByTrustQuery, GetEntityByTrustQueryVariables>;
export const GetEntityProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEntityProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getEntityProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entity' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entityId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'startDate' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'endDate' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'firstAccessed' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastAccessed' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'providers' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mfaMethods' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEntityProfileQuery, GetEntityProfileQueryVariables>;
export const GetEntityPermissionStatsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEntityPermissionStats' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getEntityPermissionStats' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entity' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entityId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'groupCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'policyCount' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'targetCount' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEntityPermissionStatsQuery, GetEntityPermissionStatsQueryVariables>;
export const GetEntitySpiderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEntitySpider' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsEntityType' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getEntitySpider' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entity' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'entityId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dimensions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sessions' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policies' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'devices' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'processes' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targets' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'activities' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetEntitySpiderQuery, GetEntitySpiderQueryVariables>;
export const ListPolicyProfilesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listPolicyProfiles' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyProfileQueryInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listPolicyProfiles' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListPolicyProfilesQuery, ListPolicyProfilesQueryVariables>;
export const GetPolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getPolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPolicyProfileQuery, GetPolicyProfileQueryVariables>;
export const CreatePolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createPolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileInput' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyProfileInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createPolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileInput' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileInput' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreatePolicyProfileMutation, CreatePolicyProfileMutationVariables>;
export const UpdatePolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileInput' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyProfileInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatePolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileInput' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileInput' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdatePolicyProfileMutation, UpdatePolicyProfileMutationVariables>;
export const DeletePolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deletePolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletePolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeletePolicyProfileMutation, DeletePolicyProfileMutationVariables>;
export const AddActorsToPolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addActorsToPolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'actorIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addActorsToPolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'actorIds' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'actorIds' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddActorsToPolicyProfileMutation, AddActorsToPolicyProfileMutationVariables>;
export const RemoveActorsFromPolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeActorsFromPolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'actorIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeActorsFromPolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'actorIds' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'actorIds' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveActorsFromPolicyProfileMutation, RemoveActorsFromPolicyProfileMutationVariables>;
export const AddTargetsToPolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addTargetsToPolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'targets' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyProfileTargetInput' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addTargetsToPolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'target' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'targets' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddTargetsToPolicyProfileMutation, AddTargetsToPolicyProfileMutationVariables>;
export const RemoveTargetsFromPolicyProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeTargetsFromPolicyProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeTargetsFromPolicyProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'targetIds' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetIds' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveTargetsFromPolicyProfileMutation, RemoveTargetsFromPolicyProfileMutationVariables>;
export const AddPolicyProfilePolicyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addPolicyProfilePolicy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policies' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyProfilePolicyInput' } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addPolicyProfilePolicy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policies' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policies' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddPolicyProfilePolicyMutation, AddPolicyProfilePolicyMutationVariables>;
export const RemovePolicyProfilePolicyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removePolicyProfilePolicy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removePolicyProfilePolicy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyIds' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyIds' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemovePolicyProfilePolicyMutation, RemovePolicyProfilePolicyMutationVariables>;
export const UpdatePolicyProfilePolicyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePolicyProfilePolicy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policy' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyProfilePolicyInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatePolicyProfilePolicy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyProfileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyProfileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policy' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policy' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targets' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'actorIds' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalReadOnly' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdatePolicyProfilePolicyMutation, UpdatePolicyProfilePolicyMutationVariables>;
export const ListPolicyDocumentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listPolicyDocuments' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyDocumentQueryInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listPolicyDocuments' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocumentId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocument' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'requestedState' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListPolicyDocumentsQuery, ListPolicyDocumentsQueryVariables>;
export const GetPolicyDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPolicyDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getPolicyDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocumentId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocument' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'requestedState' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPolicyDocumentQuery, GetPolicyDocumentQueryVariables>;
export const StagePolicyDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'stagePolicyDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyDocument' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'validateOnly' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stagePolicyDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyDocument' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyDocument' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'validateOnly' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'validateOnly' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'policyDocument' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'policyDocumentId' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errors' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StagePolicyDocumentMutation, StagePolicyDocumentMutationVariables>;
export const PublishPolicyDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'publishPolicyDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyDocumentId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyAction' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishPolicyDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyDocumentId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyDocumentId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'action' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocumentId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errors' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PublishPolicyDocumentMutation, PublishPolicyDocumentMutationVariables>;
export const DeletePolicyDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deletePolicyDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyDocumentId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletePolicyDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyDocumentId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyDocumentId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'version' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeletePolicyDocumentMutation, DeletePolicyDocumentMutationVariables>;
export const UpdatePolicyDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePolicyDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'policyUpdates' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePolicyDocumentFieldsInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatePolicyDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'policyUpdates' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'policyUpdates' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocumentId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'policyDocument' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'requestedState' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'originVersion' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdatePolicyDocumentMutation, UpdatePolicyDocumentMutationVariables>;
export const CreateWebhookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createWebhook' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'webhookInput' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'WebhookInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createWebhook' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'webhookInput' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'webhookInput' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'webhookId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'headers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateWebhookMutation, CreateWebhookMutationVariables>;
export const ListWebhooksDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listWebhooks' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listWebhooks' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'webhookId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'headers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListWebhooksQuery, ListWebhooksQueryVariables>;
export const GetWebhookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getWebhook' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'webhookId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getWebhook' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'webhookId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'webhookId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'webhookId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'headers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetWebhookQuery, GetWebhookQueryVariables>;
export const DeleteWebhookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteWebhook' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'webhookId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteWebhook' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'webhookId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'webhookId' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteWebhookMutation, DeleteWebhookMutationVariables>;
export const GetTrustProfileHistoryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTrustProfileHistory' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'unit' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatsUnits' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int64' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getTrustProfileHistory' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'profileId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'unit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'unit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'startDate' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'endDate' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'trustScore' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTrustProfileHistoryQuery, GetTrustProfileHistoryQueryVariables>;
export const GetStorageKeysDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getStorageKeys' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getStorageKeys' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'keys' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetStorageKeysQuery, GetStorageKeysQueryVariables>;
export const GetStorageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getStorage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'shared' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getStorage' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'shared' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'shared' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetStorageQuery, GetStorageQueryVariables>;
export const SetStorageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setStorage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'shared' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setStorage' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'shared' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'shared' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SetStorageMutation, SetStorageMutationVariables>;
export const DeleteStorageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteStorage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'shared' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteStorage' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'shared' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'shared' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteStorageMutation, DeleteStorageMutationVariables>;
export const ListAlertsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listAlerts' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ListAlertInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listAlerts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'alerts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'raisedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'muted' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'observedCount' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'definitionId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'alertDefinitionName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'triggeredNodeId' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListAlertsQuery, ListAlertsQueryVariables>;
export const GetTotalAlertCountDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTotalAlertCount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getTotalAlertCount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'tenantId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTotalAlertCountQuery, GetTotalAlertCountQueryVariables>;
export const GetTenantBySlugDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTenantBySlug' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getTenantBySlug' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'slug' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tenantId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'saasPlatformTenantId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'saasPlatformTenantUrl' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'saasPlatformTenantApiUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'saasPlatformTenantAccessTokenUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTenantBySlugQuery, GetTenantBySlugQueryVariables>;
