import { useState, useEffect } from 'react';
import ExternalPage from 'Onboarding/ExternalPage';
import logo from 'assets/logo-192x192.png';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@apollo/client';
import { GET_TENANT_BY_SLUG } from 'Graph/typedQueries';
import { classNames } from 'Utilities/utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserManager } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import { useOrganization } from 'Utilities/OrganizationProvider';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import Keycloak from 'keycloak-js';

const oidcConfig: AuthProviderProps = {
    client_id: 'sir-ui-dev',
    disablePKCE: true,
    omitScopeWhenRequesting: true,
    redirect_uri: window.location.origin,
    authority: '',
    loadUserInfo: true,
};

const SailPointContinue = ({ currentOrganization }: { currentOrganization: string }) => {
    // Query to get tenant by slug
    const { data, error, loading } = useQuery(GET_TENANT_BY_SLUG, {
        variables: { slug: currentOrganization || '' },
        skip: !currentOrganization,
    });

    const handleContinue = (org: string) => {
        if (!data) {
            return;
        }

        if (!data.getTenantBySlug) {
            return;
        }

        if (!data.getTenantBySlug.metadata) {
            return;
        }

        const { saasPlatformTenantUrl, saasPlatformTenantAccessTokenUrl } = data.getTenantBySlug.metadata;

        console.log(
            'Redirecting to SailPoint Identity Risk with tenant:',
            org,
            saasPlatformTenantUrl,
            saasPlatformTenantAccessTokenUrl,
        );

        const authMetaData = {
            authorization_endpoint: `${saasPlatformTenantUrl}/oauth/authorize`,
            token_endpoint: saasPlatformTenantAccessTokenUrl,
            userinfo_endpoint: saasPlatformTenantAccessTokenUrl.replace('token', 'userinfo'),
            end_session_endpoint: `${saasPlatformTenantUrl}/logout`,
            authority: saasPlatformTenantUrl,
        };

        localStorage.setItem('dzTenantId', data.getTenantBySlug.tenantId);
        localStorage.setItem('authMetadata', JSON.stringify(authMetaData));

        oidcConfig.authority = saasPlatformTenantUrl;
        oidcConfig.metadata = authMetaData;

        const userManager = new UserManager(oidcConfig);

        userManager.signinRedirect();
    };

    return (
        <>
            {!error && (
                <button
                    id="continue"
                    className={classNames(
                        'text-xs px-4 py-2 btn w-full rounded-md focus:ring-0',
                        loading ? 'btn-disabled ' : 'btn-primary hover:bg-blue-900 active:bg-blue-950',
                    )}
                    onClick={() => handleContinue(currentOrganization)}
                >
                    {loading ? (
                        <span className="flex place-items-center">
                            Continue
                            <span className="h-4 w-4 loader ml-2" />
                        </span>
                    ) : (
                        'Continue'
                    )}
                </button>
            )}
            {error && (
                <p className="text-sm text-red-500">
                    Organization is not available.
                    <br />
                    <br />
                    Please select another.
                </p>
            )}
        </>
    );
};

const KeycloakContinue = ({ currentOrganization }: { currentOrganization: string }) => {
    const [loading, setLoading] = useState(false);

    const handleContinue = (org: string) => {
        console.log('Redirecting to SailPoint Identity Risk with tenant:', org);

        setLoading(true);

        const keycloakConfig = {
            url: window.__env__.KEYCLOAK_URL,
            realm: window.__env__.KEYCLOAK_REALM,
            clientId: window.__env__.KEYCLOAK_CLIENT_ID,
        };

        const kc = new Keycloak(keycloakConfig);

        kc.init({ redirectUri: window.location.origin });

        kc.login();
    };

    return (
        <>
            <button
                className={classNames(
                    'text-xs px-4 py-2 btn w-full rounded-md focus:ring-0',
                    loading ? 'btn-disabled ' : 'btn-primary hover:bg-blue-900 active:bg-blue-950',
                )}
                onClick={() => handleContinue(currentOrganization)}
            >
                {loading ? (
                    <span className="flex place-items-center">
                        Continue
                        <span className="h-4 w-4 loader ml-2" />
                    </span>
                ) : (
                    'Continue'
                )}
            </button>
        </>
    );
};

const Auth0Continue = ({ currentOrganization }: { currentOrganization: string }) => {
    const [loading, setLoading] = useState(false);

    const handleContinue = async (org: string) => {
        console.log('Redirecting to SailPoint Identity Risk with tenant:', org);

        setLoading(true);
        localStorage.setItem('org_id', currentOrganization);

        const domain = window.__env__.AUTH0_DOMAIN || '';
        const clientId = window.__env__.AUTH0_CLIENT_ID || '';
        const audience = window.__env__.AUTH0_AUDIENCE || '';

        const auth0 = await createAuth0Client({
            domain,
            clientId,
            authorizationParams: {
                audience,
                redirect_uri: window.location.origin,
                organization: currentOrganization,
            },
            useRefreshTokens: true,
        });

        auth0.loginWithRedirect();
    };

    return (
        <>
            <button
                className={classNames(
                    'text-xs px-4 py-2 btn w-full rounded-md focus:ring-0',
                    loading ? 'btn-disabled ' : 'btn-primary hover:bg-blue-900 active:bg-blue-950',
                )}
                onClick={() => handleContinue(currentOrganization)}
            >
                {loading ? (
                    <span className="flex place-items-center">
                        Continue
                        <span className="h-4 w-4 loader ml-2" />
                    </span>
                ) : (
                    'Continue'
                )}
            </button>
        </>
    );
};

const Login = (): JSX.Element => {
    const { auth, setOrgName } = useOrganization();
    const [organizations, setOrganizations] = useState<string[]>([]);
    const [currentOrganization, setCurrentOrganization] = useState<string | null>(null);

    const { register, handleSubmit, reset } = useForm<{ organization: string }>();

    useEffect(() => {
        const storedOrganizations = JSON.parse(localStorage.getItem('organizations') || '[]');
        const storedCurrentOrganization = localStorage.getItem('currentOrganization');
        setOrganizations(storedOrganizations);
        setCurrentOrganization(storedCurrentOrganization);
    }, []);

    const handleNewOrganization: SubmitHandler<{ organization: string }> = (data) => {
        const newOrganization = data.organization.trim();

        if (newOrganization && !organizations.includes(newOrganization)) {
            const updatedOrganizations = [...organizations, newOrganization];
            setOrganizations(updatedOrganizations);
            localStorage.setItem('organizations', JSON.stringify(updatedOrganizations));
        }
        handleSelectOrganization(newOrganization);
        reset();
    };

    const handleSelectOrganization = (org: string) => {
        setCurrentOrganization(org);
        setOrgName(org);
        localStorage.setItem('currentOrganization', org);
    };

    const handleDeleteOrganization = (org: string) => {
        const updatedOrganizations = organizations.filter((o) => o !== org);
        setOrganizations(updatedOrganizations);
        localStorage.setItem('organizations', JSON.stringify(updatedOrganizations));
        if (currentOrganization === org) {
            setCurrentOrganization(null);
            localStorage.removeItem('currentOrganization');
        }
    };

    return (
        <ExternalPage>
            <div className="bg-gray-700 rounded-lg py-12 px-8">
                <div className="space-y-6 ">
                    <div className="flex place-content-center">
                        <img className="h-12 w-auto" src={logo} alt="SailPoint Icon" />
                    </div>
                    <div className="text-center">
                        <h2 className="text-xl font-bold text-gray-100 mb-2">Login to SailPoint Identity Risk</h2>
                    </div>
                    {currentOrganization && (
                        <div className="flex flex-col place-items-center text-center space-y-6">
                            <p className="text-sm text-gray-100 bg-gray-600 p-2 rounded-md">{currentOrganization}</p>
                            {auth === 'sailpoint' && <SailPointContinue currentOrganization={currentOrganization} />}
                            {auth === 'keycloak' && <KeycloakContinue currentOrganization={currentOrganization} />}
                            {auth === 'auth0' && <Auth0Continue currentOrganization={currentOrganization} />}
                        </div>
                    )}
                </div>
                <hr className="my-12 border-gray-500" />
                <div>
                    <h3 className="text-md text-gray-300">Select an organization</h3>
                    <ul className="space-y-2 pt-2">
                        <form onSubmit={handleSubmit(handleNewOrganization)}>
                            <li className="flex space-x-2">
                                <input
                                    {...register('organization')}
                                    type="text"
                                    className="input-gray text-xs flex-1 p-2.5 border-0 py-0"
                                    placeholder="Enter new organization name"
                                />
                                <button type="submit" className="text-xs btn bg-gray-800 rounded-md px-2">
                                    <PlusIcon className="h-4 w-4 text-gray-100" />
                                </button>
                            </li>
                        </form>
                        {organizations.toReversed().map((org, index) => (
                            <li
                                key={index}
                                className={
                                    'w-full flex justify-between items-center space-x-2 py-2 px-2 rounded-md break-inside-avoid-column text-xs bg-gray-600 hover:bg-gray-800 active:bg-gray-900 cursor-pointer'
                                }
                                onClick={() => handleSelectOrganization(org)}
                            >
                                <span>{org}</span>
                                <button
                                    className="text-xs text-gray-500 hover:text-gray-100 hover:bg-gray-600 rounded-md"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteOrganization(org);
                                    }}
                                >
                                    <XMarkIcon className="h-4 w-4" />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </ExternalPage>
    );
};

export default Login;
