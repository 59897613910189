import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import logo from 'assets/logo-192x192.png';
import ExternalPage from '../Onboarding/ExternalPage';
import { InvalidAuthProviderConfig } from './AuthStatusMessageComponents';
import { useHistory } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }: { children: JSX.Element }): JSX.Element => {
    const history = useHistory();
    const domain = window.__env__.AUTH0_DOMAIN || '';
    const clientId = window.__env__.AUTH0_CLIENT_ID || '';
    const audience = window.__env__.AUTH0_AUDIENCE || '';
    const organization = localStorage.getItem('org_id') || undefined;

    const [validProvider, setValidProvider] = useState(true);

    const onRedirectCallback = (appState: { returnTo: string }) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    useEffect(() => {
        console.log('Authentication will be provided by Auth0');
        if (!domain || !clientId || !audience) {
            console.error('Auth0 configuration variables are not correctly specified:', {
                domain,
                clientId,
                audience,
            });
            setValidProvider(false);
        }
    }, [audience, clientId, domain]);

    return validProvider ? (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience,
                redirect_uri: window.location.origin,
                organization,
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            cacheLocation="localstorage"
        >
            <Auth0>{children}</Auth0>
        </Auth0Provider>
    ) : (
        <InvalidAuthProviderConfig />
    );
};

export default Auth0ProviderWithHistory;

const Auth0 = ({ children }: { children: JSX.Element }) => {
    const { isLoading, isAuthenticated, error, user, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated && !isLoading && !error) {
            loginWithRedirect({ appState: { returnTo: window.location.pathname + window.location.search } });
        }
    }, [error, isAuthenticated, isLoading, loginWithRedirect]);

    // Deal with Organization ID in localStorage
    useEffect(() => {
        if (user && user.org_id) {
            localStorage.setItem('org_id', String(user.org_id));
        }

        if (error) {
            localStorage.removeItem('org_id');
        }
    }, [error, user]);

    return (
        <>
            {error && <AuthError />}
            {isLoading && !error && <Loading />}
            {!isLoading && !error && children}
        </>
    );
};

const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Authenticating ...
            </p>
        </div>
    </div>
);

const AuthError = (): JSX.Element => {
    const { error, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (error) {
            console.warn('Auth0 Error:', error);
        }
    }, [error]);

    const clearCacheAndLogin = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('dzTenantId');

        loginWithRedirect({
            authorizationParams: { organization: undefined },
            appState: { returnTo: window.location.pathname + window.location.search },
        });
    };

    return (
        <ExternalPage>
            <div className="pb-16">
                <img className="h-12 w-auto" src={logo} alt="SailPoint Identity Risk Icon" />

                <h2 className="text-3xl font-extrabold text-gray-200">Authentication Issue</h2>
                <p className="text-sm text-gray-400 mt-2">Unfortunately you may not access SailPoint Identity Risk</p>

                <p className="text-xs text-red-500 mt-4">Reason: {error?.message}</p>

                <div className="mt-6 space-y-8">
                    <button onClick={clearCacheAndLogin} className="btn btn-primary w-3/7 text-sm rounded-md mx-auto">
                        Try to Sign In Again
                    </button>
                </div>
            </div>
        </ExternalPage>
    );
};
