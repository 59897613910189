import { useFlags } from 'launchdarkly-react-client-sdk';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { getDisplayName, classNames } from 'Utilities/utils';
import { NodeTheWall } from './Library/NodeTheWall';
import { NodeAttributeDisplay } from './Library/NodeAttributeDisplay';
import { ProfilePanel, ProfileHeader } from './Library/ProfileComponents';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { endOfDay, subMonths } from 'date-fns';

export const LightWeightProfile = () => {
    const { mapState } = useContext(IdentityMapContext);
    const { enableActivityLog } = useFlags();

    const { selectedProfileNode: node } = mapState;

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [view, setView] = useState<'activity' | 'attributes'>('attributes');

    const { show } = useContextMenu({
        id: 'profile',
    });

    const endDate = +endOfDay(+new Date());
    const startDate = +subMonths(endDate, 1);

    const displayContextMenu = (e: TriggerEvent & { clientX: number; clientY: number }) => {
        // prevent default List click action
        e.stopPropagation();
        e.preventDefault();

        // show context menu
        show(e, {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
        });
    };

    let pillTranslate = 'translate-x-0';
    switch (view) {
        case 'attributes':
            pillTranslate = 'translate-x-0';
            break;
        case 'activity':
            pillTranslate = 'translate-x-20';
    }

    if (!node) {
        return <></>;
    }

    return (
        <ProfilePanel>
            <Helmet>
                <title>Profile | {getDisplayName(node)}</title>
            </Helmet>
            <div
                data-test="profile-window"
                className={classNames('p-3', isMobile ? 'overflow-y-auto h-[calc(100vh-328px)]' : '')}
                onContextMenu={displayContextMenu}
            >
                <ContextMenu id={'profile'} node={node} />
                <ProfileHeader
                    node={node}
                    title={`${node.label} profile`}
                    displayContextMenu={displayContextMenu}
                    startDate={0}
                    endDate={0}
                />
                <div className="flex items-center justify-between">
                    <div className="flex rounded-full items-stretch justify-center my-3 bg-gray-700 relative">
                        <div
                            className={classNames(
                                'w-20 bg-blue-700 absolute left-0 top-0 text-xs p-1 rounded-full transition-all',
                                pillTranslate,
                            )}
                        >
                            &nbsp;
                        </div>

                        <p
                            onClick={() => {
                                setView('attributes');
                            }}
                            className={classNames(
                                'text-xs w-20 flex justify-center p-1 px-2 rounded-l-full transition-all cursor-pointer z-10',
                                view == 'attributes'
                                    ? 'text-white font-medium hover:text-white'
                                    : 'text-blue-600 hover:text-blue-600',
                            )}
                        >
                            Attributes
                        </p>

                        {enableActivityLog && (
                            <p
                                onClick={() => {
                                    setView('activity');
                                }}
                                className={classNames(
                                    'text-xs w-20 flex justify-center p-1 px-2 transition-all cursor-pointer z-10',
                                    view == 'activity'
                                        ? 'text-white font-medium hover:text-white'
                                        : 'text-blue-600 hover:text-blue-600',
                                )}
                            >
                                Activity
                            </p>
                        )}
                    </div>
                </div>

                <div className="bg-gray-800/50">
                    {view == 'attributes' && (
                        <NodeAttributeDisplay node={node} startDate={startDate} endDate={endDate} />
                    )}
                    {view == 'activity' && <NodeTheWall node={node} startDate={startDate} endDate={endDate} />}
                </div>
            </div>
        </ProfilePanel>
    );
};
