import logo from 'assets/logo-192x192.png';
import ExternalPage from './ExternalPage';

const Terms = (): JSX.Element => {
    return (
        <ExternalPage>
            <img className="h-12 w-auto" src={logo} alt="Double Zero Icon" />

            <h2 className="text-3xl font-extrabold text-gray-200">SailPoint Identity Risk</h2>
            <p className="text-sm text-gray-400 mt-2">About</p>

            <div className="mt-6 space-y-8">
                <p className="text-xs text-gray-300 ">
                    Copyright © 2024 SailPoint Technologies, Inc.
                    <br />
                    All Rights Reserved.
                    <br />
                    <br />
                    All logos, text, content, including underlying HTML code, designs, and graphics used and/or depicted
                    on these written materials or in this Internet website are protected under United States and
                    international copyright and trademark laws and treaties, and may not be used or reproduced without
                    the prior express written permission of SailPoint Technologies, Inc.
                    <br />
                    <br />
                    “SailPoint Technologies” (design and word mark), “SailPoint” (design and word mark), “IdentityIQ,”
                    “IdentityNow,” “IdentityAI”, “Identity Cube” and “SailPoint Predictive Identity” are registered
                    trademarks of SailPoint Technologies, Inc. “Identity is Everything,” “The Power of Identity,”
                    “Navigate” and “Identity University” are trademarks of SailPoint Technologies, Inc. None of the
                    foregoing marks may be used without the prior express written permission of SailPoint Technologies,
                    Inc.
                    <br />
                    <br />
                    Patents:{' '}
                    <a href="https://www.sailpoint.com/legal/patents/">https://www.sailpoint.com/legal/patents/</a>
                </p>
            </div>
        </ExternalPage>
    );
};
export default Terms;
