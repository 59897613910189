import { useLazyQuery } from '@apollo/client';
import { GET_TENANT } from 'Graph/queries';
import { useLocalStorage, useTenant } from 'Hooks/Hooks';
import { useEffect, useState } from 'react';

enum TenantStatusCode {
    TENANT_STATUS_UNKNOWN = 'TENANT_STATUS_UNKNOWN',
    TENANT_STATUS_PENDING = 'TENANT_STATUS_PENDING',
    TENANT_STATUS_APPROVED = 'TENANT_STATUS_APPROVED',
    TENANT_STATUS_PROVISIONED = 'TENANT_STATUS_PROVISIONED',
    TENANT_STATUS_DISABLED = 'TENANT_STATUS_DISABLED',
    TENANT_STATUS_CREATION_IN_PROGRESS = 'TENANT_STATUS_CREATION_IN_PROGRESS',
    TENANT_STATUS_CREATION_ERROR = 'TENANT_STATUS_CREATION_ERROR',
}

const TenantStatusDescription: Record<TenantStatusCode, string> = {
    TENANT_STATUS_UNKNOWN: 'Tenant is in an unknown state, please contact support',
    TENANT_STATUS_PENDING: 'Tenant is pending approval, please wait, this may take a few minutes',
    TENANT_STATUS_APPROVED: 'Tenant is approved, provisioning will begin shortly',
    TENANT_STATUS_PROVISIONED: 'Tenant is provisioned',
    TENANT_STATUS_DISABLED: 'Tenant is disabled, please contact support',
    TENANT_STATUS_CREATION_IN_PROGRESS: 'Tenant is being provisioned, please wait, this may take a few minutes',
    TENANT_STATUS_CREATION_ERROR: 'Tenant creation failed, please contact support',
};

type TenantStatusProps = {
    children: JSX.Element;
};

export const TenantStatus = ({ children }: TenantStatusProps): JSX.Element => {
    const timeoutInMs = 10000;
    const tenantId = useTenant();
    const { getScopedStorageKey } = useLocalStorage();
    const key = getScopedStorageKey(`tenantStatus`);
    const [status, setStatus] = useState<TenantStatusCode | undefined>(undefined);
    const [timeoutOrErrorOccurred, setTimeoutOrErrorOccurred] = useState(false);

    const [getTenant, { data }] = useLazyQuery(GET_TENANT, {
        variables: { tenantId },
        pollInterval: status !== TenantStatusCode.TENANT_STATUS_PROVISIONED ? 10000 : 0,
    });

    // Process the tenant status when we receive that from the API
    // This may happen after the timeout and the UI has been unblocked
    useEffect(() => {
        if (data) {
            const tenant = data.getTenant;
            const status = tenant.status as TenantStatusCode;

            // Update the state and cache the status
            setStatus(status);
            setTimeoutOrErrorOccurred(false);

            if (key) {
                localStorage.setItem(key, status);
            }

            console.log('Tenant status:', status);
        }
    }, [data, key]);

    useEffect(() => {
        // In this function we will start a race between the API request and a timer
        // If the API request returns before the timer, all good
        // If the timer expires before the API request returns or an error occurs we will set a flag
        // indicating that the timer expired and the UI should be unblocked.
        //
        // The reason we do this is so that the UI is not blocked indefinitely or for a long time
        // incase there is an issue with the getTenant API call.
        //
        // If the timeout occurs we will either (a) use the cached status or (b) unblock the UI and assume the tenant is provisioned
        // Obviously, the cached tenant status preferred, but they may not be available if the user has never logged in before
        async function fetchTenantStatus() {
            let isTimedOut = false;
            let isErrored = false;

            const responsePromise = getTenant();
            const timeoutPromise: Promise<{ error: string }> = new Promise((resolve) =>
                setTimeout(resolve, timeoutInMs, { error: 'timeout' }),
            );

            const response = await Promise.race([responsePromise, timeoutPromise]);

            if (response.error === 'timeout') {
                console.log('Timeout occurred while fetching tenant status');
                isTimedOut = true;
            } else {
                console.log('Tenant status API returned before timeout');

                if (response.error) {
                    console.warn('Error fetching tenant status:', response.error);
                    isErrored = true;
                }
            }

            if (isTimedOut || isErrored) {
                setTimeoutOrErrorOccurred(true);

                // If we have a cached status, use it
                if (key) {
                    const cachedStatus = localStorage.getItem(key);
                    if (cachedStatus) {
                        setStatus(cachedStatus as TenantStatusCode);
                        console.warn('Using cached tenant status');
                        return;
                    }
                }

                // If we don't have cached permissions, unblock the UI in read-only mode
                console.warn(
                    'Error or timeout occurred while waiting for tenant status, unblocking UI in read-only mode',
                );
            }
        }

        fetchTenantStatus();
    }, [key, getTenant]);

    // If a timeout occurred or we are in a provisioned state, render the app
    if (timeoutOrErrorOccurred || (status && status === TenantStatusCode.TENANT_STATUS_PROVISIONED)) {
        return <>{children}</>;
    }

    // If we have a status, that is NOT provisioned, show a message to the user
    if (status) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <p className="text-gray-500 text-xs" data-testid="tenant-status">
                        {TenantStatusDescription[status]}
                    </p>
                </div>
            </div>
        );
    }

    return <Loading />;
};
const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Retrieving Tenant Status...
            </p>
        </div>
    </div>
);
